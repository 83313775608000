import { useWorkflow } from "@/contexts/WorkflowContext";
import { DISPLAY_DATE_TIME_FORMAT } from "@/utils/constants";
import { Transaction } from "@/utils/interfaces";
import creditCoin from "@Assets/PNGs/credit_coin.png";
import { Spin, Tooltip } from "antd";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { List } from "react-virtualized";

interface Props {
  name: string;
  credits: number;
  workflowId: string;
  filters: any;
}

interface actionItemProp {
  data: Transaction;
}

const ActionItem: React.FC<actionItemProp> = ({ data }) => {
  return (
    <div className="flex items-center justify-between p-4">
      <div className="flex items-center">
        <div className="flex items-center gap-1 mr-4">
          <img src={creditCoin} width="25px" />
          <span className="text-[1rem] text-[#F9A605] font-bold -translate-y-0.5 min-w-6">
            {data?.total_credits_consumed}
          </span>
        </div>

        <div className="w-[2px] h-[45px] bg-[#B7B7B7] mr-4" />

        <div>
          <Tooltip trigger={["hover"]} title={"Time in UTC"} placement="topLeft" overlayClassName="numeric-input">
            <p className="font-bold">{data?.apiName}</p>
            <p className="text-gray-500">{moment.utc(data?.created_at).format(DISPLAY_DATE_TIME_FORMAT)}</p>
          </Tooltip>
        </div>
      </div>
      <div className="bg-[#BEFFE0] text-[#00733C] px-2 py-1 rounded-md text-[0.85rem]">
        {data?.status.toUpperCase()}
      </div>
    </div>
  );
};

const Card: React.FC<Props> = ({ name, credits, workflowId, filters: preAppliedFilters }) => {
  const [showBody, setShowBody] = useState(false);
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [reachedEnd, setReachedEnd] = useState(false);
  const { fetchTransactionsForOrgIdWithFiltersUnformatted } = useWorkflow();

  useMemo(() => {
    if (!showBody) {
      setTransactions([]);
      setReachedEnd(false);
      setPageNumber(1);
      setLoading(true);
      return;
    }

    if (reachedEnd) {
      return;
    }
    setLoading(true);
    const filters = { ...preAppliedFilters, workflows: [workflowId] };
    fetchTransactionsForOrgIdWithFiltersUnformatted(filters, pageNumber).then((transactionsData) => {
      const concatenatedTransactions = transactions.concat(transactionsData?.transactions);
      setTransactions(concatenatedTransactions);
      if (concatenatedTransactions?.length >= transactionsData.total_count) {
        setReachedEnd(true);
      }
      setLoading(false);
    });
  }, [workflowId, showBody, pageNumber]);

  return (
    <div>
      <div className=" bg-white rounded-md border-solid border-2 border-[#d1d1d1] cursor-pointer  mb-3">
        <div className="flex items-center justify-between p-4" onClick={() => setShowBody(!showBody)}>
          <div className="flex items-center">
            <div className="flex items-center gap-1 mr-4">
              <img src={creditCoin} />
              <span className="text-[1.4rem] text-[#F9A605] font-bold -translate-y-0.5 min-w-16">{credits}</span>
            </div>

            <div className="w-[2px] h-[45px] bg-[#B7B7B7] mr-4" />

            <div>
              <p className="font-bold">{name}</p>
              {/* <p className="text-gray-500">Jul 05, 03:34 PM</p> */}
            </div>
          </div>
          <div className={`text-[#B7B7B7] text-[1.5rem] ${showBody && "rotate-180"} transition-all duration-300`}>
            <IoIosArrowDown />
          </div>
        </div>

        <div className={`${showBody ? "block" : "hidden"} p-4 border-t overflow-scroll `}>
          {transactions?.length ? (
            <List
              width={1155}
              height={265}
              rowHeight={65}
              rowCount={transactions?.length}
              rowRenderer={({ key, index, style }) => {
                return (
                  <div key={key} style={style}>
                    <ActionItem key={index} data={transactions[index]} />
                  </div>
                );
              }}
              // @ts-ignore
              onRowsRendered={({ startIndex, stopIndex }) => {
                if (stopIndex === transactions?.length - 1) {
                  setPageNumber(pageNumber + 1);
                }
              }}
            />
          ) : (
            ""
          )}
          {loading && (
            <div className="flex items-center justify-center h-full py-5">
              <Spin />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
