import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Form, FormControl, FormField, FormItem, FormMessage, FormLabel } from "../ui/Form";
import { Checkbox } from "../ui/Checkbox";
import { useQuery } from "@tanstack/react-query";
import { WEEK_IN_MS, areObjectsEqual } from "../utils";
import { Tag } from "../ui/Tag";
import { LuX } from "react-icons/lu";
import { getCampaigns } from "@/utils/apis";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";

const personFields = [
  {
    id: "id",
    name: "ID",
    type: "fixed",
  },
  {
    id: "name",
    name: "Name",
    type: "fixed",
  },
  {
    id: "first_name",
    name: "First Name",
    type: "fixed",
  },
  {
    id: "last_name",
    name: "Last Name",
    type: "fixed",
  },
  {
    id: "owner_id",
    name: "Owner ID",
    type: "fixed",
  },
  {
    id: "org_id",
    name: "Organization ID",
    type: "fixed",
  },
  {
    id: "add_time",
    name: "Add Time",
    type: "fixed",
  },
  {
    id: "update_time",
    name: "Update Time",
    type: "fixed",
  },
  {
    id: "emails",
    name: "Emails",
    type: "fixed",
  },
  {
    id: "phones",
    name: "Phones",
    type: "fixed",
  },
  {
    id: "is_deleted",
    name: "Is Deleted",
    type: "fixed",
  },
  {
    id: "visible_to",
    name: "Visible To",
    type: "fixed",
  },
  {
    id: "label_ids",
    name: "Label IDs",
    type: "fixed",
  },
  {
    id: "picture_id",
    name: "Picture ID",
    type: "fixed",
  },
  {
    id: "next_activity_id",
    name: "Next Activity ID",
    type: "optional",
  },
  {
    id: "last_activity_id",
    name: "Last Activity ID",
    type: "optional",
  },
  {
    id: "open_deals_count",
    name: "Open Deals Count",
    type: "optional",
  },
  {
    id: "related_open_deals_count",
    name: "Related Open Deals Count",
    type: "optional",
  },
  {
    id: "closed_deals_count",
    name: "Closed Deals Count",
    type: "optional",
  },
  {
    id: "related_closed_deals_count",
    name: "Related Closed Deals Count",
    type: "optional",
  },
  {
    id: "participant_open_deals_count",
    name: "Participant Open Deals Count",
    type: "optional",
  },
  {
    id: "participant_closed_deals_count",
    name: "Participant Closed Deals Count",
    type: "optional",
  },
  {
    id: "email_messages_count",
    name: "Email Messages Count",
    type: "optional",
  },
  {
    id: "activities_count",
    name: "Activities Count",
    type: "optional",
  },
  {
    id: "done_activities_count",
    name: "Done Activities Count",
    type: "optional",
  },
  {
    id: "undone_activities_count",
    name: "Undone Activities Count",
    type: "optional",
  },
  {
    id: "files_count",
    name: "Files Count",
    type: "optional",
  },
  {
    id: "notes_count",
    name: "Notes Count",
    type: "optional",
  },
  {
    id: "followers_count",
    name: "Followers Count",
    type: "optional",
  },
  {
    id: "won_deals_count",
    name: "Won Deals Count",
    type: "optional",
  },
  {
    id: "related_won_deals_count",
    name: "Related Won Deals Count",
    type: "optional",
  },
  {
    id: "lost_deals_count",
    name: "Lost Deals Count",
    type: "optional",
  },
  {
    id: "related_lost_deals_count",
    name: "Related Lost Deals Count",
    type: "optional",
  },
  {
    id: "last_incoming_mail_time",
    name: "Last Incoming Mail Time",
    type: "optional",
  },
  {
    id: "last_outgoing_mail_time",
    name: "Last Outgoing Mail Time",
    type: "optional",
  },
  {
    id: "marketing_status",
    name: "Marketing Status",
    type: "optional",
  },
  {
    id: "doi_status",
    name: "DOI Status",
    type: "optional",
  },
];

const organizationFields = [
  {
    id: "id",
    name: "ID",
    type: "fixed",
  },
  {
    id: "name",
    name: "Name",
    type: "fixed",
  },
  {
    id: "owner_id",
    name: "Owner ID",
    type: "fixed",
  },
  {
    id: "add_time",
    name: "Add Time",
    type: "fixed",
  },
  {
    id: "update_time",
    name: "Update Time",
    type: "fixed",
  },
  {
    id: "visible_to",
    name: "Visible To",
    type: "fixed",
  },
  {
    id: "address",
    name: "Address",
    type: "fixed",
  },
  {
    id: "label_ids",
    name: "Label IDs",
    type: "fixed",
  },
  {
    id: "is_deleted",
    name: "Is Deleted",
    type: "fixed",
  },
  {
    id: "next_activity_id",
    name: "Next Activity ID",
    type: "optional",
  },
  {
    id: "last_activity_id",
    name: "Last Activity ID",
    type: "optional",
  },
  {
    id: "open_deals_count",
    name: "Open Deals Count",
    type: "optional",
  },
  {
    id: "related_open_deals_count",
    name: "Related Open Deals Count",
    type: "optional",
  },
  {
    id: "closed_deals_count",
    name: "Closed Deals Count",
    type: "optional",
  },
  {
    id: "related_closed_deals_count",
    name: "Related Closed Deals Count",
    type: "optional",
  },
  {
    id: "email_messages_count",
    name: "Email Messages Count",
    type: "optional",
  },
  {
    id: "people_count",
    name: "People Count",
    type: "optional",
  },
  {
    id: "activities_count",
    name: "Activities Count",
    type: "optional",
  },
  {
    id: "done_activities_count",
    name: "Done Activities Count",
    type: "optional",
  },
  {
    id: "undone_activities_count",
    name: "Undone Activities Count",
    type: "optional",
  },
  {
    id: "files_count",
    name: "Files Count",
    type: "optional",
  },
  {
    id: "notes_count",
    name: "Notes Count",
    type: "optional",
  },
  {
    id: "followers_count",
    name: "Followers Count",
    type: "optional",
  },
  {
    id: "won_deals_count",
    name: "Won Deals Count",
    type: "optional",
  },
  {
    id: "related_won_deals_count",
    name: "Related Won Deals Count",
    type: "optional",
  },
  {
    id: "lost_deals_count",
    name: "Lost Deals Count",
    type: "optional",
  },
  {
    id: "related_lost_deals_count",
    name: "Related Lost Deals Count",
    type: "optional",
  },
];

const technologySchema = z.object({
  name: z.string(),
  id: z.string(),
  type: z.string(),
});

type TechnologyCategory = z.infer<typeof technologySchema>;

const FormSchema = z.object({
  items: z.array(z.any()),
});

const getCustomFields = async (type: string) => {
  if (!type) return [];
  try {
    const existing = type === "organizations" ? organizationFields : personFields;
    const response = await getCampaigns(
      type === "organizations" ? "pipedriveOrgFields" : "pipedrivePersonFields",
      true
    );
    return existing.concat(response.map((item: any) => ({ id: item.id, name: item.name, type: "custom" })));
  } catch (error) {
    return [];
  }
};

export function SearchFilterValuePreviewPipedriveFields({
  value,
  type,
}: {
  value: TechnologyCategory[];
  type: string;
}) {
  const { data: categories = [] } = useQuery<TechnologyCategory[]>({
    queryKey: ["pipedrive_fields"],
    queryFn: () => getCustomFields(type),
    staleTime: WEEK_IN_MS,
  });
  const length = value ? value.length : 0;
  const valueLabels = value
    ? value.slice(0, 2).map((item) => categories.find((value) => value.id === item.id)?.name)
    : [];
  const valueString = valueLabels ? valueLabels.join(", ") : "";
  return <p className="text-sm">{`${valueString}${length > 2 ? `, + ${length - 2}` : ""}`}</p>;
}

export function SearchFilterFormPipedriveFields({
  value,
  onChangeValue,
  entityType,
  onInteractOutside,
  closePopover,
}: {
  value: TechnologyCategory[];
  onChangeValue: (_value: TechnologyCategory[] | undefined) => void;
  entityType: string;
  onInteractOutside: boolean;
  closePopover: () => void;
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const { data: categories = [], isLoading } = useQuery<TechnologyCategory[]>({
    queryKey: ["pipedrive_fields"],
    queryFn: () => getCustomFields(entityType),
    staleTime: WEEK_IN_MS,
  });
  const [selectedValues, setSelectedValues] = useState<TechnologyCategory[]>([]);
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      items: value || [],
    },
  });
  const formCurrentValue = useWatch({ control: form.control, name: "items" });

  useEffect(() => {
    if (!formCurrentValue) return;
    const currentIds = formCurrentValue.map((item) => item.id);
    setSelectedValues(categories.filter((item) => currentIds.includes(item.id)));
  }, [formCurrentValue]);

  const filteredItems = categories.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));

  function onSubmit(data: z.infer<typeof FormSchema> | undefined) {
    const newValue = data && data.items && data.items.length > 0 ? data.items : undefined;
    if (areObjectsEqual(newValue, value)) {
      closePopover();
      return;
    }
    onChangeValue(newValue);
  }
  useEffect(() => {
    if (onInteractOutside === true) {
      onSubmit(form.getValues());
    }
  }, [onInteractOutside]);

  function sortbyStatus(): (a: TechnologyCategory, b: TechnologyCategory) => number {
    return (a, b) => {
      const ids = value?.map((item) => item.id);
      // Sort active items first
      if (value && ids && ids.includes(a.id) && !ids.includes(b.id)) return -1;
      if (value && ids && !ids.includes(a.id) && ids.includes(b.id)) return 1;
      // Sort remaining items alphabetically
      return a.name.localeCompare(b.name);
    };
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="grid max-w-sm items-center gap-y-4">
        <div
          className={`relative flex flex-col items-start border rounded  max-h-40 overflow-y-auto ${
            selectedValues.length > 0 ? "p-2" : "px-3"
          }`}
          cmdk-input-wrapper=""
        >
          <div className="flex flex-row gap-1 flex-wrap">
            {selectedValues.map((item) => (
              <Tag
                key={item.id}
                id={item.id}
                name={item.name}
                onClick={(slug) => {
                  setSelectedValues(selectedValues.filter((item) => item.id !== slug));
                  form.setValue(
                    "items",
                    selectedValues.filter((item) => item.id !== slug)
                  );
                }}
              />
            ))}
            <input
              type="text"
              value={searchQuery}
              autoFocus={true}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search..."
              className="h-8 min-w-12 rounded-md bg-transparent py-3 text-sm outline-none border-none focus:!border-transparent focus:!ring-0 focus:!ring-offset-0 placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
            />
          </div>
          {selectedValues.length > 0 && (
            <LuX
              className="absolute right-2 top-3 h-4 w-4 cursor-pointer text-slate-500 hover:text-black"
              onClick={() => form.setValue("items", [])}
            />
          )}
        </div>
        <FormField
          control={form.control}
          name="items"
          render={() => (
            <FormItem className="max-h-[300px] overflow-scroll">
              <FormMessage />
              {filteredItems
                .filter((item) => !selectedValues.map((item) => item.id).includes(item.id))
                .sort(sortbyStatus())
                .map((item) => getFormField(item, form))}

              {isLoading && (
                <div className="flex justify-center">
                  <SpinnerStatus />
                </div>
              )}
              {!isLoading && filteredItems.length === 0 && (
                <div className="flex justify-center">
                  <p className="text-sm text-gray-500 text-center">
                    {entityType === ""
                      ? "Please select a type first to see fields"
                      : "Please connect your Pipedrive account to see the fields"}
                  </p>
                </div>
              )}
            </FormItem>
          )}
        />
        <button className="btn py-2" type="submit">
          Save
        </button>
      </form>
    </Form>
  );
}

function getFormField(item: TechnologyCategory, form: any) {
  return (
    <FormField
      key={item.id}
      control={form.control}
      name="items"
      render={({ field }) => {
        return (
          <FormItem key={item.id} className="flex flex-row items-center space-x-3 space-y-0">
            <FormControl>
              <Checkbox
                id={item.id}
                checked={field.value?.map((fld: any) => fld.id)?.includes(item.id)}
                onCheckedChange={(checked) => {
                  return checked
                    ? field.onChange([...(field.value || []), item])
                    : field.onChange(field.value?.filter((value: TechnologyCategory) => value.id !== item.id));
                }}
              />
            </FormControl>
            <FormLabel htmlFor={item.id} className="text-sm font-normal cursor-pointer hover:underline">
              <p className="text-sm font-normal"> {item.name} </p>
            </FormLabel>
          </FormItem>
        );
      }}
    />
  );
}
