import { operatorStatesDropdown } from "@/utils/countries";
import { IFiltersProps, IOperator } from "./filter.schema";
import { LuBackpack, LuMapPin } from "react-icons/lu";

export const pipedriveFilters: IFiltersProps[] = [
  {
    id: "pipedrive_type",
    icon: LuBackpack,
    title: "Type",
    description: "Type of the pipedrive entity",
    data_type: "options",
    availableOperators: [IOperator.is],
    areOperatorsAccumulative: true,
    availableValues: [
      { id: "persons", name: "Persons" },
      { id: "organizations", name: "Organizations" },
    ],
    group: "Company",
    is_permanent: true,
  },
  {
    id: "pipedrive_fields",
    icon: LuMapPin,
    title: "Fields",
    description: "Fields of the pipedrive entity",
    data_type: "pipedrive_fields[]",
    availableOperators: [IOperator.contains_any],
    areOperatorsAccumulative: true,
    availableValues: operatorStatesDropdown,
    group: "Company",
    is_permanent: true,
  },
];
