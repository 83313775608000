import { setStateType } from "../../../utils/constants";
import { useEffect, useState } from "react";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import TiptapField from "../Tiptap/TiptapField";
import { Action, PayloadConfiguration } from "../../../utils/interfaces";
import { Alert, ConfigProvider, Drawer, Select, Skeleton, Switch } from "antd";
import { FaPlus, FaRegEdit } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";
import RunCondition from "./ModalComponents/RunCondition";
import { useConnections } from "@/contexts/ConnectionContext";
import { getCampaigns } from "@/utils/apis";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  payloadConfiguration: PayloadConfiguration[];
  variables: any[];
};

const HubspotModal = ({ action, modal, setModal, payloadConfiguration, variables }: Props) => {
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [campaigns, setCampaigns] = useState<{
    loading: boolean;
    campaigns: any[];
  }>({
    loading: false,
    campaigns: [],
  });
  const [updateField, setUpdateField] = useState(0);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const [runCondition, setRunCondition] = useState(action.runCondition);
  const { updatePayloadConfig, saveWorkflowActions, updateActionName, setPublishWarning } = useWorkflow();
  const { getConnectionFromType } = useConnections();
  const [isConnectionExists, setIsConnectionExists] = useState(true);
  const [changeName, setChangeName] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setCampaigns({ loading: true, campaigns: [] });
    getCampaigns("hubspot_properties").then((campaigns) => {
      setCampaigns({ loading: false, campaigns });
    });
  }, []);

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setUpdateField((prev) => prev + 1);
  }, [payloadConfiguration]);

  useEffect(() => {
    if (payloads !== payloadConfiguration || runCondition !== action.runCondition) setChanged(true);
    else setChanged(false);
  }, [payloads, runCondition]);

  useEffect(() => {
    if (!action?.connectionId) return;
    getConnectionFromType(action?.connectionId).then((connection: any) => {
      setIsConnectionExists(connection?.userConnection ? true : false);
    });
  }, [action?.connectionId]);

  const saveOutputStructure = async () => {
    setLoading(true);
    const updated = updatePayloadConfig(payloads, action.id, false, action.continueOnFail, runCondition);
    setPayloads(updated);
    await saveWorkflowActions(id || "");
    setChanged(false);
    setLoading(false);
    setPublishWarning(true);
    setModal(false);
  };

  const fieldSwitchCase = (field: any) => {
    switch (field.type) {
      case "jsonArray":
        return (
          <div className="w-full flex flex-col gap-4">
            {payloads
              .find((input) => input.payloadStructureId === field.payloadStructureId)
              ?.inputString.map((input: any, idx: number) => {
                return (
                  <div className="flex gap-2 justify-between">
                    <div className="w-full" key={idx}>
                      <Skeleton
                        loading={campaigns.loading}
                        paragraph={false}
                        active={campaigns.loading}
                        style={{ marginBottom: "0.25rem" }}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%", marginBottom: "0.25rem" }}
                          value={
                            payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                              ?.inputString[idx].label || ""
                          }
                          onChange={(value, options: any) => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString[idx].name = value;
                            temp[index].inputString[idx].label = options.label;
                            temp[index].inputString[idx].type = options.type;
                            temp[index].inputString[idx].enums = options.enums;
                            // temp[index].inputString[idx].desc = options.desc;

                            setCampaigns({
                              loading: false,
                              campaigns: campaigns.campaigns,
                            });
                            setPayloads(temp);
                          }}
                          options={campaigns.campaigns
                            .filter((value: any) => value.modificationMetadata.readOnlyValue === false)
                            .map((field: any) => ({
                              label: field.label,
                              value: field.name,
                              type: field.type,
                              enums: field.options,
                              // desc: field.description,
                            }))}
                        />
                      </Skeleton>

                      {(() => {
                        switch (input.type) {
                          case "bool":
                            return (
                              <Switch
                                style={{
                                  width: "fit-content",
                                  marginTop: "0.25rem",
                                  marginLeft: "0.20rem",
                                }}
                                defaultChecked={
                                  payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                                    ?.inputString[idx].value === true
                                }
                                checkedChildren="True"
                                unCheckedChildren="False"
                                onChange={(value) => {
                                  const temp = [...payloads];
                                  const index = payloads.findIndex(
                                    (input) => input.payloadStructureId === field.payloadStructureId
                                  );
                                  temp[index].inputString[idx].value = value;
                                  setPayloads(temp);
                                }}
                              />
                            );
                          case "enumeration":
                            return (
                              <Select
                                showSearch
                                style={{ width: "100%", marginBottom: "0.25rem" }}
                                value={
                                  payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                                    ?.inputString[idx].value || ""
                                }
                                onChange={(value) => {
                                  const temp = [...payloads];
                                  const index = payloads.findIndex(
                                    (input) => input.payloadStructureId === field.payloadStructureId
                                  );
                                  temp[index].inputString[idx].value = value;
                                  setPayloads(temp);
                                }}
                                options={input.enums.map((field: any) => ({
                                  label: field.label,
                                  value: field.value,
                                }))}
                              />
                            );
                          default:
                            return (
                              <TiptapField
                                content={
                                  payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                                    ?.inputString[idx].tiptapJson || ""
                                }
                                setContent={(content: any, text: string) => {
                                  const temp = [...payloads];
                                  const index = payloads.findIndex(
                                    (input) => input.payloadStructureId === field.payloadStructureId
                                  );
                                  temp[index].inputString[idx].value = text;
                                  temp[index].inputString[idx].tiptapJson = content;
                                  setPayloads(temp);
                                }}
                                refresh={updateField}
                                // placeholder={input.desc || "Enter the value"}
                                placeholder="Enter the value"
                                variables={variables}
                              />
                            );
                        }
                      })()}
                    </div>
                    <button
                      className="hover:bg-primary/10 h-fit w-fit p-2 rounded-full text-primary"
                      onClick={() => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );

                        temp[index].inputString.splice(idx, 1);
                        setUpdateField((prev) => prev + 1);
                        setPayloads(temp);
                      }}
                    >
                      <MdDeleteOutline size={25} />
                    </button>
                  </div>
                );
              })}
            <button
              className="text-primary flex gap-2 items-center"
              onClick={() => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString.push({
                  name: "",
                  value: "",
                });
                setPayloads(temp);
              }}
            >
              <FaPlus />
              Add a new Variable Name and Variable Value pair
            </button>
          </div>
        );
      default:
        return (
          <textarea
            className="w-full h-32 rounded-lg"
            placeholder="Enter the value"
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(e) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: e.target.value,
                  type: field.type,
                });
              } else {
                temp[index].inputString = e.target.value;
              }
              setPayloads(temp);
            }}
          />
        );
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        onClose={() => setModal(false)}
        width="30%"
        className='!font-["Inter"] !p-0'
        footer={
          <div className="w-full flex flex-col gap-4 bg-white">
            {!isConnectionExists && (
              <Alert
                message={
                  <>
                    Please add the required connection to proceed.{" "}
                    <Link to="/connections" target="_blank" rel="noopener noreferrer">
                      <i>Click here</i>
                    </Link>
                  </>
                }
                type="warning"
                showIcon
              />
            )}
            <button
              className={` text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                loading || !changed || !isConnectionExists
                  ? "cursor-not-allowed bg-gray-500"
                  : "bg-primary cursor-pointer"
              }`}
              onClick={saveOutputStructure}
              disabled={loading || !changed || !isConnectionExists}
            >
              {loading ? "Saving..." : !changed ? "Saved" : "Save"}
            </button>
          </div>
        }
        title={
          <div className="flex items-center gap-2">
            <img src={action.logo} alt={action.name} className="w-6 h-6" />
            <span
              contentEditable={changeName}
              suppressContentEditableWarning
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.target.blur();
                }
              }}
              onBlur={async (e) => {
                if (!e.target.textContent) return;
                setLoading(true);
                updateActionName(action.id, e.target.textContent || action.name);
                setChangeName(false);
                await saveWorkflowActions(id || "");
                setChanged(false);
                setLoading(false);
                setPublishWarning(true);
              }}
            >
              {action.name}
            </span>
            <button
              onClick={() => {
                setChangeName(!changeName);
                const p = document.getElementById("actionName");
                if (!p) return;
                setTimeout(function () {
                  const range = document.createRange();
                  const selection = window.getSelection();
                  range.selectNodeContents(p);
                  range.collapse(false); // <-- Set the cursor at the end of the selection
                  selection?.removeAllRanges();
                  selection?.addRange(range);
                  p.focus();
                }, 0);
              }}
              className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                changeName ? "opacity-50" : "opacity-100"
              }`}
            >
              <FaRegEdit />
            </button>
          </div>
        }
      >
        <div className="bg-white gap-10 flex flex-col">
          {action.payloadStructure.map((field, idx) => {
            return (
              <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                <div>
                  <p className="tracking-primary font-semibold">
                    {field.name.toUpperCase()}
                    {!field.required && (
                      <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                    )}
                  </p>
                  <p className="text-sm font-light">{field.description}</p>
                </div>
                {field.needVars ? (
                  <TiptapField
                    content={
                      payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson || ""
                    }
                    setContent={(content: any, text: string) => {
                      const temp = [...payloads];
                      const index = payloads.findIndex(
                        (input) => input.payloadStructureId === field.payloadStructureId
                      );
                      if (index === -1) {
                        temp.push({
                          payloadStructureId: field.payloadStructureId,
                          tiptapJson: content,
                          inputString: text,
                          type: field.type,
                        });
                      } else {
                        temp[index].tiptapJson = content;
                        temp[index].inputString = text;
                      }
                      setPayloads(temp);
                    }}
                    refresh={updateField}
                    placeholder={field.name}
                    variables={variables}
                  />
                ) : (
                  fieldSwitchCase(field)
                )}
              </div>
            );
          })}
          <div>
            Continue workflow even if this fails:{" "}
            <Switch
              defaultChecked={action.continueOnFail}
              onChange={(value) => {
                setChanged(true);
                action.continueOnFail = value;
              }}
            />
          </div>
          <RunCondition
            variables={variables}
            responses={action.responseStructure}
            conditionsArr={
              runCondition || {
                conditions: [],
                otherwise: [],
              }
            }
            setConditionsArr={setRunCondition}
          />
        </div>
      </Drawer>
    </ConfigProvider>
  );
};

export default HubspotModal;
