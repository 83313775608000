import Placeholder from "@tiptap/extension-placeholder";
import { EditorContent, useEditor } from "@tiptap/react";
import { useCallback, useEffect } from "react";
import { CustomTag } from "../CustomTag";
import Variables from "../Variables";
import StarterKit from "@tiptap/starter-kit";
import { ErrorTag } from "../ErrorTag";
import { keyDownArr1, keyDownArr2 } from "@/utils/constants";
import Commands from "./Suggestions/Commands";
import { getCommandSuggestions } from "./Suggestions/Items";
import renderItems from "./Suggestions/RenderItems";
import { cn } from "@/utils/cn";
import { BsCopy } from "react-icons/bs";
import { App, message } from "antd";

type Props = {
  content: any;
  setContent: (content: any, text: string) => void;
  placeholder?: string;
  NotificationNode?: any;
  refresh: number;
  variables: any[];
  skipDataValues?: boolean;
  placeholderText?: string;
  centerPlaceholder?: boolean;
  applyStyles?: boolean;
  className?: string;
  customStyles?: boolean;
  explodeSection?: string;
};

const TiptapField = ({
  content,
  NotificationNode,
  setContent,
  placeholder,
  refresh,
  variables,
  skipDataValues = false,
  className,
  placeholderText = "Enter the value",
  centerPlaceholder = false,
  applyStyles = true,
  customStyles = false,
  explodeSection = "",
}: Props) => {
  const extensions = [
    StarterKit.configure({
      codeBlock: false,
      code: false,
    }),
    CustomTag.configure({
      HTMLAttributes: {
        span: {
          class: customStyles ? "text-[#f9a605] bg-primary/30" : "",
        },
      },
    }),
    ErrorTag,
    Placeholder.configure({
      placeholder: placeholder
        ? `Enter your "${placeholder.trim()}" here.\nTip: Use the '/' key to access variables from previous steps.`
        : placeholderText,
    }),
    Commands.configure({
      suggestion: {
        // @ts-expect-error - editor is not defined in the options
        items: ({ editor, query }) =>
          getCommandSuggestions({
            query,
            variables,
            explodeSection,
          }),
        render: renderItems,
      },
    }),
  ];
  const editor = useEditor(
    {
      extensions: extensions,
      editorProps: {
        attributes: {
          class: cn(
            "w-full min-h-[120px] p-4 bg-white border border-gray-300 rounded-lg",
            className,
            !centerPlaceholder ? "" : "flex items-center"
          ),
          style: applyStyles ? "border: 1px solid #e2e8f0; border-radius: 0.375rem;" : "",
        },
        handlePaste(view, event) {
          // Prevent default paste behavior to avoid duplication
          event.preventDefault();

          navigator.clipboard.readText().then((clipboardText) => {
            try {
              // Attempt to parse clipboard content as JSON
              const jsonContent = JSON.parse(clipboardText);

              if (jsonContent.type === "doc" && jsonContent.content) {
                // Helper to process and validate nodes
                const findIdByParentAndLabel = (parent: string, label: string) => {
                  const matchingAction = variables.find((action) => action.name === parent);

                  if (matchingAction) {
                    const matchingVariable = matchingAction.variables.find((variable: any) => variable.name === label);
                    return matchingVariable?.responseId || null;
                  }

                  return null;
                };

                const updateNodeAttrs = (node: any) => {
                  if (node.type === "customTag" && node.attrs) {
                    const { parent, label } = node.attrs;

                    // Update ID based on parent and label
                    if (parent && label) {
                      const newId = findIdByParentAndLabel(parent, label);
                      if (newId) {
                        node.attrs.id = newId;
                      } else {
                        console.error(`No matching variable found for parent "${parent}" and label "${label}".`);
                        node.type = "errorTag"; // Mark as error if no match
                      }
                    }
                  }

                  // Recursively update children if present
                  if (Array.isArray(node.content)) {
                    node.content.forEach(updateNodeAttrs);
                  }
                };

                // Process and validate all nodes in the content
                jsonContent.content.forEach(updateNodeAttrs);

                // Insert JSON content inline
                const { tr, selection, schema } = view.state;
                const { from, to } = selection;

                // Flatten and extract content nodes
                const flattenedNodes = jsonContent.content.flatMap((node: any) =>
                  node.type === "paragraph" && node.content ? node.content : [node]
                );

                const fragment = schema.nodes.doc.create(
                  null,
                  flattenedNodes.map((node: any) => schema.nodeFromJSON(node))
                );

                // Replace selection inline with the processed fragment
                view.dispatch(tr.replaceRangeWith(from, to, fragment).scrollIntoView());
              }
            } catch {
              // If parsing fails, treat it as plain text
              const { tr, selection, schema } = view.state;
              const { from, to } = selection;

              // Create a new paragraph node for plain text
              const textNode = schema.text(clipboardText);
              const paragraphNode = schema.nodes.paragraph.create(null, textNode);

              // Replace the selected range with the new paragraph
              view.dispatch(tr.replaceRangeWith(from, to, paragraphNode).scrollIntoView());
            }
          });

          return true; // Indicate that we handled the paste
        },
      },
      content: content,
      onUpdate: ({ editor }) => {
        setContent(editor.getJSON(), editor.getText());
      },
    },
    [refresh]
  );

  editor?.setOptions({
    editorProps: {
      handleKeyDown(_view, event) {
        if (event.code === "Tab") {
          event.preventDefault();
          editor?.commands.insertContent("\t");
        } else if (keyDownArr2.includes(event.code)) {
          event.stopPropagation();
        } else if (
          (event.code[0] === "D" || keyDownArr1.includes(event.code)) &&
          event.ctrlKey === false &&
          event.metaKey === false &&
          event.altKey === false
        ) {
          event.preventDefault();
          handleSpecialKeys(event);
        }
      },
    },
  });

  const handleSpecialKeys = useCallback(
    (event: any) => {
      const capsOn = event.getModifierState("CapsLock");
      const shiftPressed = event.getModifierState("Shift");
      if (shiftPressed) {
        event.stopPropagation();
      }
      if (event.code[0] === "D") {
        editor?.commands.insertContent(event.key);
      } else {
        let isCap = shiftPressed ? !capsOn : capsOn;
        if (isCap) editor?.commands.insertContent(event.code[3]);
        else editor?.commands.insertContent(event.key);
      }
    },
    [editor]
  );

  const getParentAction = (variableId: any) => {
    // variables.forEach((action) => {
    for (const action of variables) {
      for (const variable of action.variables) {
        if (variable.responseId === variableId) return action.name;
        else if (explodeSection && explodeSection === variable.responseId) {
          const sectionVar = variable.sectionVariablesConfiguration?.find(
            (item: any) => item.responseId === variableId
          );
          if (sectionVar) return action.name;
        }
      }
    }
    return "";
  };

  const checkValidNode = (node: any) => {
    if (node.type === "customTag") {
      const parentAction = getParentAction(node.attrs.id);
      if (!parentAction) {
        node.type = "errorTag";
      } else {
        node.attrs.parent = parentAction;
      }
    } else if (node.type === "errorTag") {
      const parentAction = getParentAction(node.attrs.id);
      if (parentAction) {
        node.type = "customTag";
        node.attrs.parent = parentAction;
      }
    }

    // Only recurse if there's valid content
    if (Array.isArray(node.content)) {
      node.content.forEach(checkValidNode);
    }
  };

  useEffect(() => {
    const newContent = content;
    if (!editor) return;
    if (newContent !== "" && newContent.type === "doc" && variables.length > 0)
      newContent.content.forEach((node: any) => {
        checkValidNode(node);
      });
    editor?.commands.setContent(newContent);
  }, [editor, variables]);

  const addVariable = (variable: any, actionName: string) => {
    if (editor?.isFocused) {
      editor?.commands.insertContentAt(editor?.state.selection.$anchor.pos, {
        type: "customTag",
        attrs: {
          id: variable.responseId,
          parent: actionName,
          label: variable.name,
          logo: variable.logo,
        },
      });
    } else
      editor
        ?.chain()
        .focus()
        .insertContent({
          type: "customTag",
          attrs: {
            id: variable.responseId,
            parent: actionName,
            label: variable.name,
            logo: variable.logo,
          },
        })
        .run();
    editor?.commands.focus();
  };

  const handleCopy = useCallback(() => {
    if (editor) {
      const content = editor.getJSON(); // Get the entire editor content as JSON

      // Check if the editor is empty
      if (
        !content.content ||
        (content.content.length === 1 && content.content[0].type === "paragraph" && !content.content[0].content)
      ) {
        message.error("No content to copy");
        return;
      }

      const selection = editor.state.selection;
      let jsonContent;

      const text = editor.getText();
      const hasCustomVariable = /\{\{.*?\}\}/.test(text);
      console.log(hasCustomVariable);

      // Copy if plain text
      if (!hasCustomVariable) {
        navigator.clipboard.writeText(editor.getText());
        message.success("Text copied to clipboard");
        return;
      }

      if (!selection.empty) {
        // Copy only the selected content
        const selectedContent = editor.state.doc.cut(selection.from, selection.to);
        jsonContent = selectedContent.toJSON();
        message.success("Selected content copied to clipboard");
      } else {
        // Copy the entire content
        jsonContent = editor.getJSON();
        message.success("Entire content copied to clipboard");
      }
      // Write to clipboard
      navigator.clipboard.writeText(JSON.stringify(jsonContent).trim());
    }
  }, [editor]);

  useEffect(() => {
    const handleCopyEvent = (event: ClipboardEvent) => {
      if (editor?.isFocused) {
        // Only trigger when editor is focused
        event.preventDefault();
        handleCopy();
      }
    };

    // Add listener specifically to the editor's DOM element
    const editorElement = editor?.view.dom;
    if (editorElement) {
      editorElement.addEventListener("copy", handleCopyEvent);
    }

    return () => {
      if (editorElement) {
        editorElement.removeEventListener("copy", handleCopyEvent);
      }
    };
  }, [editor, handleCopy]);

  return (
    <div className="w-full flex flex-col gap-2">
      <div className="relative">
        <EditorContent editor={editor} />
        <App>
          <BsCopy
            className="cursor-pointer text-primary hover:bg-primary/15 rounded-sm absolute bottom-[10px] right-[10px] p-1"
            onClick={handleCopy}
            size={20}
            title="Copy content"
          />
        </App>
      </div>
      {NotificationNode ? NotificationNode : null}
      {!skipDataValues && variables.length > 0 && <Variables variables={variables} addVariable={addVariable} />}
    </div>
  );
};

export default TiptapField;
