// import { countries } from "@/data/countries";
import { funding_stages } from "@/utils/funding_stages";
import { countriesDropdown, crustDataCountries } from "@/utils/countries";
import { IFiltersProps, IOperator } from "./filter.schema";
import {
  LuCaseUpper,
  LuFactory,
  LuFrame,
  LuUsers2,
  LuBackpack,
  LuCalendarDays,
  LuMapPin,
  LuDollarSign,
  LuLinkedin,
  LuList,
  LuLink,
  LuLaptop2,
  LuWallet,
  LuBriefcase,
} from "react-icons/lu";
import { GoBriefcase, GoOrganization } from "react-icons/go";
import { CiLaptop } from "react-icons/ci";
import { crustDataIndustries } from "@/utils/industries";

export const filterGroupIcons = {
  Company: <GoOrganization className="h-5 w-5" />,
  Technology: <CiLaptop className="h-5 w-5" />,
  "Job Posting": <GoBriefcase className="h-5 w-5" />,
  Revenue: <LuDollarSign className="h-5 w-5" />,
  Staff: <LuUsers2 className="h-5 w-5" />,
};

export const filters: IFiltersProps[] = [
  {
    id: "company_name",
    icon: LuBackpack,
    title: "Company Name",
    data_type: "string[]",
    availableOperators: [
      IOperator.contains_any_case_insensitive,
      IOperator.contains_any,
      IOperator.not_contains_any,
      IOperator.contains_any_partial_match,
      IOperator.not_contains_any_partial_match,
    ],
    areOperatorsAccumulative: true,
    group: "Company",
  },
  {
    id: "company_description",
    icon: LuBackpack,
    title: "Company Description",
    data_type: "string[]",
    availableOperators: [IOperator.contains_any_regex, IOperator.not_contains_any_regex],
    areOperatorsAccumulative: true,
    group: "Company",
  },
  {
    id: "company_location",
    icon: LuMapPin,
    title: "Company City",
    description: "City or area where the company is located",
    data_type: "string[]",
    availableOperators: [IOperator.contains_any_regex],
    areOperatorsAccumulative: true,
    group: "Company",
  },

  {
    id: "company_domain",
    icon: LuLink,
    title: "Website Domain",
    description: "Company website domain like apple.com, google.com, etc.",
    data_type: "string[]",
    availableOperators: [IOperator.contains_any, IOperator.not_contains_any],
    areOperatorsAccumulative: true,
    group: "Company",
  },
  {
    id: "industry_id",
    icon: LuFactory,
    title: "Industry",
    description: 'Industry the company operates in like "Healthcare", "Finance", "Retail", etc.',
    data_type: "industry[]",
    availableOperators: [IOperator.contains_any, IOperator.not_contains_any],
    areOperatorsAccumulative: true,
    group: "Company",
  },
  {
    id: "company_country_code",
    icon: LuMapPin,
    title: "HQ Country",
    description: "Country where the company is headquartered",
    data_type: "options[]",
    availableOperators: [IOperator.contains_any, IOperator.not_contains_any],
    areOperatorsAccumulative: true,
    availableValues: countriesDropdown,
    group: "Company",
    is_permanent: true,
  },
  {
    id: "employee_count",
    icon: LuUsers2,
    title: "Employees",
    description: "Number of employees in the company",
    data_type: "number[]",
    availableValues: [
      { id: "10", name: "10" },
      { id: "50", name: "50" },
      { id: "200", name: "200" },
      { id: "500", name: "500" },
      { id: "1000", name: "1,000" },
      { id: "5000", name: "5,000" },
      { id: "10000", name: "10,000" },
    ],
    availableOperators: [IOperator.greater, IOperator.less, IOperator.greater_or_null, IOperator.less_or_null],
    areOperatorsAccumulative: true,
    group: "Company",
  },
  {
    id: "revenue_usd",
    icon: LuDollarSign,
    title: "Revenue",
    description: "Annual revenue of the company in USD",
    data_type: "number[]",
    availableValues: [
      { id: "1000000", name: "$1M" },
      { id: "5000000", name: "$5M" },
      { id: "10000000", name: "$10M" },
      { id: "50000000", name: "$50M" },
      { id: "100000000", name: "$100M" },
      { id: "500000000", name: "$500M" },
      { id: "1000000000", name: "$1B" },
      { id: "2000000000", name: "$2B" },
      { id: "5000000000", name: "$5B" },
      { id: "10000000000", name: "$10B" },
      { id: "20000000000", name: "$20B" },
      { id: "50000000000", name: "$50B" },
    ],
    availableOperators: [IOperator.greater, IOperator.less],
    areOperatorsAccumulative: true,
    group: "Company",
  },
  {
    id: "funding_usd",
    icon: LuDollarSign,
    title: "Funding",
    description: "Total funding raised by the company in USD",
    data_type: "number[]",
    availableValues: [
      { id: "100000", name: "$100k" },
      { id: "500000", name: "$500k" },
      { id: "1000000", name: "$1M" },
      { id: "5000000", name: "$5M" },
      { id: "10000000", name: "$10M" },
      { id: "50000000", name: "$50M" },
      { id: "100000000", name: "$100M" },
      { id: "500000000", name: "$500M" },
      { id: "1000000000", name: "$1B" },
    ],
    availableOperators: [IOperator.greater, IOperator.less],
    areOperatorsAccumulative: true,
    group: "Company",
  },
  {
    id: "funding_stage",
    icon: LuDollarSign,
    title: "Funding Stages",
    description: "Stage of funding the company is in like Seed, Series A, Series B, etc.",
    data_type: "options[]",
    availableOperators: [IOperator.contains_any],
    areOperatorsAccumulative: true,
    availableValues: funding_stages,
    group: "Company",
  },
  {
    id: "company_type",
    icon: LuBackpack,
    title: "Company Type",
    description: "Recruiting Agency or Direct Employer",
    data_type: "options",
    availableOperators: [IOperator.is],
    availableValues: [
      { id: "recruiting_agency", name: "Recruiting Agency" },
      { id: "direct_employer", name: "Direct employer" },
    ],
    areOperatorsAccumulative: true,
    group: "Company",
  },
  {
    id: "company_linkedin_url",
    icon: LuLinkedin,
    title: "Linkedin URL",
    description: "Company Linkedin URL",
    data_type: "string[]",
    availableOperators: [IOperator.contains_any],
    areOperatorsAccumulative: true,
    group: "Company",
  },
  {
    id: "company_linkedin_url_exists",
    icon: LuLinkedin,
    title: "Linkedin URL exists",
    description: "Company Linkedin URL",
    data_type: "boolean",
    availableOperators: [IOperator.is],
    areOperatorsAccumulative: true,
    group: "Company",
  },
  {
    id: "revealed_company_data",
    icon: LuWallet,
    title: "Is Company Revealed ?",
    description:
      "Has the company data been revealed in the current billing period? Not credits will be consumed if true",
    data_type: "boolean",
    availableOperators: [IOperator.is],
    areOperatorsAccumulative: true,
    group: "Company",
  },
  {
    id: "company_technology_slug",
    icon: LuLaptop2,
    title: "Technology",
    description: "Technology used by the company",
    data_type: "technologies[]",
    availableOperators: [IOperator.contains_any, IOperator.contains_all, IOperator.not_contains_any],
    areOperatorsAccumulative: true,
    group: "Technology",
    is_permanent: true,
    itemsType: "technologies",
  },
  {
    id: "tech_filters.technology_category_slug",
    icon: LuLaptop2,
    title: "Technology Category",
    description: "Category of the technology like Database, CRM, Analytics, etc.",
    data_type: "technology-category[]",
    availableOperators: [IOperator.contains_any],
    areOperatorsAccumulative: true,
    group: "Technology",
  },
  {
    id: "tech_filters.confidence",
    icon: LuBackpack,
    title: "Technology Confidence",
    description: "Level of confidence (high, medium, low) that the company uses the technology",
    data_type: "options[]",
    availableOperators: [IOperator.contains_any],
    availableValues: [
      { id: "high", name: "High" },
      { id: "medium", name: "Medium" },
      { id: "low", name: "Low" },
    ],
    areOperatorsAccumulative: true,
    group: "Technology",
  },
  {
    id: "tech_filters.first_date_found",
    icon: LuCalendarDays,
    title: "First Date Found",
    description: "Date when of the first job posting by the company mentioning the technology",
    data_type: "number",
    availableOperators: [IOperator.between_dates],
    areOperatorsAccumulative: true,
    group: "Technology",
  },
  {
    id: "tech_filters.last_date_found",
    icon: LuCalendarDays,
    title: "Last Date Found",
    description: "Date when of the last job posting by the company mentioning the technology",
    data_type: "number",
    availableOperators: [IOperator.between_dates],
    areOperatorsAccumulative: true,
    group: "Technology",
  },
  {
    id: "tech_filters.jobs",
    icon: LuFrame,
    title: "Jobs where the technology appears",
    description: "Number of job postings by the company mentioning a technology",
    data_type: "number",
    availableOperators: [IOperator.greater, IOperator.less],
    areOperatorsAccumulative: true,
    group: "Technology",
  },
  {
    id: "job_filters.posted_at",
    icon: LuCalendarDays,
    title: "Posted date",
    description: "Date when the job was posted on a job board or company website",
    data_type: "number",
    availableOperators: [IOperator.max_age_days, IOperator.between_dates],
    areOperatorsAccumulative: false,
    group: "Job Posting",
  },
  {
    id: "job_filters.discovered_at",
    icon: LuCalendarDays,
    title: "Discovered date",
    description: "Date when the job was discovered by TheirStack",
    data_type: "number",
    availableOperators: [IOperator.max_age_days, IOperator.min_age_days, IOperator.between_dates],
    areOperatorsAccumulative: true,
    group: "Job Posting",
  },
  {
    id: "job_filters.job_title",
    icon: LuCaseUpper,
    title: "Job Title",
    description: "Title of the job posting",
    data_type: "string[]",
    availableOperators: [
      IOperator.contains_any,
      IOperator.not_contains_any,
      IOperator.contains_any_regex,
      IOperator.not_contains_any_regex,
      IOperator.contains_all_regex,
    ],
    dependsOnFilterIds: ["job_filters.posted_at", "job_filters.discovered_at"],
    areOperatorsAccumulative: true,
    group: "Job Posting",
  },
  {
    id: "job_filters.job_description",
    icon: LuCaseUpper,
    title: "Job Description",
    description: "Description of the job posting",
    data_type: "string[]",
    availableOperators: [IOperator.contains_any_regex, IOperator.not_contains_any_regex],
    dependsOnFilterIds: ["job_filters.posted_at", "job_filters.discovered_at"],
    areOperatorsAccumulative: true,
    group: "Job Posting",
  },
  {
    id: "job_filters.job_country_code",
    icon: LuMapPin,
    title: "Job Country",
    description: "Country where the job is located",
    data_type: "options[]",
    availableOperators: [IOperator.contains_any, IOperator.not_contains_any],
    dependsOnFilterIds: ["job_filters.posted_at", "job_filters.discovered_at"],
    areOperatorsAccumulative: true,
    availableValues: countriesDropdown,
    group: "Job Posting",
  },
  {
    id: "job_filters.job_location",
    icon: LuMapPin,
    title: "Job Location",
    description: "City or  State where the job is located",
    data_type: "string[]",
    availableOperators: [IOperator.contains_any_regex, IOperator.not_contains_any_regex],
    dependsOnFilterIds: ["job_filters.posted_at", "job_filters.discovered_at"],
    areOperatorsAccumulative: true,
    group: "Job Posting",
  },

  {
    id: "job_filters.remote",
    icon: LuLaptop2,
    title: "Remote",
    description: "Is the job remote?",
    data_type: "boolean",
    availableOperators: [IOperator.is],
    dependsOnFilterIds: ["job_filters.posted_at", "job_filters.discovered_at"],
    areOperatorsAccumulative: true,
    group: "Job Posting",
  },
  {
    id: "job_filters.salary_usd",
    icon: LuDollarSign,
    title: "Annual Salary",
    description: "Annual salary of the job in USD",
    data_type: "number",
    availableOperators: [IOperator.greater, IOperator.less],
    dependsOnFilterIds: ["job_filters.posted_at", "job_filters.discovered_at"],
    areOperatorsAccumulative: true,
    group: "Job Posting",
  },
  {
    id: "job_filters.job_technology_slug",
    icon: LuLaptop2,
    title: "Job Technology",
    description: "Technology mentioned in the job posting",
    data_type: "technologies[]",
    availableOperators: [IOperator.contains_any, IOperator.contains_all, IOperator.not_contains_any],
    dependsOnFilterIds: ["job_filters.posted_at", "job_filters.discovered_at"],
    areOperatorsAccumulative: true,
    group: "Job Posting",
    itemsType: "technologies",
  },
  {
    id: "job_filters.scraper_name_pattern",
    icon: LuList,
    title: "Scraping Source",
    description: "Source where the job was scraped from",
    data_type: "options[]",
    availableOperators: [IOperator.contains_any],
    dependsOnFilterIds: ["job_filters.posted_at", "job_filters.discovered_at"],
    areOperatorsAccumulative: true,
    availableValues: [
      { id: "Indeed", name: "Indeed" },
      { id: "LinkedIn", name: "LinkedIn" },
      { id: "Infojobs", name: "Infojobs" },
      { id: "workable", name: "Workable" },
      { id: "otta", name: "Otta" },
      { id: "Startup.jobs", name: "Startup.jobs" },
      { id: "Tecnoempleo", name: "Tecnoempleo" },
      { id: "YC", name: "YC" },
      { id: "Jooble", name: "Jooble" },
      { id: "Manfred", name: "Manfred" },
      { id: "Remotive", name: "Remotive" },
      { id: "PMFarma", name: "PMFarma" },
      { id: "Angellist", name: "Angellist" },
      { id: "Circular", name: "Circular" },
    ],
    group: "Job Posting",
  },
];

export const crustDataLinkedinFilters: IFiltersProps[] = [
  {
    id: "COMPANY_HEADCOUNT",
    icon: LuBackpack,
    title: "Company Headcount",
    data_type: "options",
    availableOperators: [IOperator.in],
    areOperatorsAccumulative: false,
    group: "Staff",
    availableValues: [
      { id: "1-10", name: "1-10" },
      { id: "11-50", name: "11-50" },
      { id: "51-200", name: "51-200" },
      { id: "201-500", name: "201-500" },
      { id: "501-1,000", name: "501-1,000" },
      { id: "1,001-5,000", name: "1,001-5,000" },
      { id: "5,001-10,000", name: "5,001-10,000" },
      { id: "10,001+", name: "10,001+" },
    ],
  },
  {
    id: "REGION",
    icon: LuMapPin,
    title: "Region",
    data_type: "technologies[]",
    // availableOperators: [IOperator.in, IOperator.not_in],
    availableOperators: [IOperator.in, IOperator.not_in],
    areOperatorsAccumulative: false,
    availableValues: crustDataCountries,
    group: "Company",
    itemsType: "regions",
  },
  {
    id: "INDUSTRY",
    icon: LuFactory,
    title: "Industry",
    data_type: "options[]",
    availableOperators: [IOperator.in, IOperator.not_in],
    areOperatorsAccumulative: false,
    availableValues: crustDataIndustries,
    group: "Company",
  },
  {
    id: "NUM_OF_FOLLOWERS",
    icon: LuUsers2,
    title: "Number of Followers",
    data_type: "options",
    availableValues: [
      { id: "1-50", name: "1-50" },
      { id: "51-100", name: "51-100" },
      { id: "101-1000", name: "101-1000" },
      { id: "1001-5000", name: "1001-5000" },
      { id: "5001+", name: "5001+" },
    ],
    availableOperators: [IOperator.in],
    areOperatorsAccumulative: false,
    group: "Company",
  },
  {
    id: "FORTUNE",
    icon: LuDollarSign,
    title: "Fortune",
    data_type: "options",
    availableOperators: [IOperator.in],
    areOperatorsAccumulative: false,
    group: "Revenue",
    availableValues: [
      { id: "Fortune 50", name: "Fortune 50" },
      { id: "Fortune 51-100", name: "Fortune 51-100" },
      { id: "Fortune 101-250", name: "Fortune 101-250" },
      { id: "Fortune 251-500", name: "Fortune 251-500" },
    ],
  },
  {
    id: "ACCOUNT_ACTIVITIES",
    icon: LuList,
    title: "Account Activities",
    data_type: "options",
    availableOperators: [IOperator.in],
    areOperatorsAccumulative: false,
    group: "Company",
    availableValues: [
      { id: "Senior leadership changes in last 3 months", name: "Senior leadership changes in last 3 months" },
      { id: "Funding events in past 12 months", name: "Funding events in past 12 months" },
    ],
  },
  {
    id: "JOB_OPPORTUNITIES",
    availableValues: [{ id: "Hiring on Linkedin", name: "Hiring on Linkedin" }],
    icon: LuBriefcase,
    title: "Job Opportunities",
    data_type: "options",
    availableOperators: [IOperator.in],
    areOperatorsAccumulative: false,
    group: "Staff",
  },
  // marked
  // {
  //   id: "COMPANY_HEADCOUNT_GROWTH",
  //   icon: LuUsers2,
  //   title: "Company Headcount Growth",
  //   data_type: "options",
  //   availableValues: [
  //     { id: "10", name: "10" },
  //     { id: "50", name: "50" },
  //     { id: "200", name: "200" },
  //     { id: "500", name: "500" },
  //     { id: "1000", name: "1,000" },
  //     { id: "5000", name: "5,000" },
  //     { id: "10000", name: "10,000" },
  //   ],
  //   availableOperators: [IOperator.between],
  //   areOperatorsAccumulative: true,
  //   group: "Staff",
  // },
  {
    id: "ANNUAL_REVENUE",
    icon: LuDollarSign,
    title: "Annual Revenue",
    data_type: "options-subfilter",
    availableValues: [
      { id: "USD", name: "USD" },
      { id: "AED", name: "AED" },
      { id: "AUD", name: "AUD" },
      { id: "BRL", name: "BRL" },
      { id: "CAD", name: "CAD" },
      { id: "CNY", name: "CNY" },
      { id: "DKK", name: "DKK" },
      { id: "EUR", name: "EUR" },
      { id: "GBP", name: "GBP" },
      { id: "HKD", name: "HKD" },
      { id: "IDR", name: "IDR" },
      { id: "ILS", name: "ILS" },
      { id: "INR", name: "INR" },
      { id: "JPY", name: "JPY" },
      { id: "NOK", name: "NOK" },
      { id: "NZD", name: "NZD" },
      { id: "RUB", name: "RUB" },
      { id: "SEK", name: "SEK" },
      { id: "SGD", name: "SGD" },
      { id: "THB", name: "THB" },
      { id: "TRY", name: "TRY" },
      { id: "TWD", name: "TWD" },
    ],
    availableOperators: [IOperator.between],
    areOperatorsAccumulative: false,
    group: "Revenue",
  },
  {
    id: "DEPARTMENT_HEADCOUNT",
    icon: LuUsers2,
    title: "Department Headcount",
    data_type: "options-subfilter",
    availableValues: [
      { id: "Accounting", name: "Accounting" },
      { id: "Administrative", name: "Administrative" },
      { id: "Arts and Design", name: "Arts and Design" },
      { id: "Business Development", name: "Business Development" },
      { id: "Community and Social Services", name: "Community and Social Services" },
      { id: "Consulting", name: "Consulting" },
      { id: "Education", name: "Education" },
      { id: "Engineering", name: "Engineering" },
      { id: "Entrepreneurship", name: "Entrepreneurship" },
      { id: "Finance", name: "Finance" },
      { id: "Healthcare Services", name: "Healthcare Services" },
      { id: "Human Resources", name: "Human Resources" },
      { id: "Information Technology", name: "Information Technology" },
      { id: "Legal", name: "Legal" },
      { id: "Marketing", name: "Marketing" },
      { id: "Media and Communication", name: "Media and Communication" },
      { id: "Military and Protective Services", name: "Military and Protective Services" },
      { id: "Operations", name: "Operations" },
      { id: "Product Management", name: "Product Management" },
      { id: "Program and Project Management", name: "Program and Project Management" },
      { id: "Purchasing", name: "Purchasing" },
      { id: "Quality Assurance", name: "Quality Assurance" },
      { id: "Real Estate", name: "Real Estate" },
      { id: "Research", name: "Research" },
      { id: "Sales", name: "Sales" },
      { id: "Customer Success and Support", name: "Customer Success and Support" },
    ],
    availableOperators: [IOperator.between],
    areOperatorsAccumulative: false,
    group: "Staff",
  },
  {
    id: "DEPARTMENT_HEADCOUNT_GROWTH",
    icon: LuUsers2,
    title: "Department Headcount Growth",
    data_type: "options-subfilter",
    availableValues: [
      { id: "Accounting", name: "Accounting" },
      { id: "Administrative", name: "Administrative" },
      { id: "Arts and Design", name: "Arts and Design" },
      { id: "Business Development", name: "Business Development" },
      { id: "Community and Social Services", name: "Community and Social Services" },
      { id: "Consulting", name: "Consulting" },
      { id: "Education", name: "Education" },
      { id: "Engineering", name: "Engineering" },
      { id: "Entrepreneurship", name: "Entrepreneurship" },
      { id: "Finance", name: "Finance" },
      { id: "Healthcare Services", name: "Healthcare Services" },
      { id: "Human Resources", name: "Human Resources" },
      { id: "Information Technology", name: "Information Technology" },
      { id: "Legal", name: "Legal" },
      { id: "Marketing", name: "Marketing" },
      { id: "Media and Communication", name: "Media and Communication" },
      { id: "Military and Protective Services", name: "Military and Protective Services" },
      { id: "Operations", name: "Operations" },
      { id: "Product Management", name: "Product Management" },
      { id: "Program and Project Management", name: "Program and Project Management" },
      { id: "Purchasing", name: "Purchasing" },
      { id: "Quality Assurance", name: "Quality Assurance" },
      { id: "Real Estate", name: "Real Estate" },
      { id: "Research", name: "Research" },
      { id: "Sales", name: "Sales" },
      { id: "Customer Success and Support", name: "Customer Success and Support" },
    ],
    availableOperators: [IOperator.between],
    areOperatorsAccumulative: false,
    group: "Staff",
  },
  {
    id: "KEYWORD",
    icon: LuCaseUpper,
    title: "Keyword",
    data_type: "string[]",
    availableOperators: [IOperator.in],
    areOperatorsAccumulative: false,
    group: "Company",
  },
];
