import { memo } from "react";
import WorkflowIcon from "../../../assets/SVGs/newWorkflowBlue.svg";
import { MdMoreHoriz } from "react-icons/md";
import moment from "moment";
import { DISPLAY_DATE_TIME_FORMAT } from "@/utils/constants";
import { Link } from "react-router-dom";
import { Dropdown } from "antd";

interface WorkflowProps {
  workflow: any;
  getWorkflowActionMenu: any;
  RenameWorkflow: any;
  toRenameWorkflow: any | null;
}

const Workflow = memo(
  ({ workflow, getWorkflowActionMenu, RenameWorkflow, toRenameWorkflow }: WorkflowProps) => {
    if (!workflow) return;

    return (
      <div className="px-2 py-1 flex justify-between items-center hover:bg-[#efe5ffc7]">
        <div className="flex p-4 pl-5 gap-x-3 text-[22px] items-center font-normal w-3/4">
          <img src={WorkflowIcon} alt="workflow-icon" width={18} />
          {!toRenameWorkflow || toRenameWorkflow.id !== workflow.id ? (
            <Link to={`/workflow/${workflow.id}?v2=${workflow.supabase}`} className="!text-gray-500">
              {(workflow.name || workflow.draftWorkflowConfig.name).length > 70
                ? `${(workflow.name || workflow.draftWorkflowConfig.name).slice(0, 67)}...`
                : workflow.name || workflow.draftWorkflowConfig.name}
            </Link>
          ) : (
            <RenameWorkflow />
          )}
        </div>
        <div className="absolute right-0 flex items-center justify-between w-[21rem]">
          <p className="w-1/2 text-[18px] text-gray-500">
            {moment.utc(workflow.createdAt).local().format(DISPLAY_DATE_TIME_FORMAT)}
          </p>
          <div className="hoverClass w-1/2 flex items-center justify-center">
            <Dropdown
              overlay={getWorkflowActionMenu(workflow)}
              trigger={["click"]}
              overlayStyle={{
                width: "11rem",
              }}
            >
              <button
                className="flex justify-center h-fit items-center p-1 rounded-md transition-opacity duration-600 ease-in-out hover:opacity-100 hover:bg-gray-200"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                }}
              >
                <MdMoreHoriz size={24} />
              </button>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    // Skip comparison for props that never change
    return (
      prevProps.workflow === nextProps.workflow &&
      prevProps.toRenameWorkflow === nextProps.toRenameWorkflow &&
      prevProps.getWorkflowActionMenu === nextProps.getWorkflowActionMenu
    );
  }
);

export default Workflow;
