import React, { useEffect, useState } from "react";
import { CELL_STATUS_TYPE } from "@/utils/constants";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { Dropdown, MenuProps } from "antd";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { VscOpenPreview } from "react-icons/vsc";

interface Props {
  actionId: string;
  userWorkflowId: string;
  section: number;
  style: any;
  // showModalNearCell: (e: React.MouseEvent<HTMLButtonElement>) => void;
  selectedRows: any;
  setSelectedRows: () => void;
}

const MultiReviewModal: React.FC<Props> = ({
  actionId,
  userWorkflowId,
  section,
  style,
  // showModalNearCell,
  selectedRows,
  setSelectedRows,
}) => {
  const { markActionAsReviewedAndMoveOn, dataRef } = useWorkflow();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataIds, setDataIds] = useState<string[]>([]);
  const [reviewIds, setReviewIds] = useState<string[]>([]);

  useEffect(() => {
    const wrapperDataArr = dataRef.current.reduce((acc, curr) => {
      return [...acc, curr[actionId || ""]];
    }, []);
    setReviewIds(() =>
      wrapperDataArr
        .filter((cellData: any) => cellData && [CELL_STATUS_TYPE.READY_TO_BE_REVIEWED].includes(cellData.status))
        .map((cellData: any) => cellData?.extra?.dataId)
    );
  }, [actionId, dataRef.current]);

  useEffect(() => {
    setDataIds(() => {
      if (selectedRows && selectedRows.length) return reviewIds.filter((id) => selectedRows.includes(id));
      return [];
    });
  }, [reviewIds, selectedRows]);

  const handleRegenerateAll = async () => {
    try {
      setLoading(true);
      markActionAsReviewedAndMoveOn(actionId || "", userWorkflowId, [], reviewIds, section, "all")
        .catch(() => {
          console.error("Error making call for regeneration");
        })
        .finally(() => {
          setOpen(false);
          setLoading(false);
        });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };
  const handleRegenerateColumnAll = async () => {
    try {
      setLoading(true);
      markActionAsReviewedAndMoveOn(actionId || "", userWorkflowId, [], reviewIds, section, "singleColumn")
        .catch(() => {
          console.error("Error making call for regeneration");
        })
        .finally(() => {
          setOpen(false);
          setLoading(false);
        });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const handleRegenerateSelected = async () => {
    try {
      setLoading(true);
      markActionAsReviewedAndMoveOn(actionId || "", userWorkflowId, dataIds, dataIds, section, "selected")
        .catch(() => {
          console.error("Error making call for regeneration");
        })
        .finally(() => {
          setOpen(false);
          setLoading(false);
          setSelectedRows();
        });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };
  const handleRegenerateColumnSelected = async () => {
    try {
      setLoading(true);
      markActionAsReviewedAndMoveOn(actionId || "", userWorkflowId, dataIds, dataIds, section, "singleColumnSelected")
        .catch(() => {
          console.error("Error making call for regeneration");
        })
        .finally(() => {
          setOpen(false);
          setLoading(false);
          setSelectedRows();
        });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const items: MenuProps["items"] = [
    !dataIds.length && {
      key: "1",
      label: (
        <div className="flex items-center gap-2">
          {loading && <SpinnerStatus />}
          <span>Review all rows and continue</span>
        </div>
      ),
      onClick: handleRegenerateAll,
      disabled: loading,
    },
    dataIds.length && {
      key: "3",
      label: (
        <div className={`flex items-center gap-2`}>
          {loading && <SpinnerStatus />}
          <span>Review {dataIds.length} row(s) and continue</span>
        </div>
      ),
      onClick: handleRegenerateSelected,
      disabled: loading,
    },
    !dataIds.length && {
      key: "4",
      label: (
        <div className="flex items-center gap-2">
          {loading && <SpinnerStatus />}
          <span>Review all rows in this column</span>
        </div>
      ),
      onClick: handleRegenerateColumnAll,
      disabled: loading || loading,
    },
    dataIds.length && {
      key: "5",
      label: (
        <div className={`flex items-center gap-2`}>
          {loading && <SpinnerStatus />}
          <span>Review {dataIds.length} row(s) in this column</span>
        </div>
      ),
      onClick: handleRegenerateColumnSelected,
      disabled: loading,
    },
  ] as MenuProps["items"];

  return (
    <Dropdown
      open={open}
      overlayStyle={{ ...style, height: 100 }}
      onOpenChange={(o) => {
        if (loading) return;
        setOpen(o);
      }}
      trigger={["click"]}
      menu={{ items }}
    >
      <button
        type="button"
        onClick={() => {
          // setRegenId(() => actionId || "");
          setOpen(true);
          // showModalNearCell(e);
        }}
        className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-300 p-2"
      >
        {loading ? <SpinnerStatus /> : <VscOpenPreview size={"12px"} />}
      </button>
    </Dropdown>
  );
};

export default MultiReviewModal;
