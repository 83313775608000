import React from "react";
import { Action, ResponseConfiguration, ResponseStructure } from "../../../../utils/interfaces";
import { TbTextSize, TbBraces } from "react-icons/tb";
import { GoNumber } from "react-icons/go";
import { RxSwitch } from "react-icons/rx";
import { IoIosArrowDown, IoIosArrowForward, IoMdLink } from "react-icons/io";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { PiBracketsSquareBold } from "react-icons/pi";
import { IconType } from "react-icons/lib";
import { Switch } from "antd";

interface KeyValueItem {
  _id: string;
  name: string;
  value: any;
  type: string;
  icon: IconType;
  display_column: boolean;
}

const ICON_MAP: Record<string, IconType> = {
  string: TbTextSize,
  number: GoNumber,
  boolean: RxSwitch,
  textFileURL: IoMdLink,
  json: TbBraces,
  object: TbBraces,
  table: TbBraces,
  jsonArray: PiBracketsSquareBold,
  array: PiBracketsSquareBold,
  sectionList: PiBracketsSquareBold,
  date: IoCalendarNumberOutline,
  unknown: TbBraces,
};

function keyValueItemFromData(data: any, name?: string): any {
  const type = typeof data;
  switch (type) {
    case "string":
      return {
        name: name ?? "string",
        value: data,
        type: "string",
        icon: ICON_MAP.string,
      };
    case "number":
      return {
        name: name ?? "number",
        value: data,
        type: "number",
        icon: ICON_MAP.number,
      };
    case "boolean":
      return {
        name: name ?? "boolean",
        value: data,
        type: "boolean",
        icon: ICON_MAP.boolean,
      };
    case "object": {
      if (Array.isArray(data)) {
        return data.map((item, index) => keyValueItemFromData(item, `${name}[${index}]`));
      }
      return {
        name: name ?? "object",
        value: data,
        type: "object",
        icon: ICON_MAP.object,
      };
    }
    default:
      return {
        name: name ?? "unknown",
        value: data,
        type: "unknown",
        icon: ICON_MAP.unknown,
      };
  }
}

function dummyData(structure: ResponseStructure): any {
  if (structure.type === "sectionList") {
    const data: Record<string, KeyValueItem> = {};
    structure.sectionVariablesStructure?.forEach((s) => {
      data[s.name] = {
        name: s.name,
        value: dummyData(s),
        type: s.type ?? "unknown",
        icon: ICON_MAP[s.type ?? "unknown"],
        display_column: s.display_column ?? false,
        _id: s.responseStructureId ?? "",
      };
    });
    return data;
  }
  switch (structure.type) {
    case "string":
      return "string";
    case "number":
      return 0;
    case "boolean":
      return true;
    case "textFileURL":
      return "https://storage.googleapis.com/../file.txt";
    case "json":
    case "object":
    case "table":
      return { a: "b", c: "d", key: 2, nested: { e: "f" } };
    case "jsonArray":
      return [{ a: "b" }, { c: "d" }, { key: 2 }, { nested: { e: "f" } }];
    case "array":
      return ["string 1", "string 2", 1, 2, true, false];
    case "date":
      return new Date();
    default:
      throw new Error("Unknown type");
  }
}

function constructStructure(structure: ResponseStructure[]): Record<string, KeyValueItem> {
  const data: Record<string, KeyValueItem> = {};
  structure.forEach((s) => {
    data[s.name] = {
      name: s.name,
      value: dummyData(s),
      type: s.type ?? "unknown",
      icon: ICON_MAP[s.type ?? "unknown"],
      display_column: s.display_column ?? false,
      _id: s.responseStructureId ?? "",
    };
  });
  return data;
}

function KeyValue({
  item,
  switchChange,
  outer = false,
}: {
  item: KeyValueItem | KeyValueItem[];
  switchChange: (checked: boolean, name: string) => void;
  outer?: boolean;
}): React.ReactElement {
  const [isOpen, setIsOpen] = React.useState(false);

  if (Array.isArray(item)) {
    return (
      <div className="flex flex-col">
        {item.map((i) => (
          <KeyValue key={i.name} item={i} switchChange={switchChange} />
        ))}
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="p-2 border border-gray-200 rounded flex items-center flex-wrap gap-2 max-w-full overflow-x-auto relative group bg-gray-100 mt-1">
        <div className="flex flex-row w-full items-center justify-between">
          <div className="flex items-center">
            {item.type !== "string" &&
              item.type !== "number" &&
              item.type !== "boolean" &&
              item.type !== "textFileURL" && (
                <button className="mr-2" onClick={() => setIsOpen((prev) => !prev)}>
                  {isOpen ? <IoIosArrowDown /> : <IoIosArrowForward />}
                </button>
              )}
            <p className="flex flex-row self-start items-center">
              <span className="font-semibold text-md line-clamp-1">{item.name}:</span>
              <span className="ml-2 text-xs max-w-48 text-gray-500 truncate text-ellipsis mt-0.5">
                {JSON.stringify(item.value)}
              </span>
            </p>
          </div>
          <div className="flex items-center">
            <item.icon />
            <span className="ml-1">{item.type}</span>
            {outer && (
              <Switch
                size="small"
                checked={item.display_column}
                className="ml-2"
                onChange={(checked) => switchChange(checked, item._id)}
              />
            )}
          </div>
        </div>
      </div>
      {["object", "json", "table"].includes(item.type) && isOpen && (
        <div className="ml-4">
          {Object.entries(item.value).map(([key, value]) => (
            <KeyValue key={key} item={keyValueItemFromData(value, key)} switchChange={switchChange} />
          ))}
        </div>
      )}
      {["array", "jsonArray"].includes(item.type) && isOpen && (
        <div className="ml-4">
          {item.value.map((value: any, index: number) => (
            <KeyValue
              key={index}
              item={keyValueItemFromData(value, `${item.name}[${index}]`)}
              switchChange={switchChange}
            />
          ))}
        </div>
      )}
      {item.type === "sectionList" && isOpen && (
        <div className="ml-4">
          {Object.values(item.value).map((value: any) => (
            <KeyValue key={value.name} item={value} switchChange={switchChange} />
          ))}
        </div>
      )}
    </div>
  );
}

export default function ResponseStructureViewer({
  action,
  responses,
  setResponses,
  setChanged,
}: {
  action: Action;
  responses: ResponseConfiguration[];
  setResponses: React.Dispatch<React.SetStateAction<ResponseConfiguration[]>>;
  setChanged: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement {
  const [data, setData] = React.useState<any>(action.responseStructure);
  const structure = React.useMemo(() => constructStructure(data), [data]);

  React.useEffect(() => {
    const newData = [...data];
    newData.forEach((d) => {
      const index = responses.findIndex((r) => r.responseStructureId === d.responseStructureId);
      if (index === -1) return;
      d.display_column = responses[index].display_column;
    });
    setData(newData);
  }, [responses]);

  const handleSwitchChange = (checked: boolean, name: string) => {
    setResponses((prev) => {
      const newResponses: any[] = [...prev];
      const index = newResponses.findIndex((r) => r.responseStructureId === name);
      if (index === -1)
        newResponses.push({
          responseStructureId: name,
          display_column: checked,
        });
      else newResponses[index].display_column = checked;
      return newResponses;
    });
    setChanged(true);
  };

  return (
    <div className="flex flex-col border-2 p-3 rounded-lg">
      <div className="flex flex-col gap-2 mb-4">
        <h2 className="text-lg font-semibold">Response Structure</h2>
        <p className="text-sm text-gray-500">
          This is the structure of the response that will be returned by the action.
        </p>
      </div>
      {Object.values(structure).map((item) => (
        <KeyValue switchChange={handleSwitchChange} outer={true} key={item.name} item={item} />
      ))}
    </div>
  );
}
