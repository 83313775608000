import { setStateType } from "../../../utils/constants";
import { useEffect, useState, useCallback } from "react";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import TiptapField from "../Tiptap/TiptapField";
import { Action, PayloadConfiguration } from "../../../utils/interfaces";
import { Alert, ConfigProvider, Drawer, Select, Skeleton, Switch } from "antd";
import { FaPlus, FaRegEdit } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";
import RunCondition from "./ModalComponents/RunCondition";
import ResponseStructureViewer from "./ModalComponents/ResponseStructure";
import { useConnections } from "@/contexts/ConnectionContext";

const SALESFORCE_OBJECT_PAYLOAD_STRUCTURE_ID = "sfObject";
const SALESFORCE_OBJECT_FIELDS_PAYLOAD_STRUCTURE_ID = "objectFields";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  payloadConfiguration: PayloadConfiguration[];
  responseConfiguration: any[];
  variables: any[];
  type: string;
};

const CrmModal = ({ action, modal, setModal, payloadConfiguration, responseConfiguration, variables, type }: Props) => {
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [sfObjects, setSfObjects] = useState<{
    loading: boolean;
    sfObjects: any[];
  }>({
    loading: false,
    sfObjects: [],
  });
  const [sfObjectFields, setSfObjectFields] = useState<{
    loading: boolean;
    sfFields: any[];
  }>({
    loading: false,
    sfFields: [],
  });
  const [sfObjectItems, setSfObjectItems] = useState<{
    [fieldName: string]: { loading: boolean; sfItems: any[] };
  }>({});
  const [updateField, setUpdateField] = useState(0);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const [responses, setResponses] = useState(responseConfiguration);
  const [runCondition, setRunCondition] = useState(action.runCondition);
  const {
    updatePayloadConfig,
    updateResponseConfig,
    getSalesforceObjects,
    getSfObjectsFields,
    getSfObjectItems,
    saveWorkflowActions,
    updateActionName,
    setPublishWarning,
  } = useWorkflow();
  const { getConnectionFromType } = useConnections();
  const [isConnectionExists, setIsConnectionExists] = useState(true);
  const [changeName, setChangeName] = useState(false);
  const { id } = useParams();
  const objectIDX =
    payloads.findIndex((input) => input.payloadStructureId === SALESFORCE_OBJECT_PAYLOAD_STRUCTURE_ID) || 0;
  const fieldIDX =
    payloads.findIndex((input) => input.payloadStructureId === SALESFORCE_OBJECT_FIELDS_PAYLOAD_STRUCTURE_ID) || 1;

  useEffect(() => {
    if (type === "") return;
    setSfObjects({ loading: true, sfObjects: [] });
    getSalesforceObjects().then((data: any) => {
      setSfObjects({ loading: false, sfObjects: data.sfObjects });
    });
  }, []);

  useEffect(() => {
    if (!action?.connectionId) return;
    getConnectionFromType(action?.connectionId).then((connection: any) => {
      setIsConnectionExists(connection?.userConnection ? true : false);
    });
  }, [action?.connectionId]);

  // needed to auto-fetch selected object fields after reopening the action modal
  useEffect(() => {
    setSfObjectFields({ loading: true, sfFields: [] });
    const selectedObject = payloads[objectIDX]?.inputString;
    getSfObjectsFields(selectedObject).then((fields) => {
      const filteredFields = fields.filter((field: any) => field.createable === true);
      setSfObjectFields({
        loading: false,
        sfFields: filteredFields,
      });
    });
  }, [setSfObjects]);

  // needed to auto fetch selected fields' items after reopening the action modal
  useEffect(() => {
    payloads[fieldIDX]?.inputString?.forEach((input: any) => {
      if (input.reference !== undefined) {
        setSfObjectItems((prev) => ({
          ...prev,
          [input.name]: { loading: true, sfItems: [] },
        }));
        getSfObjectItems(input.reference).then((items) => {
          setSfObjectItems((prev) => ({
            ...prev,
            [input.name]: { loading: false, sfItems: items },
          }));
        });
      }
    });
  }, [setSfObjectFields]);

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setUpdateField((prev) => prev + 1);
  }, [payloadConfiguration]);

  useEffect(() => {
    if (payloads !== payloadConfiguration) setChanged(true);
    else setChanged(false);
  }, [payloads]);

  const saveOutputStructure = async () => {
    setLoading(true);
    const updated = updatePayloadConfig(payloads, action.id, false, action.continueOnFail, runCondition);
    setPayloads(updated);
    const updatedResponses = updateResponseConfig(responses, action.id);
    setResponses(updatedResponses);
    await saveWorkflowActions(id || "");
    setChanged(false);
    setLoading(false);
    setPublishWarning(true);
    setModal(false);
  };

  // fetches the object's fields and mark each field as selected: false, to keep track of selected fields
  const handleObjectSelect = useCallback(
    async (selectedObjectName: any) => {
      setSfObjectFields({
        loading: true,
        sfFields: [],
      });
      // Clear the selected fields in payloads
      const temp = [...payloads];
      const objectIndex = temp.findIndex(
        (input) => input.payloadStructureId === SALESFORCE_OBJECT_FIELDS_PAYLOAD_STRUCTURE_ID
      );

      if (objectIndex !== -1) {
        temp[objectIndex].inputString = [];
      }
      setPayloads(temp);
      // Fetch the fields of the selected object
      getSfObjectsFields(selectedObjectName).then((fields) => {
        const filteredFields = fields.filter((field: any) => field.createable === true);
        setSfObjectFields({
          loading: false,
          sfFields: filteredFields,
        });
      });
    },
    [sfObjectFields]
  );

  // fetches the selected field's items through reference object
  const handleFieldSelect = useCallback(
    async (value: string, reference: any) => {
      setSfObjectItems((prevState) => ({
        ...prevState,
        [value]: { loading: true, sfItems: [] },
      }));
      const items = await getSfObjectItems(reference);
      setSfObjectItems((prevState) => ({
        ...prevState,
        [value]: { loading: false, sfItems: items },
      }));
    },
    [sfObjectItems]
  );

  const fieldSwitchCase = (field: any) => {
    switch (field.type) {
      case "dropdown":
        return (
          <Skeleton loading={sfObjects.loading} paragraph={false} active={sfObjects.loading}>
            <Select
              showSearch
              defaultValue={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
              }
              onChange={(value) => {
                handleObjectSelect(value);
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString = value;
                setPayloads(temp);
              }}
              options={
                type === ""
                  ? field.values?.map((value: any) => ({
                      label: value.name,
                      value: value.name,
                    }))
                  : sfObjects.sfObjects?.map((value: any) => ({
                      label: value.label,
                      value: value.name,
                    }))
              }
            />
          </Skeleton>
        );
      case "normalDropdown":
        return (
          <Select
            showSearch
            defaultValue={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || "OR"
            }
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              temp[index].inputString = value;
              setPayloads(temp);
            }}
            optionFilterProp="label"
            options={field.values?.map((value: any) => ({
              label: value.name,
              value: value.name,
            }))}
          />
        );
      case "jsonArray":
        return (
          <div className="w-full flex flex-col gap-4">
            {payloads
              .find((input) => input.payloadStructureId === field.payloadStructureId)
              ?.inputString.map((input: any, idx: number) => {
                return (
                  <div className="flex gap-2 justify-between">
                    <div className="w-full" key={idx}>
                      <Skeleton
                        loading={sfObjectFields.loading}
                        paragraph={false}
                        active={sfObjectFields.loading}
                        style={{ marginBottom: "0.25rem" }}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%", marginBottom: "0.25rem" }}
                          value={
                            payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                              ?.inputString[idx].label || ""
                          }
                          onChange={(value, options: any) => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString[idx].name = value;
                            temp[index].inputString[idx].label = options.label;
                            temp[index].inputString[idx].type = options.type;
                            temp[index].inputString[idx].reference = options.reference;
                            // temp[index].inputString[idx].selected = true;
                            if (options.reference !== undefined) {
                              handleFieldSelect(value, options.reference);
                            }
                            // const updatedSfFields = sfObjectFields.sfFields.map(field => {
                            //   if (field.name === value) {
                            //     return { ...field, selected: true };
                            //   }
                            //   return field;
                            // });
                            setSfObjectFields({
                              loading: false,
                              sfFields: sfObjectFields.sfFields,
                            });
                            setPayloads(temp);
                          }}
                          options={
                            type === ""
                              ? field.values?.map((value: any) => ({
                                  label: value.name,
                                  value: value.name,
                                }))
                              : sfObjectFields.sfFields
                                  // .filter((field: any) => field.selected === false)
                                  .map((field: any) => ({
                                    label: field.label,
                                    value: field.name,
                                    type: field.type,
                                    reference: field.referenceTo[0],
                                    // selected: field.selected,
                                  }))
                          }
                        />
                      </Skeleton>
                      {input.type !== "boolean" ? (
                        input.reference !== undefined ? (
                          <Skeleton
                            loading={sfObjectItems[input.name]?.loading}
                            paragraph={false}
                            active={sfObjectItems[input.name]?.loading}
                          >
                            <Select
                              showSearch
                              style={{ width: "100%" }}
                              defaultValue={
                                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                                  ?.inputString[idx].label2 || ""
                              }
                              onChange={(value, options: any) => {
                                const temp = [...payloads];
                                const index = payloads.findIndex(
                                  (input) => input.payloadStructureId === field.payloadStructureId
                                );
                                temp[index].inputString[idx].value = value;
                                temp[index].inputString[idx].label2 = options.label2;
                                setPayloads(temp);
                              }}
                              options={
                                type === ""
                                  ? field.values?.map((value: any) => ({
                                      label: value.name,
                                      value: value.name,
                                    }))
                                  : sfObjectItems[input.name]?.sfItems?.map((value: any) => ({
                                      label: value.Name,
                                      label2: value.Name,
                                      value: value.Id,
                                    }))
                              }
                            />
                          </Skeleton>
                        ) : (
                          <TiptapField
                            content={
                              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                                ?.inputString[idx].tiptapJson || ""
                            }
                            setContent={(content: any, text: string) => {
                              const temp = [...payloads];
                              const index = payloads.findIndex(
                                (input) => input.payloadStructureId === field.payloadStructureId
                              );
                              temp[index].inputString[idx].value = text;
                              temp[index].inputString[idx].tiptapJson = content;
                              setPayloads(temp);
                            }}
                            refresh={updateField}
                            placeholder="Enter the value"
                            variables={variables}
                          />
                        )
                      ) : (
                        <Switch
                          style={{
                            width: "fit-content",
                            marginTop: "0.25rem",
                            marginLeft: "0.20rem",
                          }}
                          defaultChecked={
                            payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                              ?.inputString[idx].value === true
                          }
                          checkedChildren="True"
                          unCheckedChildren="False"
                          onChange={(value) => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString[idx].value = value;
                            setPayloads(temp);
                          }}
                        />
                      )}
                    </div>
                    <button
                      className="hover:bg-primary/10 h-fit w-fit p-2 rounded-full text-primary"
                      onClick={() => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        // setSfObjectFields({
                        //   loading: false,
                        //   sfFields: sfObjectFields.sfFields
                        //   .map(field => {
                        //     if (field.name === temp[index].inputString[idx].name) {
                        //       return { ...field, selected: false };
                        //     }
                        //     return field;
                        //   }),
                        // })
                        temp[index].inputString.splice(idx, 1);
                        setUpdateField((prev) => prev + 1);
                        setPayloads(temp);
                      }}
                    >
                      <MdDeleteOutline size={25} />
                    </button>
                  </div>
                );
              })}
            <button
              className="text-primary flex gap-2 items-center"
              onClick={() => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString.push({
                  name: "",
                  value: "",
                });
                setPayloads(temp);
              }}
            >
              <FaPlus />
              Add a new Variable Name and Variable Value pair
            </button>
          </div>
        );
      case "radio":
        return (
          <Switch
            className="w-fit"
            defaultChecked={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString === true ||
              false
            }
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              temp[index].inputString = value;
              setPayloads(temp);
            }}
          />
        );
      default:
        return (
          <textarea
            className="w-full h-32 rounded-lg"
            placeholder="Enter the value"
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(e) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: e.target.value,
                  type: field.type,
                });
              } else {
                temp[index].inputString = e.target.value;
              }
              setPayloads(temp);
            }}
          />
        );
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        onClose={() => setModal(false)}
        width="30%"
        className='!font-["Inter"] !p-0'
        footer={
          <div className="w-full flex flex-col gap-4 bg-white">
            {!isConnectionExists && (
              <Alert
                message={
                  <>
                    Please add the required connection to proceed.{" "}
                    <Link to="/connections" target="_blank" rel="noopener noreferrer">
                      <i>Click here</i>
                    </Link>
                  </>
                }
                type="warning"
                showIcon
              />
            )}
            <button
              className={` text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                loading || !changed || !isConnectionExists
                  ? "cursor-not-allowed bg-gray-500"
                  : "bg-primary cursor-pointer"
              }`}
              onClick={saveOutputStructure}
              disabled={loading || !changed || !isConnectionExists}
            >
              {loading ? "Saving..." : !changed ? "Saved" : "Save"}
            </button>
          </div>
        }
        title={
          <div className="flex items-center gap-2">
            <img src={action.logo} alt={action.name} className="w-6 h-6" />
            <span
              contentEditable={changeName}
              suppressContentEditableWarning
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.target.blur();
                }
              }}
              onBlur={async (e) => {
                if (!e.target.textContent) return;
                setLoading(true);
                updateActionName(action.id, e.target.textContent || action.name);
                setChangeName(false);
                await saveWorkflowActions(id || "");
                setChanged(false);
                setLoading(false);
                setPublishWarning(true);
              }}
            >
              {action.name}
            </span>
            <button
              onClick={() => {
                setChangeName(!changeName);
                const p = document.getElementById("actionName");
                if (!p) return;
                setTimeout(function () {
                  const range = document.createRange();
                  const selection = window.getSelection();
                  range.selectNodeContents(p);
                  range.collapse(false); // <-- Set the cursor at the end of the selection
                  selection?.removeAllRanges();
                  selection?.addRange(range);
                  p.focus();
                }, 0);
              }}
              className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                changeName ? "opacity-50" : "opacity-100"
              }`}
            >
              <FaRegEdit />
            </button>
          </div>
        }
      >
        <div className="bg-white gap-10 flex flex-col">
          {action.payloadStructure.map((field, idx) => {
            return (
              <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                <div>
                  <p className="tracking-primary font-semibold">
                    {field.name.toUpperCase()}
                    {!field.required && (
                      <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                    )}
                  </p>
                  <p className="text-sm font-light">{field.description}</p>
                </div>
                {field.needVars ? (
                  <TiptapField
                    content={
                      payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson || ""
                    }
                    setContent={(content: any, text: string) => {
                      const temp = [...payloads];
                      const index = payloads.findIndex(
                        (input) => input.payloadStructureId === field.payloadStructureId
                      );
                      if (index === -1) {
                        temp.push({
                          payloadStructureId: field.payloadStructureId,
                          tiptapJson: content,
                          inputString: text,
                          type: field.type,
                        });
                      } else {
                        temp[index].tiptapJson = content;
                        temp[index].inputString = text;
                      }
                      setPayloads(temp);
                    }}
                    refresh={updateField}
                    placeholder={field.name}
                    variables={variables}
                  />
                ) : (
                  fieldSwitchCase(field)
                )}
              </div>
            );
          })}
          <div>
            Continue workflow even if this fails:{" "}
            <Switch
              defaultChecked={action.continueOnFail}
              onChange={(value) => {
                setChanged(true);
                action.continueOnFail = value;
              }}
            />
          </div>
          {action.name.includes("Lookup") && (
            <>
              <RunCondition
                variables={variables}
                responses={action.responseStructure}
                conditionsArr={
                  runCondition || {
                    conditions: [],
                    otherwise: [],
                  }
                }
                setConditionsArr={setRunCondition}
              />
              <ResponseStructureViewer
                setResponses={setResponses}
                responses={responses}
                action={action}
                setChanged={setChanged}
              />
            </>
          )}
        </div>
      </Drawer>
    </ConfigProvider>
  );
};

export default CrmModal;
