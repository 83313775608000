import { useEffect, useState } from "react";
import { Card } from "./ui/Card";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/Popover";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "./ui/Select";
import { IFilterValue, IFiltersProps, IOperator, MultiSelectValues, conditionAttributes } from "./filter.schema";
import { SearchFilterFormArrayString, SearchFilterValuePreviewArrayString } from "./SearchFilter/ArrayString";
import { SearchFilterFormNumber, SearchFilterValuePreviewNumber } from "./SearchFilter/Number";
import { SearchFilterFormBoolean, SearchFilterValuePreviewBoolean } from "./SearchFilter/Boolean";
import { SearchFilterFormMultiSelect, SearchFilterValuePreviewMultiSelect } from "./SearchFilter/MultiSelect";
import { SearchFilterFormTechnologies, SearchFilterValuePreviewTechnologies } from "./SearchFilter/Technologies";
import { SearchFilterFormSelect, SearchFilterValuePreviewSelect } from "./SearchFilter/Select";
import { SearchFilterFormBetweenDates, SearchFilterValuePreviewBetweenDates } from "./SearchFilter/BetweenDates";
import { PopoverClose } from "@radix-ui/react-popover";
import { SearchFilterFormIndustry, SearchFilterValuePreviewIndustry } from "./SearchFilter/Industries";
import {
  SearchFilterFormTechnologyCategories,
  SearchFilterValuePreviewTechnologyCategories,
} from "./SearchFilter/TechnologyCategories";
import { SearchFilterFormSelectNumber, SearchFilterValuePreviewSelectNumber } from "./SearchFilter/SelectNumber";
import { cn } from "@/utils/cn";
import { LuChevronDown, LuX } from "react-icons/lu";
import { IconType } from "react-icons/lib";
import { SelectSubFilter, SelectSubFilterPreview } from "./SearchFilter/SelectSubFilter";
import {
  SearchFilterFormPipedriveFields,
  SearchFilterValuePreviewPipedriveFields,
} from "./SearchFilter/PipedriveFields";

interface FilterTitleProps {
  title: string;
  icon: IconType;
}

function FilterTitle({ title, icon: Icon }: FilterTitleProps) {
  return (
    <div className="flex space-x-1 items-center">
      <Icon className="h-4 w-4" />
      <p className="text-sm text-nowrap">{title}</p>
    </div>
  );
}

interface FilterProps {
  index: number;
  filter: IFiltersProps;
  changeFilter: (index: number, _value: IFiltersProps) => void;
  removeFilter: (index: number) => void;
  appliedFilters: IFiltersProps[];
  isRemovable: boolean;
  isPermanent: boolean;
  hasChanged: boolean;
}

export default function SearchFilterBase({
  index,
  filter,
  changeFilter,
  removeFilter,
  appliedFilters,
  isRemovable,
  isPermanent,
  hasChanged,
}: FilterProps) {
  const [isValuePopoverOpen, setIsValuePopoverOpen] = useState<boolean>(filter.value === undefined && !isPermanent);
  const [usedOperators, setUsedOperators] = useState<IOperator[]>(filter.availableOperators);
  const isBigPopOver = filter.data_type == "number" && filter.operator == IOperator.between_dates;
  const [interactOutside, setInteractOutside] = useState(false);

  const showChevronDown = isPermanent && filter.value == undefined;

  useEffect(() => {
    const appliedFiltersWithSameId = appliedFilters.filter((appliedFilter) => appliedFilter.id === filter.id);
    const operatorsUsed = appliedFiltersWithSameId
      .map((appliedFilter) => appliedFilter.operator)
      .filter((operator): operator is IOperator => operator !== undefined);
    setUsedOperators(operatorsUsed);
  }, [appliedFilters, filter]);

  const onChangeValue = (value: IFilterValue, preventClose?: boolean, isSubvalue?: boolean) => {
    if (isSubvalue) {
      const newFilter = { ...filter };
      newFilter.subValue = value as string[];
      changeFilter(index, newFilter);
    } else {
      const newFilter = { ...filter };
      newFilter.value = value;
      changeFilter(index, newFilter);
    }

    if (preventClose) {
      setIsValuePopoverOpen(true);
      setInteractOutside(true);
      return;
    }
    setIsValuePopoverOpen(false);
    setInteractOutside(false);
  };

  const closePopover = () => {
    setIsValuePopoverOpen(false);
    setInteractOutside(false);
  };

  const handleAvailableValues = (availableValues: any[]) => {
    // filter available values based on the country
    const countryFilter = appliedFilters.find((filter) => filter.id === "operator_country_id");
    if (countryFilter && countryFilter.value) {
      const country = countryFilter.value as any[];
      return availableValues.filter((state) => country.includes(state.flag));
    } else if (filter.id === "pipedrive_fields") {
      // filter available values based on the selected source
      const sourceFilter = appliedFilters.find((filter) => filter.id === "pipedrive_type");
      if (sourceFilter && sourceFilter.value) {
        const source = sourceFilter.value as string;
        return availableValues.filter((field) => field.source === source);
      }
    }
    return availableValues as MultiSelectValues;
  };

  const onChangeCondition = (condition: IOperator) => {
    if (condition !== filter.operator) {
      const newFilter = { ...filter };
      if (condition == IOperator.between_dates || filter.operator == IOperator.between_dates) {
        // reset value because the type of value is different (string or number)
        newFilter.value = undefined;
      }
      newFilter.operator = condition;
      changeFilter(index, newFilter);
    }
  };

  const handleInteractOutside = () => {
    setInteractOutside((prevState) => !prevState);
  };

  const handleXClick = () => {
    if (!isPermanent) {
      removeFilter(index);
    } else {
      changeFilter(index, { ...filter, value: undefined });
    }
  };

  return (
    <Card className="flex items-stretch relative">
      {hasChanged && <div className="rounded-full bg-orange-400 w-3 h-3 absolute -top-1 -right-1"></div>}
      <Popover open={isValuePopoverOpen} onOpenChange={setIsValuePopoverOpen}>
        <PopoverTrigger
          className={cn(
            "flex items-center px-1 gap-1 hover:bg-slate-100 py-2 flex-wrap",
            isRemovable ? "rounded-tl-md rounded-bl-md pl-2" : "rounded-md px-2"
          )}
        >
          <FilterTitle title={filter.title} icon={filter.icon} />
          {(!isPermanent || filter.value != undefined) && (
            <p className="text-sm text-gray-500 text-nowrap">
              {filter.operator && conditionAttributes[filter.operator].name}
            </p>
          )}
          {filter.value == undefined && !isPermanent && <p className="text-sm text-gray-500">Select value</p>}
          {filter.value != undefined && (
            <>
              {filter.data_type == "string[]" && (
                <SearchFilterValuePreviewArrayString value={filter.value as string[]} />
              )}
              {filter.data_type == "states[]" && (
                <SearchFilterValuePreviewMultiSelect
                  value={filter.value as string[]}
                  availableValues={handleAvailableValues(filter.availableValues as MultiSelectValues)}
                  // availableValues={av as MultiSelectValues}
                />
              )}
              {filter.data_type == "number" && filter.operator != IOperator.between_dates && (
                <SearchFilterValuePreviewNumber value={filter.value as number} />
              )}
              {filter.data_type == "number" && filter.operator == IOperator.between_dates && (
                <SearchFilterValuePreviewBetweenDates value={filter.value as string} />
              )}
              {filter.data_type == "boolean" && <SearchFilterValuePreviewBoolean value={filter.value as boolean} />}
              {filter.data_type == "options" && (
                <SearchFilterValuePreviewSelect
                  value={filter.value as string}
                  availableValues={filter.availableValues as MultiSelectValues}
                />
              )}
              {filter.data_type == "options-subfilter" && (
                <SelectSubFilterPreview
                  data={filter}
                  availableValues={filter.availableValues as MultiSelectValues}
                  primary="subValue"
                />
              )}
              {filter.data_type == "options[]" && (
                <SearchFilterValuePreviewMultiSelect
                  value={filter.value as string[]}
                  availableValues={filter.availableValues as MultiSelectValues}
                />
              )}
              {filter.data_type == "technologies[]" && (
                <SearchFilterValuePreviewTechnologies
                  value={filter.value as string[]}
                  itemsType={filter.itemsType || "technologies"}
                />
              )}
              {filter.data_type == "industry[]" && (
                <SearchFilterValuePreviewIndustry value={filter.value as number[]} />
              )}
              {filter.data_type == "technology-category[]" && (
                <SearchFilterValuePreviewTechnologyCategories value={filter.value as string[]} />
              )}
              {filter.data_type == "number[]" && (
                <SearchFilterValuePreviewSelectNumber
                  value={filter.value as number}
                  availableValues={filter.availableValues as MultiSelectValues}
                />
              )}
              {filter.data_type == "pipedrive_fields[]" && (
                <SearchFilterValuePreviewPipedriveFields
                  value={filter.value as any[]}
                  type={(function () {
                    const sourceFilter = appliedFilters.find((filter) => filter.id === "pipedrive_type");
                    if (sourceFilter && sourceFilter.value) return sourceFilter.value as string;
                    return "";
                  })()}
                />
              )}
            </>
          )}
          {showChevronDown && (
            <div className="flex items-center justify-center">
              <LuChevronDown className="h-4 w-4" strokeWidth={1.5} />
            </div>
          )}
        </PopoverTrigger>
        <PopoverContent
          className={`space-y-4 ${isBigPopOver ? "w-[580px]" : "w-72 md:w-96"}`}
          sideOffset={4}
          align="start"
          onInteractOutside={handleInteractOutside}
        >
          <div className="flex flex-row justify-between items-start">
            <div className="flex flex-row items-center gap-2 flex-wrap">
              <FilterTitle title={filter.title} icon={filter.icon} />
              {filter.availableOperators.length > 1 && (
                <Select defaultValue={filter.operator} onValueChange={onChangeCondition}>
                  <SelectTrigger className="p-1 h-6 text-sm gap-1 w-auto" tabIndex={-1}>
                    <SelectValue placeholder="Select an option" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {filter.availableOperators.map((operator) => (
                        <SelectItem key={operator} value={operator} disabled={usedOperators.includes(operator)}>
                          {conditionAttributes[operator].name}{" "}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              )}
              {filter.availableOperators.length == 1 && (
                <p className="text-sm text-gray-500 text-nowrap">
                  {filter.operator && conditionAttributes[filter.operator].name}
                </p>
              )}
            </div>
            <div className="h-6 flex items-center justify-center">
              <PopoverClose className="ml-auto hover:text-black">
                <LuX />
              </PopoverClose>
            </div>
          </div>
          {filter.data_type == "string[]" && (
            <SearchFilterFormArrayString
              id={filter.id}
              value={filter.value as string[]}
              onChangeValue={onChangeValue}
              onInteractOutside={interactOutside}
              closePopover={closePopover}
            />
          )}
          {filter.data_type == "number" && filter.operator != IOperator.between_dates && (
            <SearchFilterFormNumber
              id={filter.id}
              value={filter.value as number}
              onChangeValue={onChangeValue}
              onInteractOutside={interactOutside}
              closePopover={closePopover}
            />
          )}
          {filter.data_type == "states[]" && (
            <SearchFilterFormMultiSelect
              value={filter.value as string[]}
              onChangeValue={onChangeValue}
              availableValues={handleAvailableValues(filter.availableValues as MultiSelectValues)}
              onInteractOutside={interactOutside}
              closePopover={closePopover}
            />
          )}
          {filter.data_type == "number" && filter.operator == IOperator.between_dates && (
            <SearchFilterFormBetweenDates
              id={filter.id}
              value={filter.value as string}
              onChangeValue={onChangeValue}
              onInteractOutside={interactOutside}
              closePopover={closePopover}
            />
          )}
          {filter.data_type == "boolean" && (
            <SearchFilterFormBoolean id={filter.id} value={filter.value as boolean} onChangeValue={onChangeValue} />
          )}
          {filter.data_type == "options" && (
            <SearchFilterFormSelect
              value={filter.value as string}
              onChangeValue={onChangeValue}
              availableValues={filter.availableValues as MultiSelectValues}
            />
          )}
          {filter.data_type == "options-subfilter" && (
            <SelectSubFilter
              value={filter.value as string}
              typedValue={filter.subValue || []}
              onChangeValue={onChangeValue}
              availableValues={filter.availableValues as MultiSelectValues}
              closePopover={closePopover}
            />
          )}
          {filter.data_type == "options[]" && (
            <SearchFilterFormMultiSelect
              value={filter.value as string[]}
              onChangeValue={onChangeValue}
              availableValues={filter.availableValues as MultiSelectValues}
              onInteractOutside={interactOutside}
              closePopover={closePopover}
            />
          )}
          {filter.data_type == "technologies[]" && (
            <SearchFilterFormTechnologies
              id={filter.id}
              value={filter.value as string[]}
              onChangeValue={onChangeValue}
              onInteractOutside={interactOutside}
              closePopover={closePopover}
              itemsType={filter?.itemsType || "technologies"}
            />
          )}
          {filter.data_type == "industry[]" && (
            <SearchFilterFormIndustry
              value={filter.value as number[]}
              onChangeValue={onChangeValue}
              onInteractOutside={interactOutside}
              closePopover={closePopover}
            />
          )}
          {filter.data_type == "technology-category[]" && (
            <SearchFilterFormTechnologyCategories
              value={filter.value as string[]}
              onChangeValue={onChangeValue}
              onInteractOutside={interactOutside}
              closePopover={closePopover}
            />
          )}
          {filter.data_type == "pipedrive_fields[]" && (
            <SearchFilterFormPipedriveFields
              value={filter.value as any[]}
              onChangeValue={onChangeValue}
              entityType={(function () {
                const sourceFilter = appliedFilters.find((filter) => filter.id === "pipedrive_type");
                if (sourceFilter && sourceFilter.value) return sourceFilter.value as string;
                return "";
              })()}
              onInteractOutside={interactOutside}
              closePopover={closePopover}
            />
          )}
          {filter.data_type == "number[]" && (
            <SearchFilterFormSelectNumber
              value={filter.value as string}
              onChangeValue={onChangeValue}
              availableValues={filter.availableValues as MultiSelectValues}
            />
          )}
        </PopoverContent>
      </Popover>
      {isRemovable && !showChevronDown && (
        <div
          onClick={handleXClick}
          className="flex items-center justify-center hover:bg-slate-100 cursor-pointer px-1 pr-1  rounded-tr-md rounded-br-md text-slate-500 hover:text-red-500"
        >
          <LuX className="h-4 w-4" strokeWidth={1.5} />
        </div>
      )}
    </Card>
  );
}
