export const countriesDropdown = [
  {
    id: "AF",
    name: "Afghanistan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1eb.svg",
  },
  {
    id: "AX",
    name: "\u00c5land Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1fd.svg",
  },
  {
    id: "AL",
    name: "Albania",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f1.svg",
  },
  {
    id: "DZ",
    name: "Algeria",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1ff.svg",
  },
  {
    id: "AS",
    name: "American Samoa",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f8.svg",
  },
  {
    id: "AD",
    name: "Andorra",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1e9.svg",
  },
  {
    id: "AO",
    name: "Angola",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f4.svg",
  },
  {
    id: "AI",
    name: "Anguilla",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ee.svg",
  },
  {
    id: "AQ",
    name: "Antarctica",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f6.svg",
  },
  {
    id: "AG",
    name: "Antigua and Barbuda",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ec.svg",
  },
  {
    id: "AR",
    name: "Argentina",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f7.svg",
  },
  {
    id: "AM",
    name: "Armenia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f2.svg",
  },
  {
    id: "AW",
    name: "Aruba",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1fc.svg",
  },
  {
    id: "AU",
    name: "Australia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1fa.svg",
  },
  {
    id: "AT",
    name: "Austria",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f9.svg",
  },
  {
    id: "AZ",
    name: "Azerbaijan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ff.svg",
  },
  {
    id: "BS",
    name: "Bahamas",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f8.svg",
  },
  {
    id: "BH",
    name: "Bahrain",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ed.svg",
  },
  {
    id: "BD",
    name: "Bangladesh",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1e9.svg",
  },
  {
    id: "BB",
    name: "Barbados",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1e7.svg",
  },
  {
    id: "BY",
    name: "Belarus",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1fe.svg",
  },
  {
    id: "BE",
    name: "Belgium",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ea.svg",
  },
  {
    id: "BZ",
    name: "Belize",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ff.svg",
  },
  {
    id: "BJ",
    name: "Benin",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ef.svg",
  },
  {
    id: "BM",
    name: "Bermuda",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f2.svg",
  },
  {
    id: "BT",
    name: "Bhutan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f9.svg",
  },
  {
    id: "BO",
    name: "Bolivia, Plurinational State of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f4.svg",
  },
  {
    id: "BQ",
    name: "Bonaire, Sint Eustatius and Saba",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f6.svg",
  },
  {
    id: "BA",
    name: "Bosnia and Herzegovina",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1e6.svg",
  },
  {
    id: "BW",
    name: "Botswana",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1fc.svg",
  },
  {
    id: "BV",
    name: "Bouvet Island",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1fb.svg",
  },
  {
    id: "BR",
    name: "Brazil",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f7.svg",
  },
  {
    id: "IO",
    name: "British Indian Ocean Territory",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f4.svg",
  },
  {
    id: "BN",
    name: "Brunei Darussalam",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f3.svg",
  },
  {
    id: "BG",
    name: "Bulgaria",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ec.svg",
  },
  {
    id: "BF",
    name: "Burkina Faso",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1eb.svg",
  },
  {
    id: "BI",
    name: "Burundi",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ee.svg",
  },
  {
    id: "KH",
    name: "Cambodia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ed.svg",
  },
  {
    id: "CM",
    name: "Cameroon",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f2.svg",
  },
  {
    id: "CA",
    name: "Canada",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1e6.svg",
  },
  {
    id: "CV",
    name: "Cape Verde",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fb.svg",
  },
  {
    id: "KY",
    name: "Cayman Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1fe.svg",
  },
  {
    id: "CF",
    name: "Central African Republic",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1eb.svg",
  },
  {
    id: "TD",
    name: "Chad",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1e9.svg",
  },
  {
    id: "CL",
    name: "Chile",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f1.svg",
  },
  {
    id: "CN",
    name: "China",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f3.svg",
  },
  {
    id: "CX",
    name: "Christmas Island",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fd.svg",
  },
  {
    id: "CC",
    name: "Cocos (Keeling) Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1e8.svg",
  },
  {
    id: "CO",
    name: "Colombia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f4.svg",
  },
  {
    id: "KM",
    name: "Comoros",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f2.svg",
  },
  {
    id: "CG",
    name: "Congo",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ec.svg",
  },
  {
    id: "CD",
    name: "Congo, the Democratic Republic of the",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1e9.svg",
  },
  {
    id: "CK",
    name: "Cook Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f0.svg",
  },
  {
    id: "CR",
    name: "Costa Rica",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f7.svg",
  },
  {
    id: "CI",
    name: "C\u00f4te d'Ivoire",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ee.svg",
  },
  {
    id: "HR",
    name: "Croatia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f7.svg",
  },
  {
    id: "CU",
    name: "Cuba",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fa.svg",
  },
  {
    id: "CW",
    name: "Cura\u00e7ao",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fc.svg",
  },
  {
    id: "CY",
    name: "Cyprus",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fe.svg",
  },
  {
    id: "CZ",
    name: "Czech Republic",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ff.svg",
  },
  {
    id: "DK",
    name: "Denmark",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1f0.svg",
  },
  {
    id: "DJ",
    name: "Djibouti",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1ef.svg",
  },
  {
    id: "DM",
    name: "Dominica",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1f2.svg",
  },
  {
    id: "DO",
    name: "Dominican Republic",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1f4.svg",
  },
  {
    id: "EC",
    name: "Ecuador",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1e8.svg",
  },
  {
    id: "EG",
    name: "Egypt",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1ec.svg",
  },
  {
    id: "SV",
    name: "El Salvador",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1fb.svg",
  },
  {
    id: "GQ",
    name: "Equatorial Guinea",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f6.svg",
  },
  {
    id: "ER",
    name: "Eritrea",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1f7.svg",
  },
  {
    id: "EE",
    name: "Estonia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1ea.svg",
  },
  {
    id: "ET",
    name: "Ethiopia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1f9.svg",
  },
  {
    id: "FK",
    name: "Falkland Islands (Malvinas)",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f0.svg",
  },
  {
    id: "FO",
    name: "Faroe Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f4.svg",
  },
  {
    id: "FJ",
    name: "Fiji",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1ef.svg",
  },
  {
    id: "FI",
    name: "Finland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1ee.svg",
  },
  {
    id: "FR",
    name: "France",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f7.svg",
  },
  {
    id: "GF",
    name: "French Guiana",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1eb.svg",
  },
  {
    id: "PF",
    name: "French Polynesia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1eb.svg",
  },
  {
    id: "TF",
    name: "French Southern Territories",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1eb.svg",
  },
  {
    id: "GA",
    name: "Gabon",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1e6.svg",
  },
  {
    id: "GM",
    name: "Gambia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f2.svg",
  },
  {
    id: "GE",
    name: "Georgia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ea.svg",
  },
  {
    id: "DE",
    name: "Germany",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1ea.svg",
  },
  {
    id: "GH",
    name: "Ghana",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ed.svg",
  },
  {
    id: "GI",
    name: "Gibraltar",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ee.svg",
  },
  {
    id: "GR",
    name: "Greece",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f7.svg",
  },
  {
    id: "GL",
    name: "Greenland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f1.svg",
  },
  {
    id: "GD",
    name: "Grenada",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1e9.svg",
  },
  {
    id: "GP",
    name: "Guadeloupe",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f5.svg",
  },
  {
    id: "GU",
    name: "Guam",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1fa.svg",
  },
  {
    id: "GT",
    name: "Guatemala",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f9.svg",
  },
  {
    id: "GG",
    name: "Guernsey",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ec.svg",
  },
  {
    id: "GN",
    name: "Guinea",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f3.svg",
  },
  {
    id: "GW",
    name: "Guinea-Bissau",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1fc.svg",
  },
  {
    id: "GY",
    name: "Guyana",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1fe.svg",
  },
  {
    id: "HT",
    name: "Haiti",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f9.svg",
  },
  {
    id: "HM",
    name: "Heard Island and McDonald Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f2.svg",
  },
  {
    id: "VA",
    name: "Holy See (Vatican City State)",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1e6.svg",
  },
  {
    id: "HN",
    name: "Honduras",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f3.svg",
  },
  {
    id: "HK",
    name: "Hong Kong",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f0.svg",
  },
  {
    id: "HU",
    name: "Hungary",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1fa.svg",
  },
  {
    id: "IS",
    name: "Iceland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f8.svg",
  },
  {
    id: "IN",
    name: "India",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f3.svg",
  },
  {
    id: "ID",
    name: "Indonesia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1e9.svg",
  },
  {
    id: "IR",
    name: "Iran, Islamic Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f7.svg",
  },
  {
    id: "IQ",
    name: "Iraq",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f6.svg",
  },
  {
    id: "IE",
    name: "Ireland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1ea.svg",
  },
  {
    id: "IM",
    name: "Isle of Man",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f2.svg",
  },
  {
    id: "IL",
    name: "Israel",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f1.svg",
  },
  {
    id: "IT",
    name: "Italy",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f9.svg",
  },
  {
    id: "JM",
    name: "Jamaica",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1f2.svg",
  },
  {
    id: "JP",
    name: "Japan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1f5.svg",
  },
  {
    id: "JE",
    name: "Jersey",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1ea.svg",
  },
  {
    id: "JO",
    name: "Jordan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1f4.svg",
  },
  {
    id: "KZ",
    name: "Kazakhstan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ff.svg",
  },
  {
    id: "KE",
    name: "Kenya",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ea.svg",
  },
  {
    id: "KI",
    name: "Kiribati",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ee.svg",
  },
  {
    id: "KP",
    name: "Korea, Democratic People's Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f5.svg",
  },
  {
    id: "KR",
    name: "Korea, Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f7.svg",
  },
  {
    id: "KW",
    name: "Kuwait",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1fc.svg",
  },
  {
    id: "KG",
    name: "Kyrgyzstan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ec.svg",
  },
  {
    id: "LA",
    name: "Lao People's Democratic Republic",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1e6.svg",
  },
  {
    id: "LV",
    name: "Latvia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1fb.svg",
  },
  {
    id: "LB",
    name: "Lebanon",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1e7.svg",
  },
  {
    id: "LS",
    name: "Lesotho",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f8.svg",
  },
  {
    id: "LR",
    name: "Liberia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f7.svg",
  },
  {
    id: "LY",
    name: "Libya",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1fe.svg",
  },
  {
    id: "LI",
    name: "Liechtenstein",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1ee.svg",
  },
  {
    id: "LT",
    name: "Lithuania",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f9.svg",
  },
  {
    id: "LU",
    name: "Luxembourg",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1fa.svg",
  },
  {
    id: "MO",
    name: "Macao",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f4.svg",
  },
  {
    id: "MK",
    name: "Macedonia, the Former Yugoslav Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f0.svg",
  },
  {
    id: "MG",
    name: "Madagascar",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ec.svg",
  },
  {
    id: "MW",
    name: "Malawi",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fc.svg",
  },
  {
    id: "MY",
    name: "Malaysia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fe.svg",
  },
  {
    id: "MV",
    name: "Maldives",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fb.svg",
  },
  {
    id: "ML",
    name: "Mali",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f1.svg",
  },
  {
    id: "MT",
    name: "Malta",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f9.svg",
  },
  {
    id: "MH",
    name: "Marshall Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ed.svg",
  },
  {
    id: "MQ",
    name: "Martinique",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f6.svg",
  },
  {
    id: "MR",
    name: "Mauritania",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f7.svg",
  },
  {
    id: "MU",
    name: "Mauritius",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fa.svg",
  },
  {
    id: "YT",
    name: "Mayotte",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fe-1f1f9.svg",
  },
  {
    id: "MX",
    name: "Mexico",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fd.svg",
  },
  {
    id: "FM",
    name: "Micronesia, Federated States of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f2.svg",
  },
  {
    id: "MD",
    name: "Moldova, Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1e9.svg",
  },
  {
    id: "MC",
    name: "Monaco",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1e8.svg",
  },
  {
    id: "MN",
    name: "Mongolia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f3.svg",
  },
  {
    id: "ME",
    name: "Montenegro",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ea.svg",
  },
  {
    id: "MS",
    name: "Montserrat",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f8.svg",
  },
  {
    id: "MA",
    name: "Morocco",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1e6.svg",
  },
  {
    id: "MZ",
    name: "Mozambique",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ff.svg",
  },
  {
    id: "MM",
    name: "Myanmar",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f2.svg",
  },
  {
    id: "NA",
    name: "Namibia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1e6.svg",
  },
  {
    id: "NR",
    name: "Nauru",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f7.svg",
  },
  {
    id: "NP",
    name: "Nepal",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f5.svg",
  },
  {
    id: "NL",
    name: "Netherlands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f1.svg",
  },
  {
    id: "NC",
    name: "New Caledonia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1e8.svg",
  },
  {
    id: "NZ",
    name: "New Zealand",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ff.svg",
  },
  {
    id: "NI",
    name: "Nicaragua",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ee.svg",
  },
  {
    id: "NE",
    name: "Niger",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ea.svg",
  },
  {
    id: "NG",
    name: "Nigeria",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ec.svg",
  },
  {
    id: "NU",
    name: "Niue",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1fa.svg",
  },
  {
    id: "NF",
    name: "Norfolk Island",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1eb.svg",
  },
  {
    id: "MP",
    name: "Northern Mariana Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f5.svg",
  },
  {
    id: "NO",
    name: "Norway",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f4.svg",
  },
  {
    id: "OM",
    name: "Oman",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f4-1f1f2.svg",
  },
  {
    id: "PK",
    name: "Pakistan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f0.svg",
  },
  {
    id: "PW",
    name: "Palau",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1fc.svg",
  },
  {
    id: "PS",
    name: "Palestine, State of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f8.svg",
  },
  {
    id: "PA",
    name: "Panama",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1e6.svg",
  },
  {
    id: "PG",
    name: "Papua New Guinea",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1ec.svg",
  },
  {
    id: "PY",
    name: "Paraguay",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1fe.svg",
  },
  {
    id: "PE",
    name: "Peru",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1ea.svg",
  },
  {
    id: "PH",
    name: "Philippines",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1ed.svg",
  },
  {
    id: "PN",
    name: "Pitcairn",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f3.svg",
  },
  {
    id: "PL",
    name: "Poland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f1.svg",
  },
  {
    id: "PT",
    name: "Portugal",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f9.svg",
  },
  {
    id: "PR",
    name: "Puerto Rico",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f7.svg",
  },
  {
    id: "QA",
    name: "Qatar",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f6-1f1e6.svg",
  },
  {
    id: "RE",
    name: "R\u00e9union",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1ea.svg",
  },
  {
    id: "RO",
    name: "Romania",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1f4.svg",
  },
  {
    id: "RU",
    name: "Russian Federation",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1fa.svg",
  },
  {
    id: "RW",
    name: "Rwanda",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1fc.svg",
  },
  {
    id: "BL",
    name: "Saint Barth\u00e9lemy",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f1.svg",
  },
  {
    id: "SH",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ed.svg",
  },
  {
    id: "KN",
    name: "Saint Kitts and Nevis",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f3.svg",
  },
  {
    id: "LC",
    name: "Saint Lucia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1e8.svg",
  },
  {
    id: "MF",
    name: "Saint Martin (French part)",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1eb.svg",
  },
  {
    id: "PM",
    name: "Saint Pierre and Miquelon",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f2.svg",
  },
  {
    id: "VC",
    name: "Saint Vincent and the Grenadines",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1e8.svg",
  },
  {
    id: "WS",
    name: "Samoa",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fc-1f1f8.svg",
  },
  {
    id: "SM",
    name: "San Marino",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f2.svg",
  },
  {
    id: "ST",
    name: "Sao Tome and Principe",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f9.svg",
  },
  {
    id: "SA",
    name: "Saudi Arabia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e6.svg",
  },
  {
    id: "SN",
    name: "Senegal",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f3.svg",
  },
  {
    id: "RS",
    name: "Serbia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1f8.svg",
  },
  {
    id: "SC",
    name: "Seychelles",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e8.svg",
  },
  {
    id: "SL",
    name: "Sierra Leone",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f1.svg",
  },
  {
    id: "SG",
    name: "Singapore",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ec.svg",
  },
  {
    id: "SX",
    name: "Sint Maarten (Dutch part)",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1fd.svg",
  },
  {
    id: "SK",
    name: "Slovakia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f0.svg",
  },
  {
    id: "SI",
    name: "Slovenia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ee.svg",
  },
  {
    id: "SB",
    name: "Solomon Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e7.svg",
  },
  {
    id: "SO",
    name: "Somalia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f4.svg",
  },
  {
    id: "ZA",
    name: "South Africa",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ff-1f1e6.svg",
  },
  {
    id: "GS",
    name: "South Georgia and the South Sandwich Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f8.svg",
  },
  {
    id: "SS",
    name: "South Sudan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f8.svg",
  },
  {
    id: "ES",
    name: "Spain",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1f8.svg",
  },
  {
    id: "LK",
    name: "Sri Lanka",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f0.svg",
  },
  {
    id: "SD",
    name: "Sudan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e9.svg",
  },
  {
    id: "SR",
    name: "Suriname",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f7.svg",
  },
  {
    id: "SJ",
    name: "Svalbard and Jan Mayen",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ef.svg",
  },
  {
    id: "SZ",
    name: "Swaziland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ff.svg",
  },
  {
    id: "SE",
    name: "Sweden",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ea.svg",
  },
  {
    id: "CH",
    name: "Switzerland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ed.svg",
  },
  {
    id: "SY",
    name: "Syrian Arab Republic",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1fe.svg",
  },
  {
    id: "TW",
    name: "Taiwan, Province of China",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1fc.svg",
  },
  {
    id: "TJ",
    name: "Tajikistan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ef.svg",
  },
  {
    id: "TZ",
    name: "Tanzania, United Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ff.svg",
  },
  {
    id: "TH",
    name: "Thailand",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ed.svg",
  },
  {
    id: "TL",
    name: "Timor-Leste",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f1.svg",
  },
  {
    id: "TG",
    name: "Togo",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ec.svg",
  },
  {
    id: "TK",
    name: "Tokelau",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f0.svg",
  },
  {
    id: "TO",
    name: "Tonga",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f4.svg",
  },
  {
    id: "TT",
    name: "Trinidad and Tobago",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f9.svg",
  },
  {
    id: "TN",
    name: "Tunisia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f3.svg",
  },
  {
    id: "TR",
    name: "Turkey",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f7.svg",
  },
  {
    id: "TM",
    name: "Turkmenistan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f2.svg",
  },
  {
    id: "TC",
    name: "Turks and Caicos Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1e8.svg",
  },
  {
    id: "TV",
    name: "Tuvalu",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1fb.svg",
  },
  {
    id: "UG",
    name: "Uganda",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1ec.svg",
  },
  {
    id: "UA",
    name: "Ukraine",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1e6.svg",
  },
  {
    id: "AE",
    name: "United Arab Emirates",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ea.svg",
  },
  {
    id: "GB",
    name: "United Kingdom",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1e7.svg",
  },
  {
    id: "US",
    name: "United States",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1f8.svg",
  },
  {
    id: "UM",
    name: "United States Minor Outlying Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1f2.svg",
  },
  {
    id: "UY",
    name: "Uruguay",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1fe.svg",
  },
  {
    id: "UZ",
    name: "Uzbekistan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1ff.svg",
  },
  {
    id: "VU",
    name: "Vanuatu",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1fa.svg",
  },
  {
    id: "VE",
    name: "Venezuela, Bolivarian Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1ea.svg",
  },
  {
    id: "VN",
    name: "Viet Nam",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1f3.svg",
  },
  {
    id: "VG",
    name: "Virgin Islands, British",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1ec.svg",
  },
  {
    id: "VI",
    name: "Virgin Islands, U.S.",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1ee.svg",
  },
  {
    id: "WF",
    name: "Wallis and Futuna",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fc-1f1eb.svg",
  },
  {
    id: "EH",
    name: "Western Sahara",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1ed.svg",
  },
  {
    id: "YE",
    name: "Yemen",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fe-1f1ea.svg",
  },
  {
    id: "ZM",
    name: "Zambia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ff-1f1f2.svg",
  },
  {
    id: "ZW",
    name: "Zimbabwe",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ff-1f1fc.svg",
  },
];

export const operatorsCountriesDropdown = [
  // US and canada
  {
    id: "US",
    name: "United States",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1f8.svg",
  },
  {
    id: "CA",
    name: "Canada",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1e6.svg",
  },
];

type StatesDropdown = { id: string; name: string; flag: string }[];
// states based on country
export const operatorStatesDropdown: StatesDropdown = [
  {
    id: "103",
    name: "Alberta",
    flag: "CA",
  },
  {
    id: "104",
    name: "Saskatchewan",
    flag: "CA",
  },
  {
    id: "102",
    name: "British Columbia",
    flag: "CA",
  },
  {
    id: "101",
    name: "Ontario",
    flag: "CA",
  },
  {
    id: "100",
    name: "Manitoba",
    flag: "CA",
  },
  {
    id: "105",
    name: "Yukon",
    flag: "CA",
  },
  {
    id: "42",
    name: "Texas",
    flag: "US",
  },
  {
    id: "12",
    name: "Illinois",
    flag: "US",
  },
  {
    id: "35",
    name: "Oklahoma",
    flag: "US",
  },
  {
    id: "15",
    name: "Kansas",
    flag: "US",
  },
  {
    id: "37",
    name: "Pennsylvania",
    flag: "US",
  },
  {
    id: "34",
    name: "Ohio",
    flag: "US",
  },
  {
    id: "17",
    name: "Louisiana",
    flag: "US",
  },
  {
    id: "4",
    name: "California",
    flag: "US",
  },
  {
    id: "49",
    name: "Wyoming",
    flag: "US",
  },
  {
    id: "16",
    name: "Kentucky",
    flag: "US",
  },
  {
    id: "30",
    name: "New Mexico",
    flag: "US",
  },
  {
    id: "5",
    name: "Colorado",
    flag: "US",
  },
  {
    id: "47",
    name: "West Virginia",
    flag: "US",
  },
  {
    id: "21",
    name: "Michigan",
    flag: "US",
  },
  {
    id: "13",
    name: "Indiana",
    flag: "US",
  },
  {
    id: "31",
    name: "New York",
    flag: "US",
  },
  {
    id: "3",
    name: "Arkansas",
    flag: "US",
  },
  {
    id: "25",
    name: "Montana",
    flag: "US",
  },
  {
    id: "33",
    name: "North Dakota",
    flag: "US",
  },
  {
    id: "43",
    name: "Utah",
    flag: "US",
  },
  {
    id: "23",
    name: "Mississippi",
    flag: "US",
  },
  {
    id: "60",
    name: "Gulf of Mexico",
    flag: "US",
  },
  {
    id: "26",
    name: "Nebraska",
    flag: "US",
  },
  {
    id: "1",
    name: "Alabama",
    flag: "US",
  },
  {
    id: "41",
    name: "Tennessee",
    flag: "US",
  },
  {
    id: "45",
    name: "Virginia",
    flag: "US",
  },
  {
    id: "50",
    name: "Alaska",
    flag: "US",
  },
  {
    id: "24",
    name: "Missouri",
    flag: "US",
  },
  {
    id: "2",
    name: "Arizona",
    flag: "US",
  },
  {
    id: "40",
    name: "South Dakota",
    flag: "US",
  },
  {
    id: "9",
    name: "Florida",
    flag: "US",
  },
  {
    id: "27",
    name: "Nevada",
    flag: "US",
  },
  {
    id: "46",
    name: "Washington",
    flag: "US",
  },
  {
    id: "36",
    name: "Oregon",
    flag: "US",
  },
  {
    id: "11",
    name: "Idaho",
    flag: "US",
  },
  {
    id: "56",
    name: "Pacific Offshore",
    flag: "US",
  },
  {
    id: "61",
    name: "Atlantic Offshore",
    flag: "US",
  },
];

export const crustDataCountries = [
  {
    id: "Afghanistan",
    name: "Afghanistan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1eb.svg",
  },
  {
    id: "Åland Islands",
    name: "Åland Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1fd.svg",
  },
  {
    id: "Albania",
    name: "Albania",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f1.svg",
  },
  {
    id: "Algeria",
    name: "Algeria",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1ff.svg",
  },
  {
    id: "American Samoa",
    name: "American Samoa",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f8.svg",
  },
  {
    id: "Andorra",
    name: "Andorra",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1e9.svg",
  },
  {
    id: "Angola",
    name: "Angola",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f4.svg",
  },
  {
    id: "Anguilla",
    name: "Anguilla",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ee.svg",
  },
  {
    id: "Antarctica",
    name: "Antarctica",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f6.svg",
  },
  {
    id: "Antigua and Barbuda",
    name: "Antigua and Barbuda",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ec.svg",
  },
  {
    id: "Argentina",
    name: "Argentina",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f7.svg",
  },
  {
    id: "Armenia",
    name: "Armenia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f2.svg",
  },
  {
    id: "Aruba",
    name: "Aruba",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1fc.svg",
  },
  {
    id: "Australia",
    name: "Australia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1fa.svg",
  },
  {
    id: "Austria",
    name: "Austria",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f9.svg",
  },
  {
    id: "Azerbaijan",
    name: "Azerbaijan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ff.svg",
  },
  {
    id: "Bahamas",
    name: "Bahamas",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f8.svg",
  },
  {
    id: "Bahrain",
    name: "Bahrain",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ed.svg",
  },
  {
    id: "Bangladesh",
    name: "Bangladesh",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1e9.svg",
  },
  {
    id: "Barbados",
    name: "Barbados",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1e7.svg",
  },
  {
    id: "Belarus",
    name: "Belarus",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1fe.svg",
  },
  {
    id: "Belgium",
    name: "Belgium",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ea.svg",
  },
  {
    id: "Belize",
    name: "Belize",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ff.svg",
  },
  {
    id: "Benin",
    name: "Benin",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ef.svg",
  },
  {
    id: "Bermuda",
    name: "Bermuda",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f2.svg",
  },
  {
    id: "Bhutan",
    name: "Bhutan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f9.svg",
  },
  {
    id: "Bolivia, Plurinational State of",
    name: "Bolivia, Plurinational State of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f4.svg",
  },
  {
    id: "Bonaire, Sint Eustatius and Saba",
    name: "Bonaire, Sint Eustatius and Saba",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f6.svg",
  },
  {
    id: "Bosnia and Herzegovina",
    name: "Bosnia and Herzegovina",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1e6.svg",
  },
  {
    id: "Botswana",
    name: "Botswana",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1fc.svg",
  },
  {
    id: "Bouvet Island",
    name: "Bouvet Island",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1fb.svg",
  },
  {
    id: "Brazil",
    name: "Brazil",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f7.svg",
  },
  {
    id: "British Indian Ocean Territory",
    name: "British Indian Ocean Territory",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f4.svg",
  },
  {
    id: "Brunei Darussalam",
    name: "Brunei Darussalam",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f3.svg",
  },
  {
    id: "Bulgaria",
    name: "Bulgaria",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ec.svg",
  },
  {
    id: "Burkina Faso",
    name: "Burkina Faso",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1eb.svg",
  },
  {
    id: "Burundi",
    name: "Burundi",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ee.svg",
  },
  {
    id: "Cambodia",
    name: "Cambodia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ed.svg",
  },
  {
    id: "Cameroon",
    name: "Cameroon",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f2.svg",
  },
  {
    id: "Canada",
    name: "Canada",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1e6.svg",
  },
  {
    id: "Cape Verde",
    name: "Cape Verde",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fb.svg",
  },
  {
    id: "Cayman Islands",
    name: "Cayman Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1fe.svg",
  },
  {
    id: "Central African Republic",
    name: "Central African Republic",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1eb.svg",
  },
  {
    id: "Chad",
    name: "Chad",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1e9.svg",
  },
  {
    id: "Chile",
    name: "Chile",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f1.svg",
  },
  {
    id: "China",
    name: "China",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f3.svg",
  },
  {
    id: "Christmas Island",
    name: "Christmas Island",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fd.svg",
  },
  {
    id: "Cocos (Keeling) Islands",
    name: "Cocos (Keeling) Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1e8.svg",
  },
  {
    id: "Colombia",
    name: "Colombia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f4.svg",
  },
  {
    id: "Comoros",
    name: "Comoros",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f2.svg",
  },
  {
    id: "Congo",
    name: "Congo",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ec.svg",
  },
  {
    id: "Congo, the Democratic Republic of the",
    name: "Congo, the Democratic Republic of the",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1e9.svg",
  },
  {
    id: "Cook Islands",
    name: "Cook Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f0.svg",
  },
  {
    id: "Costa Rica",
    name: "Costa Rica",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f7.svg",
  },
  {
    id: "Côte d'Ivoire",
    name: "Côte d'Ivoire",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ee.svg",
  },
  {
    id: "Croatia",
    name: "Croatia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f7.svg",
  },
  {
    id: "Cuba",
    name: "Cuba",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fa.svg",
  },
  {
    id: "Curaçao",
    name: "Curaçao",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fc.svg",
  },
  {
    id: "Cyprus",
    name: "Cyprus",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fe.svg",
  },
  {
    id: "Czech Republic",
    name: "Czech Republic",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ff.svg",
  },
  {
    id: "Denmark",
    name: "Denmark",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1f0.svg",
  },
  {
    id: "Djibouti",
    name: "Djibouti",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1ef.svg",
  },
  {
    id: "Dominica",
    name: "Dominica",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1f2.svg",
  },
  {
    id: "Dominican Republic",
    name: "Dominican Republic",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1f4.svg",
  },
  {
    id: "Ecuador",
    name: "Ecuador",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1e8.svg",
  },
  {
    id: "Egypt",
    name: "Egypt",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1ec.svg",
  },
  {
    id: "El Salvador",
    name: "El Salvador",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1fb.svg",
  },
  {
    id: "Equatorial Guinea",
    name: "Equatorial Guinea",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f6.svg",
  },
  {
    id: "Eritrea",
    name: "Eritrea",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1f7.svg",
  },
  {
    id: "Estonia",
    name: "Estonia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1ea.svg",
  },
  {
    id: "Ethiopia",
    name: "Ethiopia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1f9.svg",
  },
  {
    id: "Falkland Islands (Malvinas)",
    name: "Falkland Islands (Malvinas)",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f0.svg",
  },
  {
    id: "Faroe Islands",
    name: "Faroe Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f4.svg",
  },
  {
    id: "Fiji",
    name: "Fiji",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1ef.svg",
  },
  {
    id: "Finland",
    name: "Finland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1ee.svg",
  },
  {
    id: "France",
    name: "France",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f7.svg",
  },
  {
    id: "French Guiana",
    name: "French Guiana",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1eb.svg",
  },
  {
    id: "French Polynesia",
    name: "French Polynesia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1eb.svg",
  },
  {
    id: "French Southern Territories",
    name: "French Southern Territories",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1eb.svg",
  },
  {
    id: "Gabon",
    name: "Gabon",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1e6.svg",
  },
  {
    id: "Gambia",
    name: "Gambia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f2.svg",
  },
  {
    id: "Georgia",
    name: "Georgia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ea.svg",
  },
  {
    id: "Germany",
    name: "Germany",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1ea.svg",
  },
  {
    id: "Ghana",
    name: "Ghana",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ed.svg",
  },
  {
    id: "Gibraltar",
    name: "Gibraltar",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ee.svg",
  },
  {
    id: "Greece",
    name: "Greece",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f7.svg",
  },
  {
    id: "Greenland",
    name: "Greenland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f1.svg",
  },
  {
    id: "Grenada",
    name: "Grenada",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1e9.svg",
  },
  {
    id: "Guadeloupe",
    name: "Guadeloupe",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f5.svg",
  },
  {
    id: "Guam",
    name: "Guam",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1fa.svg",
  },
  {
    id: "Guatemala",
    name: "Guatemala",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f9.svg",
  },
  {
    id: "Guernsey",
    name: "Guernsey",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ec.svg",
  },
  {
    id: "Guinea",
    name: "Guinea",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f3.svg",
  },
  {
    id: "Guinea-Bissau",
    name: "Guinea-Bissau",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1fc.svg",
  },
  {
    id: "Guyana",
    name: "Guyana",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1fe.svg",
  },
  {
    id: "Haiti",
    name: "Haiti",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f9.svg",
  },
  {
    id: "Heard Island and McDonald Islands",
    name: "Heard Island and McDonald Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f2.svg",
  },
  {
    id: "Holy See (Vatican City State)",
    name: "Holy See (Vatican City State)",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1e6.svg",
  },
  {
    id: "Honduras",
    name: "Honduras",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f3.svg",
  },
  {
    id: "Hong Kong",
    name: "Hong Kong",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f0.svg",
  },
  {
    id: "Hungary",
    name: "Hungary",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1fa.svg",
  },
  {
    id: "Iceland",
    name: "Iceland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f8.svg",
  },
  {
    id: "India",
    name: "India",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f3.svg",
  },
  {
    id: "Indonesia",
    name: "Indonesia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1e9.svg",
  },
  {
    id: "Iran, Islamic Republic of",
    name: "Iran, Islamic Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f7.svg",
  },
  {
    id: "Iraq",
    name: "Iraq",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f6.svg",
  },
  {
    id: "Ireland",
    name: "Ireland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1ea.svg",
  },
  {
    id: "Isle of Man",
    name: "Isle of Man",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f2.svg",
  },
  {
    id: "Israel",
    name: "Israel",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f1.svg",
  },
  {
    id: "Italy",
    name: "Italy",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f9.svg",
  },
  {
    id: "Jamaica",
    name: "Jamaica",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1f2.svg",
  },
  {
    id: "Japan",
    name: "Japan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1f5.svg",
  },
  {
    id: "Jersey",
    name: "Jersey",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1ea.svg",
  },
  {
    id: "Jordan",
    name: "Jordan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1f4.svg",
  },
  {
    id: "Kazakhstan",
    name: "Kazakhstan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ff.svg",
  },
  {
    id: "Kenya",
    name: "Kenya",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ea.svg",
  },
  {
    id: "Kiribati",
    name: "Kiribati",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ee.svg",
  },
  {
    id: "Korea, Democratic People's Republic of",
    name: "Korea, Democratic People's Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f5.svg",
  },
  {
    id: "Korea, Republic of",
    name: "Korea, Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f7.svg",
  },
  {
    id: "Kuwait",
    name: "Kuwait",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1fc.svg",
  },
  {
    id: "Kyrgyzstan",
    name: "Kyrgyzstan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ec.svg",
  },
  {
    id: "Lao People's Democratic Republic",
    name: "Lao People's Democratic Republic",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1e6.svg",
  },
  {
    id: "Latvia",
    name: "Latvia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1fb.svg",
  },
  {
    id: "Lebanon",
    name: "Lebanon",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1e7.svg",
  },
  {
    id: "Lesotho",
    name: "Lesotho",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f8.svg",
  },
  {
    id: "Liberia",
    name: "Liberia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f7.svg",
  },
  {
    id: "Libya",
    name: "Libya",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1fe.svg",
  },
  {
    id: "Liechtenstein",
    name: "Liechtenstein",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1ee.svg",
  },
  {
    id: "Lithuania",
    name: "Lithuania",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f9.svg",
  },
  {
    id: "Luxembourg",
    name: "Luxembourg",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1fa.svg",
  },
  {
    id: "Macao",
    name: "Macao",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f4.svg",
  },
  {
    id: "Macedonia, the Former Yugoslav Republic of",
    name: "Macedonia, the Former Yugoslav Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f0.svg",
  },
  {
    id: "Madagascar",
    name: "Madagascar",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ec.svg",
  },
  {
    id: "Malawi",
    name: "Malawi",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fc.svg",
  },
  {
    id: "Malaysia",
    name: "Malaysia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fe.svg",
  },
  {
    id: "Maldives",
    name: "Maldives",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fb.svg",
  },
  {
    id: "Mali",
    name: "Mali",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f1.svg",
  },
  {
    id: "Malta",
    name: "Malta",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f9.svg",
  },
  {
    id: "Marshall Islands",
    name: "Marshall Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ed.svg",
  },
  {
    id: "Martinique",
    name: "Martinique",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f6.svg",
  },
  {
    id: "Mauritania",
    name: "Mauritania",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f7.svg",
  },
  {
    id: "Mauritius",
    name: "Mauritius",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fa.svg",
  },
  {
    id: "Mayotte",
    name: "Mayotte",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fe-1f1f9.svg",
  },
  {
    id: "Mexico",
    name: "Mexico",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fd.svg",
  },
  {
    id: "Micronesia, Federated States of",
    name: "Micronesia, Federated States of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f2.svg",
  },
  {
    id: "Moldova, Republic of",
    name: "Moldova, Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1e9.svg",
  },
  {
    id: "Monaco",
    name: "Monaco",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1e8.svg",
  },
  {
    id: "Mongolia",
    name: "Mongolia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f3.svg",
  },
  {
    id: "Montenegro",
    name: "Montenegro",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ea.svg",
  },
  {
    id: "Montserrat",
    name: "Montserrat",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f8.svg",
  },
  {
    id: "Morocco",
    name: "Morocco",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1e6.svg",
  },
  {
    id: "Mozambique",
    name: "Mozambique",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ff.svg",
  },
  {
    id: "Myanmar",
    name: "Myanmar",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f2.svg",
  },
  {
    id: "Namibia",
    name: "Namibia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1e6.svg",
  },
  {
    id: "Nauru",
    name: "Nauru",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f7.svg",
  },
  {
    id: "Nepal",
    name: "Nepal",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f5.svg",
  },
  {
    id: "Netherlands",
    name: "Netherlands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f1.svg",
  },
  {
    id: "New Caledonia",
    name: "New Caledonia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1e8.svg",
  },
  {
    id: "New Zealand",
    name: "New Zealand",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ff.svg",
  },
  {
    id: "Nicaragua",
    name: "Nicaragua",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ee.svg",
  },
  {
    id: "Niger",
    name: "Niger",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ea.svg",
  },
  {
    id: "Nigeria",
    name: "Nigeria",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ec.svg",
  },
  {
    id: "Niue",
    name: "Niue",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1fa.svg",
  },
  {
    id: "Norfolk Island",
    name: "Norfolk Island",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1eb.svg",
  },
  {
    id: "Northern Mariana Islands",
    name: "Northern Mariana Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f5.svg",
  },
  {
    id: "Norway",
    name: "Norway",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f4.svg",
  },
  {
    id: "Oman",
    name: "Oman",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f4-1f1f2.svg",
  },
  {
    id: "Pakistan",
    name: "Pakistan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f0.svg",
  },
  {
    id: "Palau",
    name: "Palau",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1fc.svg",
  },
  {
    id: "Palestine, State of",
    name: "Palestine, State of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f8.svg",
  },
  {
    id: "Panama",
    name: "Panama",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1e6.svg",
  },
  {
    id: "Papua New Guinea",
    name: "Papua New Guinea",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1ec.svg",
  },
  {
    id: "Paraguay",
    name: "Paraguay",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1fe.svg",
  },
  {
    id: "Peru",
    name: "Peru",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1ea.svg",
  },
  {
    id: "Philippines",
    name: "Philippines",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1ed.svg",
  },
  {
    id: "Pitcairn",
    name: "Pitcairn",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f3.svg",
  },
  {
    id: "Poland",
    name: "Poland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f1.svg",
  },
  {
    id: "Portugal",
    name: "Portugal",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f9.svg",
  },
  {
    id: "Puerto Rico",
    name: "Puerto Rico",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f7.svg",
  },
  {
    id: "Qatar",
    name: "Qatar",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f6-1f1e6.svg",
  },
  {
    id: "Réunion",
    name: "Réunion",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1ea.svg",
  },
  {
    id: "Romania",
    name: "Romania",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1f4.svg",
  },
  {
    id: "Russian Federation",
    name: "Russian Federation",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1fa.svg",
  },
  {
    id: "Rwanda",
    name: "Rwanda",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1fc.svg",
  },
  {
    id: "Saint Barthélemy",
    name: "Saint Barthélemy",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f1.svg",
  },
  {
    id: "Saint Helena, Ascension and Tristan da Cunha",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ed.svg",
  },
  {
    id: "Saint Kitts and Nevis",
    name: "Saint Kitts and Nevis",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f3.svg",
  },
  {
    id: "Saint Lucia",
    name: "Saint Lucia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1e8.svg",
  },
  {
    id: "Saint Martin (French part)",
    name: "Saint Martin (French part)",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1eb.svg",
  },
  {
    id: "Saint Pierre and Miquelon",
    name: "Saint Pierre and Miquelon",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f2.svg",
  },
  {
    id: "Saint Vincent and the Grenadines",
    name: "Saint Vincent and the Grenadines",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1e8.svg",
  },
  {
    id: "Samoa",
    name: "Samoa",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fc-1f1f8.svg",
  },
  {
    id: "San Marino",
    name: "San Marino",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f2.svg",
  },
  {
    id: "Sao Tome and Principe",
    name: "Sao Tome and Principe",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f9.svg",
  },
  {
    id: "Saudi Arabia",
    name: "Saudi Arabia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e6.svg",
  },
  {
    id: "Senegal",
    name: "Senegal",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f3.svg",
  },
  {
    id: "Serbia",
    name: "Serbia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1f8.svg",
  },
  {
    id: "Seychelles",
    name: "Seychelles",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e8.svg",
  },
  {
    id: "Sierra Leone",
    name: "Sierra Leone",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f1.svg",
  },
  {
    id: "Singapore",
    name: "Singapore",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ec.svg",
  },
  {
    id: "Sint Maarten (Dutch part)",
    name: "Sint Maarten (Dutch part)",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1fd.svg",
  },
  {
    id: "Slovakia",
    name: "Slovakia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f0.svg",
  },
  {
    id: "Slovenia",
    name: "Slovenia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ee.svg",
  },
  {
    id: "Solomon Islands",
    name: "Solomon Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e7.svg",
  },
  {
    id: "Somalia",
    name: "Somalia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f4.svg",
  },
  {
    id: "South Africa",
    name: "South Africa",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ff-1f1e6.svg",
  },
  {
    id: "South Georgia and the South Sandwich Islands",
    name: "South Georgia and the South Sandwich Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f8.svg",
  },
  {
    id: "South Sudan",
    name: "South Sudan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f8.svg",
  },
  {
    id: "Spain",
    name: "Spain",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1f8.svg",
  },
  {
    id: "Sri Lanka",
    name: "Sri Lanka",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f0.svg",
  },
  {
    id: "Sudan",
    name: "Sudan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e9.svg",
  },
  {
    id: "Suriname",
    name: "Suriname",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f7.svg",
  },
  {
    id: "Svalbard and Jan Mayen",
    name: "Svalbard and Jan Mayen",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ef.svg",
  },
  {
    id: "Swaziland",
    name: "Swaziland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ff.svg",
  },
  {
    id: "Sweden",
    name: "Sweden",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ea.svg",
  },
  {
    id: "Switzerland",
    name: "Switzerland",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ed.svg",
  },
  {
    id: "Syrian Arab Republic",
    name: "Syrian Arab Republic",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1fe.svg",
  },
  {
    id: "Taiwan, Province of China",
    name: "Taiwan, Province of China",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1fc.svg",
  },
  {
    id: "Tajikistan",
    name: "Tajikistan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ef.svg",
  },
  {
    id: "Tanzania, United Republic of",
    name: "Tanzania, United Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ff.svg",
  },
  {
    id: "Thailand",
    name: "Thailand",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ed.svg",
  },
  {
    id: "Timor-Leste",
    name: "Timor-Leste",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f1.svg",
  },
  {
    id: "Togo",
    name: "Togo",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ec.svg",
  },
  {
    id: "Tokelau",
    name: "Tokelau",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f0.svg",
  },
  {
    id: "Tonga",
    name: "Tonga",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f4.svg",
  },
  {
    id: "Trinidad and Tobago",
    name: "Trinidad and Tobago",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f9.svg",
  },
  {
    id: "Tunisia",
    name: "Tunisia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f3.svg",
  },
  {
    id: "Turkey",
    name: "Turkey",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f7.svg",
  },
  {
    id: "Turkmenistan",
    name: "Turkmenistan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f2.svg",
  },
  {
    id: "Turks and Caicos Islands",
    name: "Turks and Caicos Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1e8.svg",
  },
  {
    id: "Tuvalu",
    name: "Tuvalu",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1fb.svg",
  },
  {
    id: "Uganda",
    name: "Uganda",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1ec.svg",
  },
  {
    id: "Ukraine",
    name: "Ukraine",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1e6.svg",
  },
  {
    id: "United Arab Emirates",
    name: "United Arab Emirates",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ea.svg",
  },
  {
    id: "United Kingdom",
    name: "United Kingdom",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1e7.svg",
  },
  {
    id: "United States",
    name: "United States",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1f8.svg",
  },
  {
    id: "United States Minor Outlying Islands",
    name: "United States Minor Outlying Islands",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1f2.svg",
  },
  {
    id: "Uruguay",
    name: "Uruguay",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1fe.svg",
  },
  {
    id: "Uzbekistan",
    name: "Uzbekistan",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1ff.svg",
  },
  {
    id: "Vanuatu",
    name: "Vanuatu",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1fa.svg",
  },
  {
    id: "Venezuela, Bolivarian Republic of",
    name: "Venezuela, Bolivarian Republic of",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1ea.svg",
  },
  {
    id: "Viet Nam",
    name: "Viet Nam",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1f3.svg",
  },
  {
    id: "Virgin Islands, British",
    name: "Virgin Islands, British",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1ec.svg",
  },
  {
    id: "Virgin Islands, U.S.",
    name: "Virgin Islands, U.S.",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1ee.svg",
  },
  {
    id: "Wallis and Futuna",
    name: "Wallis and Futuna",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fc-1f1eb.svg",
  },
  {
    id: "Western Sahara",
    name: "Western Sahara",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1ed.svg",
  },
  {
    id: "Yemen",
    name: "Yemen",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fe-1f1ea.svg",
  },
  {
    id: "Zambia",
    name: "Zambia",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ff-1f1f2.svg",
  },
  {
    id: "Zimbabwe",
    name: "Zimbabwe",
    flag: "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ff-1f1fc.svg",
  },
];
