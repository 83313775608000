import isEqual from "lodash/isEqual";
import Omit from "lodash/omit";
import { format } from "date-fns";
import { conditionAttributes, IFiltersProps, IOperator } from "./filter.schema";
import * as z from "zod";

export const areObjectsEqual = (value1: any, value2: any, skipFields: string[] = []): boolean => {
  const cleanedValue1 = Omit(value1, skipFields);
  const cleanedValue2 = Omit(value2, skipFields);
  return isEqual(cleanedValue1, cleanedValue2);
};

const REGEX_PATTERN_NUMBER_WITH_COMMA = /\B(?=(\d{3})+(?!\d))/g;

export function formatNumberWithComma(num: number | null | undefined): string {
  if (num === null || num === undefined) return "";
  if (num === 0) return "0";
  return num.toString().replace(REGEX_PATTERN_NUMBER_WITH_COMMA, ",");
}

export function formatCompactNumber(
  number: number,
  prefix: string = "",
  compactDisplay: "short" | "long" = "short"
): string {
  const formatter = Intl.NumberFormat("en", { notation: "compact", compactDisplay: compactDisplay });
  const formattedNumber = formatter.format(number);
  return prefix + formattedNumber;
}

export default function formatNumberToNDigits(num: number | null | undefined, digits: number): string {
  if (num === null || num === undefined) return "";
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

export function formatMoney(num: number, currency: string, digits?: number): string {
  const number = digits ? num.toFixed(digits) : num.toString();
  const [integerPart, decimalPart] = number.split(".");
  const formattedIntegerPart = integerPart.replace(REGEX_PATTERN_NUMBER_WITH_COMMA, ",");
  const formattedNumber = decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  return currency === "eur" ? `${formattedNumber}€` : `$${formattedNumber}`;
}

export function formatShortMoney(num: number, currency: string): string {
  const formattedNumber = formatNumberToNDigits(num, 1);
  return currency === "eur" ? `${formattedNumber}€` : `$${formattedNumber}`;
}

export function formatStripeNumber(num: number): number {
  return num / 100;
}

export const DATES_SEPARATOR = " - ";
export const GTE = "_gte";
export const LTE = "_lte";
export const WEEK_IN_MS = 1000 * 60 * 60 * 24 * 7;
export const DAY_IN_MS = 1000 * 60 * 60 * 24;
export const HOUR_IN_MS = 1000 * 60 * 60;
export const MINUTE_IN_MS = 1000 * 60;
export const THERE_MINUTE_IN_MS = MINUTE_IN_MS * 3;
export const MONTH_IN_SECONDS = 60 * 60 * 24 * 30;

export const timeAgo = (date_string: string): string => {
  const now = new Date();
  const diff = now.getTime() - new Date(date_string).getTime();
  const diffInDays = Math.floor(diff / DAY_IN_MS);
  const diffInHours = Math.floor(diff / HOUR_IN_MS);
  const diffInMinutes = Math.floor(diff / MINUTE_IN_MS);
  if (diffInDays > 0) {
    return `${diffInDays} days ago`;
  } else if (diffInHours > 0) {
    return `${diffInHours} hours ago`;
  } else if (diffInMinutes > 0) {
    return `${diffInMinutes} minutes ago`;
  } else {
    return "just now";
  }
};

export const formatDateStringToReadableDate = (date_string: string): string => {
  const [year, month, day] = date_string.split("-"); /*YYYY-MM-DD*/
  const monthlist = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return `${monthlist[parseInt(month) - 1]} ${day}, ${year}`;
};

export const formatDateTimeStringToReadableDate = (date_string: string): string => {
  const date = new Date(date_string);
  return format(date, "MMM dd, yyyy");
};

export const formatDateFromDateObject = (date: Date): string => {
  return format(date, "MMM dd, yyyy");
};

export const getCurrentDateTime = () => {
  const currentDate = new Date().toISOString().slice(0, 10);
  const currentTime = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" });
  return `${currentDate} ${currentTime}`;
};

export function fromStringToDateRange(value: string | undefined) {
  if (!value) return undefined;
  const [fromDate, toDate] = value.split(DATES_SEPARATOR);
  return {
    from: new Date(fromDate),
    to: new Date(toDate),
  };
}

export function getDaysBetweenDates(from: Date, to: Date) {
  const diffInMs = Math.abs(from.getTime() - to.getTime());
  return Math.round(diffInMs / DAY_IN_MS);
}

const orderBySchema = z.object({
  desc: z.boolean(),
  field: z.string(),
});

type OrderBy = z.infer<typeof orderBySchema>;

const baseSearchParamsSchema = z.object({
  page: z.number().optional().nullable(),
  limit: z.number().optional().nullable(),
  order_by: z.array(orderBySchema).optional().nullable(),
  include_total_results: z.boolean().optional().nullable(),
  blur_company_data: z.boolean().optional().nullable(),
});
type BaseSearchParams = z.infer<typeof baseSearchParamsSchema>;

export function filtersToParams<TSearchParms extends BaseSearchParams>(
  filters: IFiltersProps[],
  order_by: OrderBy[] | undefined | null
): TSearchParms {
  const searchParam = {} as any;
  searchParam["order_by"] = order_by;
  for (const filter of filters) {
    if (filter.value == undefined || filter.operator == undefined) {
      continue;
    }

    if (filter.operator == IOperator.between_dates) {
      SetDateRangeFields(filter, searchParam);
      continue;
    }

    if (!filter.id.includes(".")) {
      const field =
        conditionAttributes[filter.operator].prefix + filter.id + conditionAttributes[filter.operator].suffix;
      searchParam[field] = filter.value;
      continue;
    }

    const keys = filter.id.split(".");
    if (!searchParam[keys[0]]) searchParam[keys[0]] = {};
    searchParam[keys[0]][
      conditionAttributes[filter.operator].prefix + keys[1] + conditionAttributes[filter.operator].suffix
    ] = filter.value;
  }

  return searchParam;
}

function SetDateRangeFields(filter: IFiltersProps, searchParam: any) {
  const value = filter.value as string;
  if (
    value != "" &&
    value != null &&
    value != undefined &&
    typeof value == "string" &&
    value.split(DATES_SEPARATOR).length == 2
  ) {
    if (!filter.id.includes(".")) {
      searchParam[filter.id + GTE] = format(new Date(value.split(DATES_SEPARATOR)[0]), "yyyy-MM-dd");
      searchParam[filter.id + LTE] = format(new Date(value.split(DATES_SEPARATOR)[1]), "yyyy-MM-dd");
    } else {
      const keys = filter.id.split(".");
      if (!searchParam[keys[0]]) searchParam[keys[0]] = {};
      searchParam[keys[0]][keys[1] + GTE] = format(new Date(value.split(DATES_SEPARATOR)[0]), "yyyy-MM-dd");
      searchParam[keys[0]][keys[1] + LTE] = format(new Date(value.split(DATES_SEPARATOR)[1]), "yyyy-MM-dd");
    }
  }
}

export const convertNumberStringToNumber = (param: string): number => {
  const numberPart = param.replace(/[a-zA-Z\+]/g, "");
  let unit = param.replace(/\d+/g, "");
  unit = unit.replace(".", "");
  unit = unit.replace("+", "");
  const number = parseInt(numberPart);
  switch (unit) {
    case "k":
      return number * 1000;
    case "K":
      return number * 1000;
    case "m":
      return number * 1000000;
    case "M":
      return number * 1000000;
    default:
      return number;
  }
};
