export const crustDataIndustries = [
  {
    id: "Chiropractors",
    name: "Chiropractors",
  },
  {
    id: "Defense and Space Manufacturing",
    name: "Defense and Space Manufacturing",
  },
  {
    id: "Optometrists",
    name: "Optometrists",
  },
  {
    id: "Computer Hardware Manufacturing",
    name: "Computer Hardware Manufacturing",
  },
  {
    id: "Software Development",
    name: "Software Development",
  },
  {
    id: "Computer Networking Products",
    name: "Computer Networking Products",
  },
  {
    id: "Transportation Equipment Manufacturing",
    name: "Transportation Equipment Manufacturing",
  },
  {
    id: "Technology, Information and Internet",
    name: "Technology, Information and Internet",
  },
  {
    id: "Physical, Occupational and Speech Therapists",
    name: "Physical, Occupational and Speech Therapists",
  },
  {
    id: "Semiconductor Manufacturing",
    name: "Semiconductor Manufacturing",
  },
  {
    id: "Telecommunications",
    name: "Telecommunications",
  },
  {
    id: "Law Practice",
    name: "Law Practice",
  },
  {
    id: "Housing Programs",
    name: "Housing Programs",
  },
  {
    id: "Legal Services",
    name: "Legal Services",
  },
  {
    id: "Business Consulting and Services",
    name: "Business Consulting and Services",
  },
  {
    id: "Biotechnology Research",
    name: "Biotechnology Research",
  },
  {
    id: "Family Planning Centers",
    name: "Family Planning Centers",
  },
  {
    id: "Medical Practices",
    name: "Medical Practices",
  },
  {
    id: "Transportation Programs",
    name: "Transportation Programs",
  },
  {
    id: "Hospitals and Health Care",
    name: "Hospitals and Health Care",
  },
  {
    id: "Utilities Administration",
    name: "Utilities Administration",
  },
  {
    id: "Pharmaceutical Manufacturing",
    name: "Pharmaceutical Manufacturing",
  },
  {
    id: "Outpatient Care Centers",
    name: "Outpatient Care Centers",
  },
  {
    id: "Veterinary Services",
    name: "Veterinary Services",
  },
  {
    id: "Medical Equipment Manufacturing",
    name: "Medical Equipment Manufacturing",
  },
  {
    id: "Space Research and Technology",
    name: "Space Research and Technology",
  },
  {
    id: "Motor Vehicle Parts Manufacturing",
    name: "Motor Vehicle Parts Manufacturing",
  },
  {
    id: "Personal Care Product Manufacturing",
    name: "Personal Care Product Manufacturing",
  },
  {
    id: "Retail Apparel and Fashion",
    name: "Retail Apparel and Fashion",
  },
  {
    id: "Sporting Goods Manufacturing",
    name: "Sporting Goods Manufacturing",
  },
  {
    id: "Tobacco Manufacturing",
    name: "Tobacco Manufacturing",
  },
  {
    id: "Medical and Diagnostic Laboratories",
    name: "Medical and Diagnostic Laboratories",
  },
  {
    id: "Retail Groceries",
    name: "Retail Groceries",
  },
  {
    id: "Food and Beverage Manufacturing",
    name: "Food and Beverage Manufacturing",
  },
  {
    id: "Oil Extraction",
    name: "Oil Extraction",
  },
  {
    id: "Computers and Electronics Manufacturing",
    name: "Computers and Electronics Manufacturing",
  },
  {
    id: "Natural Gas Extraction",
    name: "Natural Gas Extraction",
  },
  {
    id: "Manufacturing",
    name: "Manufacturing",
  },
  {
    id: "Furniture and Home Furnishings Manufacturing",
    name: "Furniture and Home Furnishings Manufacturing",
  },
  {
    id: "Home Health Care Services",
    name: "Home Health Care Services",
  },
  {
    id: "Retail",
    name: "Retail",
  },
  {
    id: "Embedded Software Products",
    name: "Embedded Software Products",
  },
  {
    id: "Entertainment Providers",
    name: "Entertainment Providers",
  },
  {
    id: "Mobile Computing Software Products",
    name: "Mobile Computing Software Products",
  },
  {
    id: "Gambling Facilities and Casinos",
    name: "Gambling Facilities and Casinos",
  },
  {
    id: "Ambulance Services",
    name: "Ambulance Services",
  },
  {
    id: "Desktop Computing Software Products",
    name: "Desktop Computing Software Products",
  },
  {
    id: "IT System Custom Software Development",
    name: "IT System Custom Software Development",
  },
  {
    id: "Travel Arrangements",
    name: "Travel Arrangements",
  },
  {
    id: "IT System Operations and Maintenance",
    name: "IT System Operations and Maintenance",
  },
  {
    id: "Hospitality",
    name: "Hospitality",
  },
  {
    id: "IT System Installation and Disposal",
    name: "IT System Installation and Disposal",
  },
  {
    id: "Restaurants",
    name: "Restaurants",
  },
  {
    id: "IT System Training and Support",
    name: "IT System Training and Support",
  },
  {
    id: "Hospitals",
    name: "Hospitals",
  },
  {
    id: "Spectator Sports",
    name: "Spectator Sports",
  },
  {
    id: "IT System Data Services",
    name: "IT System Data Services",
  },
  {
    id: "Food and Beverage Services",
    name: "Food and Beverage Services",
  },
  {
    id: "IT System Testing and Evaluation",
    name: "IT System Testing and Evaluation",
  },
  {
    id: "Movies, Videos, and Sound",
    name: "Movies, Videos, and Sound",
  },
  {
    id: "Broadcast Media Production and Distribution",
    name: "Broadcast Media Production and Distribution",
  },
  {
    id: "Museums, Historical Sites, and Zoos",
    name: "Museums, Historical Sites, and Zoos",
  },
  {
    id: "Artists and Writers",
    name: "Artists and Writers",
  },
  {
    id: "Performing Arts",
    name: "Performing Arts",
  },
  {
    id: "Recreational Facilities",
    name: "Recreational Facilities",
  },
  {
    id: "Banking",
    name: "Banking",
  },
  {
    id: "Insurance",
    name: "Insurance",
  },
  {
    id: "Nursing Homes and Residential Care Facilities",
    name: "Nursing Homes and Residential Care Facilities",
  },
  {
    id: "Financial Services",
    name: "Financial Services",
  },
  {
    id: "Real Estate",
    name: "Real Estate",
  },
  {
    id: "Investment Banking",
    name: "Investment Banking",
  },
  {
    id: "Investment Management",
    name: "Investment Management",
  },
  {
    id: "Accounting",
    name: "Accounting",
  },
  {
    id: "Construction",
    name: "Construction",
  },
  {
    id: "Wholesale Building Materials",
    name: "Wholesale Building Materials",
  },
  {
    id: "Architecture and Planning",
    name: "Architecture and Planning",
  },
  {
    id: "Civil Engineering",
    name: "Civil Engineering",
  },
  {
    id: "Internet News",
    name: "Internet News",
  },
  {
    id: "Aviation and Aerospace Component Manufacturing",
    name: "Aviation and Aerospace Component Manufacturing",
  },
  {
    id: "Blogs",
    name: "Blogs",
  },
  {
    id: "Motor Vehicle Manufacturing",
    name: "Motor Vehicle Manufacturing",
  },
  {
    id: "Interior Design",
    name: "Interior Design",
  },
  {
    id: "Chemical Manufacturing",
    name: "Chemical Manufacturing",
  },
  {
    id: "Social Networking Platforms",
    name: "Social Networking Platforms",
  },
  {
    id: "Machinery Manufacturing",
    name: "Machinery Manufacturing",
  },
  {
    id: "Household and Institutional Furniture Manufacturing",
    name: "Household and Institutional Furniture Manufacturing",
  },
  {
    id: "Business Intelligence Platforms",
    name: "Business Intelligence Platforms",
  },
  {
    id: "Mining",
    name: "Mining",
  },
  {
    id: "Business Content",
    name: "Business Content",
  },
  {
    id: "Oil and Gas",
    name: "Oil and Gas",
  },
  {
    id: "Data Security Software Products",
    name: "Data Security Software Products",
  },
  {
    id: "Shipbuilding",
    name: "Shipbuilding",
  },
  {
    id: "Utilities",
    name: "Utilities",
  },
  {
    id: "Mobile Gaming Apps",
    name: "Mobile Gaming Apps",
  },
  {
    id: "Textile Manufacturing",
    name: "Textile Manufacturing",
  },
  {
    id: "Internet Publishing",
    name: "Internet Publishing",
  },
  {
    id: "Paper and Forest Product Manufacturing",
    name: "Paper and Forest Product Manufacturing",
  },
  {
    id: "Media and Telecommunications",
    name: "Media and Telecommunications",
  },
  {
    id: "Railroad Equipment Manufacturing",
    name: "Railroad Equipment Manufacturing",
  },
  {
    id: "Blockchain Services",
    name: "Blockchain Services",
  },
  {
    id: "Farming",
    name: "Farming",
  },
  {
    id: "Services for the Elderly and Disabled",
    name: "Services for the Elderly and Disabled",
  },
  {
    id: "Ranching",
    name: "Ranching",
  },
  {
    id: "Dairy Product Manufacturing",
    name: "Dairy Product Manufacturing",
  },
  {
    id: "Office Furniture and Fixtures Manufacturing",
    name: "Office Furniture and Fixtures Manufacturing",
  },
  {
    id: "Fisheries",
    name: "Fisheries",
  },
  {
    id: "Community Services",
    name: "Community Services",
  },
  {
    id: "Primary and Secondary Education",
    name: "Primary and Secondary Education",
  },
  {
    id: "Higher Education",
    name: "Higher Education",
  },
  {
    id: "Education Administration Programs",
    name: "Education Administration Programs",
  },
  {
    id: "Research Services",
    name: "Research Services",
  },
  {
    id: "Mattress and Blinds Manufacturing",
    name: "Mattress and Blinds Manufacturing",
  },
  {
    id: "Armed Forces",
    name: "Armed Forces",
  },
  {
    id: "Legislative Offices",
    name: "Legislative Offices",
  },
  {
    id: "Administration of Justice",
    name: "Administration of Justice",
  },
  {
    id: "International Affairs",
    name: "International Affairs",
  },
  {
    id: "Emergency and Relief Services",
    name: "Emergency and Relief Services",
  },
  {
    id: "Government Administration",
    name: "Government Administration",
  },
  {
    id: "Executive Offices",
    name: "Executive Offices",
  },
  {
    id: "Law Enforcement",
    name: "Law Enforcement",
  },
  {
    id: "Vocational Rehabilitation Services",
    name: "Vocational Rehabilitation Services",
  },
  {
    id: "Public Safety",
    name: "Public Safety",
  },
  {
    id: "Public Policy Offices",
    name: "Public Policy Offices",
  },
  {
    id: "Advertising Services",
    name: "Advertising Services",
  },
  {
    id: "Child Day Care Services",
    name: "Child Day Care Services",
  },
  {
    id: "Newspaper Publishing",
    name: "Newspaper Publishing",
  },
  {
    id: "Performing Arts and Spectator Sports",
    name: "Performing Arts and Spectator Sports",
  },
  {
    id: "Book and Periodical Publishing",
    name: "Book and Periodical Publishing",
  },
  {
    id: "Printing Services",
    name: "Printing Services",
  },
  {
    id: "Information Services",
    name: "Information Services",
  },
  {
    id: "Libraries",
    name: "Libraries",
  },
  {
    id: "Theater Companies",
    name: "Theater Companies",
  },
  {
    id: "Environmental Services",
    name: "Environmental Services",
  },
  {
    id: "Freight and Package Transportation",
    name: "Freight and Package Transportation",
  },
  {
    id: "Dance Companies",
    name: "Dance Companies",
  },
  {
    id: "Individual and Family Services",
    name: "Individual and Family Services",
  },
  {
    id: "Religious Institutions",
    name: "Religious Institutions",
  },
  {
    id: "Civic and Social Organizations",
    name: "Civic and Social Organizations",
  },
  {
    id: "Consumer Services",
    name: "Consumer Services",
  },
  {
    id: "Circuses and Magic Shows",
    name: "Circuses and Magic Shows",
  },
  {
    id: "Truck Transportation",
    name: "Truck Transportation",
  },
  {
    id: "Warehousing and Storage",
    name: "Warehousing and Storage",
  },
  {
    id: "Sports Teams and Clubs",
    name: "Sports Teams and Clubs",
  },
  {
    id: "Airlines and Aviation",
    name: "Airlines and Aviation",
  },
  {
    id: "Maritime Transportation",
    name: "Maritime Transportation",
  },
  {
    id: "Racetracks",
    name: "Racetracks",
  },
  {
    id: "IT Services and IT Consulting",
    name: "IT Services and IT Consulting",
  },
  {
    id: "Market Research",
    name: "Market Research",
  },
  {
    id: "Public Relations and Communications Services",
    name: "Public Relations and Communications Services",
  },
  {
    id: "Design Services",
    name: "Design Services",
  },
  {
    id: "Non-profit Organizations",
    name: "Non-profit Organizations",
  },
  {
    id: "Fundraising",
    name: "Fundraising",
  },
  {
    id: "Strategic Management Services",
    name: "Strategic Management Services",
  },
  {
    id: "Writing and Editing",
    name: "Writing and Editing",
  },
  {
    id: "Staffing and Recruiting",
    name: "Staffing and Recruiting",
  },
  {
    id: "Wholesale Motor Vehicles and Parts",
    name: "Wholesale Motor Vehicles and Parts",
  },
  {
    id: "Professional Training and Coaching",
    name: "Professional Training and Coaching",
  },
  {
    id: "Venture Capital and Private Equity Principals",
    name: "Venture Capital and Private Equity Principals",
  },
  {
    id: "Political Organizations",
    name: "Political Organizations",
  },
  {
    id: "Translation and Localization",
    name: "Translation and Localization",
  },
  {
    id: "Computer Games",
    name: "Computer Games",
  },
  {
    id: "Events Services",
    name: "Events Services",
  },
  {
    id: "Retail Art Supplies",
    name: "Retail Art Supplies",
  },
  {
    id: "Museums",
    name: "Museums",
  },
  {
    id: "Appliances, Electrical, and Electronics Manufacturing",
    name: "Appliances, Electrical, and Electronics Manufacturing",
  },
  {
    id: "Online Audio and Video Media",
    name: "Online Audio and Video Media",
  },
  {
    id: "Wholesale Furniture and Home Furnishings",
    name: "Wholesale Furniture and Home Furnishings",
  },
  {
    id: "Historical Sites",
    name: "Historical Sites",
  },
  {
    id: "Nanotechnology Research",
    name: "Nanotechnology Research",
  },
  {
    id: "Retail Art Dealers",
    name: "Retail Art Dealers",
  },
  {
    id: "Musicians",
    name: "Musicians",
  },
  {
    id: "Zoos and Botanical Gardens",
    name: "Zoos and Botanical Gardens",
  },
  {
    id: "Transportation, Logistics, Supply Chain and Storage",
    name: "Transportation, Logistics, Supply Chain and Storage",
  },
  {
    id: "Plastics Manufacturing",
    name: "Plastics Manufacturing",
  },
  {
    id: "Computer and Network Security",
    name: "Computer and Network Security",
  },
  {
    id: "Wireless Services",
    name: "Wireless Services",
  },
  {
    id: "Amusement Parks and Arcades",
    name: "Amusement Parks and Arcades",
  },
  {
    id: "Alternative Dispute Resolution",
    name: "Alternative Dispute Resolution",
  },
  {
    id: "Security and Investigations",
    name: "Security and Investigations",
  },
  {
    id: "Facilities Services",
    name: "Facilities Services",
  },
  {
    id: "Outsourcing and Offshoring Consulting",
    name: "Outsourcing and Offshoring Consulting",
  },
  {
    id: "Wellness and Fitness Services",
    name: "Wellness and Fitness Services",
  },
  {
    id: "Alternative Medicine",
    name: "Alternative Medicine",
  },
  {
    id: "Media Production",
    name: "Media Production",
  },
  {
    id: "Animation and Post-production",
    name: "Animation and Post-production",
  },
  {
    id: "Leasing Non-residential Real Estate",
    name: "Leasing Non-residential Real Estate",
  },
  {
    id: "Capital Markets",
    name: "Capital Markets",
  },
  {
    id: "Wholesale Photography Equipment and Supplies",
    name: "Wholesale Photography Equipment and Supplies",
  },
  {
    id: "Think Tanks",
    name: "Think Tanks",
  },
  {
    id: "Philanthropic Fundraising Services",
    name: "Philanthropic Fundraising Services",
  },
  {
    id: "Golf Courses and Country Clubs",
    name: "Golf Courses and Country Clubs",
  },
  {
    id: "E-Learning Providers",
    name: "E-Learning Providers",
  },
  {
    id: "Wholesale",
    name: "Wholesale",
  },
  {
    id: "Wholesale Computer Equipment",
    name: "Wholesale Computer Equipment",
  },
  {
    id: "Skiing Facilities",
    name: "Skiing Facilities",
  },
  {
    id: "Wholesale Import and Export",
    name: "Wholesale Import and Export",
  },
  {
    id: "Industrial Machinery Manufacturing",
    name: "Industrial Machinery Manufacturing",
  },
  {
    id: "Photography",
    name: "Photography",
  },
  {
    id: "Human Resources Services",
    name: "Human Resources Services",
  },
  {
    id: "Retail Office Equipment",
    name: "Retail Office Equipment",
  },
  {
    id: "Mental Health Care",
    name: "Mental Health Care",
  },
  {
    id: "Graphic Design",
    name: "Graphic Design",
  },
  {
    id: "International Trade and Development",
    name: "International Trade and Development",
  },
  {
    id: "Beverage Manufacturing",
    name: "Beverage Manufacturing",
  },
  {
    id: "Accommodation and Food Services",
    name: "Accommodation and Food Services",
  },
  {
    id: "Wholesale Metals and Minerals",
    name: "Wholesale Metals and Minerals",
  },
  {
    id: "Retail Luxury Goods and Jewelry",
    name: "Retail Luxury Goods and Jewelry",
  },
  {
    id: "Glass, Ceramics and Concrete Manufacturing",
    name: "Glass, Ceramics and Concrete Manufacturing",
  },
  {
    id: "Packaging and Containers Manufacturing",
    name: "Packaging and Containers Manufacturing",
  },
  {
    id: "Hotels and Motels",
    name: "Hotels and Motels",
  },
  {
    id: "Automation Machinery Manufacturing",
    name: "Automation Machinery Manufacturing",
  },
  {
    id: "Wholesale Appliances, Electrical, and Electronics",
    name: "Wholesale Appliances, Electrical, and Electronics",
  },
  {
    id: "Government Relations Services",
    name: "Government Relations Services",
  },
  {
    id: "Bed-and-Breakfasts, Hostels, Homestays",
    name: "Bed-and-Breakfasts, Hostels, Homestays",
  },
  {
    id: "Horticulture",
    name: "Horticulture",
  },
  {
    id: "Wholesale Hardware, Plumbing, Heating Equipment",
    name: "Wholesale Hardware, Plumbing, Heating Equipment",
  },
  {
    id: "Wholesale Machinery",
    name: "Wholesale Machinery",
  },
  {
    id: "Caterers",
    name: "Caterers",
  },
  {
    id: "Mobile Food Services",
    name: "Mobile Food Services",
  },
  {
    id: "Renewable Energy Power Generation",
    name: "Renewable Energy Power Generation",
  },
  {
    id: "Bars, Taverns, and Nightclubs",
    name: "Bars, Taverns, and Nightclubs",
  },
  {
    id: "Renewable Energy Equipment Manufacturing",
    name: "Renewable Energy Equipment Manufacturing",
  },
  {
    id: "Engineering Services",
    name: "Engineering Services",
  },
  {
    id: "Services for Renewable Energy",
    name: "Services for Renewable Energy",
  },
  {
    id: "Digital Accessibility Services",
    name: "Digital Accessibility Services",
  },
  {
    id: "Accessible Hardware Manufacturing",
    name: "Accessible Hardware Manufacturing",
  },
  {
    id: "Accessible Architecture and Design",
    name: "Accessible Architecture and Design",
  },
  {
    id: "Robot Manufacturing",
    name: "Robot Manufacturing",
  },
  {
    id: "Robotics Engineering",
    name: "Robotics Engineering",
  },
  {
    id: "Repair and Maintenance",
    name: "Repair and Maintenance",
  },
  {
    id: "Surveying and Mapping Services",
    name: "Surveying and Mapping Services",
  },
  {
    id: "Vehicle Repair and Maintenance",
    name: "Vehicle Repair and Maintenance",
  },
  {
    id: "Retail Pharmacies",
    name: "Retail Pharmacies",
  },
  {
    id: "Climate Technology Product Manufacturing",
    name: "Climate Technology Product Manufacturing",
  },
  {
    id: "Climate Data and Analytics",
    name: "Climate Data and Analytics",
  },
  {
    id: "Alternative Fuel Vehicle Manufacturing",
    name: "Alternative Fuel Vehicle Manufacturing",
  },
  {
    id: "Wholesale Recyclable Materials",
    name: "Wholesale Recyclable Materials",
  },
  {
    id: "Smart Meter Manufacturing",
    name: "Smart Meter Manufacturing",
  },
  {
    id: "Fuel Cell Manufacturing",
    name: "Fuel Cell Manufacturing",
  },
  {
    id: "Wholesale Luxury Goods and Jewelry",
    name: "Wholesale Luxury Goods and Jewelry",
  },
  {
    id: "Regenerative Design",
    name: "Regenerative Design",
  },
  {
    id: "Wholesale Paper Products",
    name: "Wholesale Paper Products",
  },
  {
    id: "Electronic and Precision Equipment Maintenance",
    name: "Electronic and Precision Equipment Maintenance",
  },
  {
    id: "Wholesale Drugs and Sundries",
    name: "Wholesale Drugs and Sundries",
  },
  {
    id: "Wholesale Apparel and Sewing Supplies",
    name: "Wholesale Apparel and Sewing Supplies",
  },
  {
    id: "Commercial and Industrial Machinery Maintenance",
    name: "Commercial and Industrial Machinery Maintenance",
  },
  {
    id: "Farming, Ranching, Forestry",
    name: "Farming, Ranching, Forestry",
  },
  {
    id: "Reupholstery and Furniture Repair",
    name: "Reupholstery and Furniture Repair",
  },
  {
    id: "Wholesale Footwear",
    name: "Wholesale Footwear",
  },
  {
    id: "Wholesale Food and Beverage",
    name: "Wholesale Food and Beverage",
  },
  {
    id: "Footwear and Leather Goods Repair",
    name: "Footwear and Leather Goods Repair",
  },
  {
    id: "Personal and Laundry Services",
    name: "Personal and Laundry Services",
  },
  {
    id: "Personal Care Services",
    name: "Personal Care Services",
  },
  {
    id: "Laundry and Drycleaning Services",
    name: "Laundry and Drycleaning Services",
  },
  {
    id: "Wholesale Raw Farm Products",
    name: "Wholesale Raw Farm Products",
  },
  {
    id: "Wholesale Chemical and Allied Products",
    name: "Wholesale Chemical and Allied Products",
  },
  {
    id: "Pet Services",
    name: "Pet Services",
  },
  {
    id: "Wholesale Petroleum and Petroleum Products",
    name: "Wholesale Petroleum and Petroleum Products",
  },
  {
    id: "Wholesale Alcoholic Beverages",
    name: "Wholesale Alcoholic Beverages",
  },
  {
    id: "Ranching and Fisheries",
    name: "Ranching and Fisheries",
  },
  {
    id: "Internet Marketplace Platforms",
    name: "Internet Marketplace Platforms",
  },
  {
    id: "Retail Motor Vehicles",
    name: "Retail Motor Vehicles",
  },
  {
    id: "Household Services",
    name: "Household Services",
  },
  {
    id: "Retail Furniture and Home Furnishings",
    name: "Retail Furniture and Home Furnishings",
  },
  {
    id: "Retail Appliances, Electrical, and Electronic Equipment",
    name: "Retail Appliances, Electrical, and Electronic Equipment",
  },
  {
    id: "Forestry and Logging",
    name: "Forestry and Logging",
  },
  {
    id: "Retail Building Materials and Garden Equipment",
    name: "Retail Building Materials and Garden Equipment",
  },
  {
    id: "Health and Human Services",
    name: "Health and Human Services",
  },
  {
    id: "Public Health",
    name: "Public Health",
  },
  {
    id: "Public Assistance Programs",
    name: "Public Assistance Programs",
  },
  {
    id: "Food and Beverage Retail",
    name: "Food and Beverage Retail",
  },
  {
    id: "Air, Water, and Waste Program Management",
    name: "Air, Water, and Waste Program Management",
  },
  {
    id: "Conservation Programs",
    name: "Conservation Programs",
  },
  {
    id: "Housing and Community Development",
    name: "Housing and Community Development",
  },
  {
    id: "Community Development and Urban Planning",
    name: "Community Development and Urban Planning",
  },
  {
    id: "Economic Programs",
    name: "Economic Programs",
  },
  {
    id: "Oil, Gas, and Mining",
    name: "Oil, Gas, and Mining",
  },
  {
    id: "Retail Health and Personal Care Products",
    name: "Retail Health and Personal Care Products",
  },
  {
    id: "Coal Mining",
    name: "Coal Mining",
  },
  {
    id: "Military and International Affairs",
    name: "Military and International Affairs",
  },
  {
    id: "Metal Ore Mining",
    name: "Metal Ore Mining",
  },
  {
    id: "Retail Gasoline",
    name: "Retail Gasoline",
  },
  {
    id: "Operations Consulting",
    name: "Operations Consulting",
  },
  {
    id: "Nonmetallic Mineral Mining",
    name: "Nonmetallic Mineral Mining",
  },
  {
    id: "Electric Power Transmission, Control, and Distribution",
    name: "Electric Power Transmission, Control, and Distribution",
  },
  {
    id: "Retail Musical Instruments",
    name: "Retail Musical Instruments",
  },
  {
    id: "Electric Power Generation",
    name: "Electric Power Generation",
  },
  {
    id: "Hydroelectric Power Generation",
    name: "Hydroelectric Power Generation",
  },
  {
    id: "Retail Books and Printed News",
    name: "Retail Books and Printed News",
  },
  {
    id: "Fossil Fuel Electric Power Generation",
    name: "Fossil Fuel Electric Power Generation",
  },
  {
    id: "Nuclear Electric Power Generation",
    name: "Nuclear Electric Power Generation",
  },
  {
    id: "Solar Electric Power Generation",
    name: "Solar Electric Power Generation",
  },
  {
    id: "Environmental Quality Programs",
    name: "Environmental Quality Programs",
  },
  {
    id: "Geothermal Electric Power Generation",
    name: "Geothermal Electric Power Generation",
  },
  {
    id: "Biomass Electric Power Generation",
    name: "Biomass Electric Power Generation",
  },
  {
    id: "Natural Gas Distribution",
    name: "Natural Gas Distribution",
  },
  {
    id: "Water, Waste, Steam, and Air Conditioning Services",
    name: "Water, Waste, Steam, and Air Conditioning Services",
  },
  {
    id: "Retail Florists",
    name: "Retail Florists",
  },
  {
    id: "Retail Office Supplies and Gifts",
    name: "Retail Office Supplies and Gifts",
  },
  {
    id: "Water Supply and Irrigation Systems",
    name: "Water Supply and Irrigation Systems",
  },
  {
    id: "Steam and Air-Conditioning Supply",
    name: "Steam and Air-Conditioning Supply",
  },
  {
    id: "Building Construction",
    name: "Building Construction",
  },
  {
    id: "Retail Recyclable Materials & Used Merchandise",
    name: "Retail Recyclable Materials & Used Merchandise",
  },
  {
    id: "Residential Building Construction",
    name: "Residential Building Construction",
  },
  {
    id: "Data Infrastructure and Analytics",
    name: "Data Infrastructure and Analytics",
  },
  {
    id: "Nonresidential Building Construction",
    name: "Nonresidential Building Construction",
  },
  {
    id: "Utility System Construction",
    name: "Utility System Construction",
  },
  {
    id: "Electrical Equipment Manufacturing",
    name: "Electrical Equipment Manufacturing",
  },
  {
    id: "Online and Mail Order Retail",
    name: "Online and Mail Order Retail",
  },
  {
    id: "Subdivision of Land",
    name: "Subdivision of Land",
  },
  {
    id: "Highway, Street, and Bridge Construction",
    name: "Highway, Street, and Bridge Construction",
  },
  {
    id: "Specialty Trade Contractors",
    name: "Specialty Trade Contractors",
  },
  {
    id: "Building Structure and Exterior Contractors",
    name: "Building Structure and Exterior Contractors",
  },
  {
    id: "Wind Electric Power Generation",
    name: "Wind Electric Power Generation",
  },
  {
    id: "Wineries",
    name: "Wineries",
  },
  {
    id: "Building Equipment Contractors",
    name: "Building Equipment Contractors",
  },
  {
    id: "Rail Transportation",
    name: "Rail Transportation",
  },
  {
    id: "Building Finishing Contractors",
    name: "Building Finishing Contractors",
  },
  {
    id: "Ground Passenger Transportation",
    name: "Ground Passenger Transportation",
  },
  {
    id: "Urban Transit Services",
    name: "Urban Transit Services",
  },
  {
    id: "Interurban and Rural Bus Services",
    name: "Interurban and Rural Bus Services",
  },
  {
    id: "Taxi and Limousine Services",
    name: "Taxi and Limousine Services",
  },
  {
    id: "Animal Feed Manufacturing",
    name: "Animal Feed Manufacturing",
  },
  {
    id: "School and Employee Bus Services",
    name: "School and Employee Bus Services",
  },
  {
    id: "Shuttles and Special Needs Transportation Services",
    name: "Shuttles and Special Needs Transportation Services",
  },
  {
    id: "Sugar and Confectionery Product Manufacturing",
    name: "Sugar and Confectionery Product Manufacturing",
  },
  {
    id: "Pipeline Transportation",
    name: "Pipeline Transportation",
  },
  {
    id: "Fruit and Vegetable Preserves Manufacturing",
    name: "Fruit and Vegetable Preserves Manufacturing",
  },
  {
    id: "Sightseeing Transportation",
    name: "Sightseeing Transportation",
  },
  {
    id: "Meat Products Manufacturing",
    name: "Meat Products Manufacturing",
  },
  {
    id: "Seafood Product Manufacturing",
    name: "Seafood Product Manufacturing",
  },
  {
    id: "Baked Goods Manufacturing",
    name: "Baked Goods Manufacturing",
  },
  {
    id: "Postal Services",
    name: "Postal Services",
  },
  {
    id: "Breweries",
    name: "Breweries",
  },
  {
    id: "Distilleries",
    name: "Distilleries",
  },
  {
    id: "Technology, Information and Media",
    name: "Technology, Information and Media",
  },
  {
    id: "Periodical Publishing",
    name: "Periodical Publishing",
  },
  {
    id: "Book Publishing",
    name: "Book Publishing",
  },
  {
    id: "Movies and Sound Recording",
    name: "Movies and Sound Recording",
  },
  {
    id: "Apparel Manufacturing",
    name: "Apparel Manufacturing",
  },
  {
    id: "Sound Recording",
    name: "Sound Recording",
  },
  {
    id: "Sheet Music Publishing",
    name: "Sheet Music Publishing",
  },
  {
    id: "Radio and Television Broadcasting",
    name: "Radio and Television Broadcasting",
  },
  {
    id: "Fashion Accessories Manufacturing",
    name: "Fashion Accessories Manufacturing",
  },
  {
    id: "Leather Product Manufacturing",
    name: "Leather Product Manufacturing",
  },
  {
    id: "Cable and Satellite Programming",
    name: "Cable and Satellite Programming",
  },
  {
    id: "Telecommunications Carriers",
    name: "Telecommunications Carriers",
  },
  {
    id: "Footwear Manufacturing",
    name: "Footwear Manufacturing",
  },
  {
    id: "Satellite Telecommunications",
    name: "Satellite Telecommunications",
  },
  {
    id: "Women's Handbag Manufacturing",
    name: "Women's Handbag Manufacturing",
  },
  {
    id: "Credit Intermediation",
    name: "Credit Intermediation",
  },
  {
    id: "Savings Institutions",
    name: "Savings Institutions",
  },
  {
    id: "Loan Brokers",
    name: "Loan Brokers",
  },
  {
    id: "Oil and Coal Product Manufacturing",
    name: "Oil and Coal Product Manufacturing",
  },
  {
    id: "Securities and Commodity Exchanges",
    name: "Securities and Commodity Exchanges",
  },
  {
    id: "Chemical Raw Materials Manufacturing",
    name: "Chemical Raw Materials Manufacturing",
  },
  {
    id: "Investment Advice",
    name: "Investment Advice",
  },
  {
    id: "Insurance Carriers",
    name: "Insurance Carriers",
  },
  {
    id: "Artificial Rubber and Synthetic Fiber Manufacturing",
    name: "Artificial Rubber and Synthetic Fiber Manufacturing",
  },
  {
    id: "Agricultural Chemical Manufacturing",
    name: "Agricultural Chemical Manufacturing",
  },
  {
    id: "Insurance Agencies and Brokerages",
    name: "Insurance Agencies and Brokerages",
  },
  {
    id: "Claims Adjusting, Actuarial Services",
    name: "Claims Adjusting, Actuarial Services",
  },
  {
    id: "Funds and Trusts",
    name: "Funds and Trusts",
  },
  {
    id: "Insurance and Employee Benefit Funds",
    name: "Insurance and Employee Benefit Funds",
  },
  {
    id: "Pension Funds",
    name: "Pension Funds",
  },
  {
    id: "Paint, Coating, and Adhesive Manufacturing",
    name: "Paint, Coating, and Adhesive Manufacturing",
  },
  {
    id: "Trusts and Estates",
    name: "Trusts and Estates",
  },
  {
    id: "Soap and Cleaning Product Manufacturing",
    name: "Soap and Cleaning Product Manufacturing",
  },
  {
    id: "Real Estate and Equipment Rental Services",
    name: "Real Estate and Equipment Rental Services",
  },
  {
    id: "Leasing Residential Real Estate",
    name: "Leasing Residential Real Estate",
  },
  {
    id: "Plastics and Rubber Product Manufacturing",
    name: "Plastics and Rubber Product Manufacturing",
  },
  {
    id: "Real Estate Agents and Brokers",
    name: "Real Estate Agents and Brokers",
  },
  {
    id: "Equipment Rental Services",
    name: "Equipment Rental Services",
  },
  {
    id: "Consumer Goods Rental",
    name: "Consumer Goods Rental",
  },
  {
    id: "Rubber Products Manufacturing",
    name: "Rubber Products Manufacturing",
  },
  {
    id: "Clay and Refractory Products Manufacturing",
    name: "Clay and Refractory Products Manufacturing",
  },
  {
    id: "Commercial and Industrial Equipment Rental",
    name: "Commercial and Industrial Equipment Rental",
  },
  {
    id: "Glass Product Manufacturing",
    name: "Glass Product Manufacturing",
  },
  {
    id: "Wood Product Manufacturing",
    name: "Wood Product Manufacturing",
  },
  {
    id: "Professional Services",
    name: "Professional Services",
  },
  {
    id: "Lime and Gypsum Products Manufacturing",
    name: "Lime and Gypsum Products Manufacturing",
  },
  {
    id: "Abrasives and Nonmetallic Minerals Manufacturing",
    name: "Abrasives and Nonmetallic Minerals Manufacturing",
  },
  {
    id: "Primary Metal Manufacturing",
    name: "Primary Metal Manufacturing",
  },
  {
    id: "IT System Design Services",
    name: "IT System Design Services",
  },
  {
    id: "Marketing Services",
    name: "Marketing Services",
  },
  {
    id: "Fabricated Metal Products",
    name: "Fabricated Metal Products",
  },
  {
    id: "Cutlery and Handtool Manufacturing",
    name: "Cutlery and Handtool Manufacturing",
  },
  {
    id: "Architectural and Structural Metal Manufacturing",
    name: "Architectural and Structural Metal Manufacturing",
  },
  {
    id: "Boilers, Tanks, and Shipping Container Manufacturing",
    name: "Boilers, Tanks, and Shipping Container Manufacturing",
  },
  {
    id: "Construction Hardware Manufacturing",
    name: "Construction Hardware Manufacturing",
  },
  {
    id: "Spring and Wire Product Manufacturing",
    name: "Spring and Wire Product Manufacturing",
  },
  {
    id: "Turned Products and Fastener Manufacturing",
    name: "Turned Products and Fastener Manufacturing",
  },
  {
    id: "Holding Companies",
    name: "Holding Companies",
  },
  {
    id: "Metal Treatments",
    name: "Metal Treatments",
  },
  {
    id: "Industry Associations",
    name: "Industry Associations",
  },
  {
    id: "Landscaping Services",
    name: "Landscaping Services",
  },
  {
    id: "Professional Organizations",
    name: "Professional Organizations",
  },
  {
    id: "Metal Valve, Ball, and Roller Manufacturing",
    name: "Metal Valve, Ball, and Roller Manufacturing",
  },
  {
    id: "Administrative and Support Services",
    name: "Administrative and Support Services",
  },
  {
    id: "Office Administration",
    name: "Office Administration",
  },
  {
    id: "Executive Search Services",
    name: "Executive Search Services",
  },
  {
    id: "Temporary Help Services",
    name: "Temporary Help Services",
  },
  {
    id: "Agriculture, Construction, Mining Machinery Manufacturing",
    name: "Agriculture, Construction, Mining Machinery Manufacturing",
  },
  {
    id: "Telephone Call Centers",
    name: "Telephone Call Centers",
  },
  {
    id: "Collection Agencies",
    name: "Collection Agencies",
  },
  {
    id: "Commercial and Service Industry Machinery Manufacturing",
    name: "Commercial and Service Industry Machinery Manufacturing",
  },
  {
    id: "HVAC and Refrigeration Equipment Manufacturing",
    name: "HVAC and Refrigeration Equipment Manufacturing",
  },
  {
    id: "Metalworking Machinery Manufacturing",
    name: "Metalworking Machinery Manufacturing",
  },
  {
    id: "Security Guards and Patrol Services",
    name: "Security Guards and Patrol Services",
  },
  {
    id: "Security Systems Services",
    name: "Security Systems Services",
  },
  {
    id: "Engines and Power Transmission Equipment Manufacturing",
    name: "Engines and Power Transmission Equipment Manufacturing",
  },
  {
    id: "Janitorial Services",
    name: "Janitorial Services",
  },
  {
    id: "Waste Collection",
    name: "Waste Collection",
  },
  {
    id: "Waste Treatment and Disposal",
    name: "Waste Treatment and Disposal",
  },
  {
    id: "Communications Equipment Manufacturing",
    name: "Communications Equipment Manufacturing",
  },
  {
    id: "Audio and Video Equipment Manufacturing",
    name: "Audio and Video Equipment Manufacturing",
  },
  {
    id: "Education",
    name: "Education",
  },
  {
    id: "Measuring and Control Instrument Manufacturing",
    name: "Measuring and Control Instrument Manufacturing",
  },
  {
    id: "Secretarial Schools",
    name: "Secretarial Schools",
  },
  {
    id: "Technical and Vocational Training",
    name: "Technical and Vocational Training",
  },
  {
    id: "Magnetic and Optical Media Manufacturing",
    name: "Magnetic and Optical Media Manufacturing",
  },
  {
    id: "Cosmetology and Barber Schools",
    name: "Cosmetology and Barber Schools",
  },
  {
    id: "Flight Training",
    name: "Flight Training",
  },
  {
    id: "Electric Lighting Equipment Manufacturing",
    name: "Electric Lighting Equipment Manufacturing",
  },
  {
    id: "Fine Arts Schools",
    name: "Fine Arts Schools",
  },
  {
    id: "Sports and Recreation Instruction",
    name: "Sports and Recreation Instruction",
  },
  {
    id: "Household Appliance Manufacturing",
    name: "Household Appliance Manufacturing",
  },
  {
    id: "Language Schools",
    name: "Language Schools",
  },
  {
    id: "Physicians",
    name: "Physicians",
  },
  {
    id: "Courts of Law",
    name: "Courts of Law",
  },
  {
    id: "Correctional Institutions",
    name: "Correctional Institutions",
  },
  {
    id: "Dentists",
    name: "Dentists",
  },
  {
    id: "Fire Protection",
    name: "Fire Protection",
  },
];
