import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import LoaderButton from "@/Components/LoaderButtonBlack";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { Button, Input, Table, Tooltip } from "antd";
import React from "react";
import { useState } from "react";
import { convertNumberStringToNumber } from "./utils";
import { convertDataToTableFormat, getCostString, getTotalItems, tableColumns } from "../CompaniesListViaLinkedin";
import { useNavigate } from "react-router-dom";

interface Props {
  workflowName: string;
  selectedSource: string;
  selectedFolder: string;
}

const SalesNavListBuilder: React.FC<Props> = ({ workflowName, selectedFolder }) => {
  const navigate = useNavigate();
  const { createNewWorkflow, importCrustDataSalesNavData } = useWorkflow();
  const [url, setUrl] = useState<string>("");
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [previewData, setPreviewData] = useState<any[]>([]);
  const [totalItems, setTotalItems] = useState<number>();
  const [previewGenerated, setPreviewGenerated] = useState(false);
  const { getCrustDataSalesNavigatorPreview } = useWorkflow();
  const [error, setError] = useState("");

  const generatePreview = async () => {
    setLoading(true);
    try {
      const { data } = await getCrustDataSalesNavigatorPreview(url);
      if (!data || data["error"]) {
        setError(data["error"] ?? "Something went wrong. Please try again later.");
        setLoading(false);
        return;
      }
      const { total_display_count, companies } = data;

      setPreviewData(companies);
      setTotalItems(convertNumberStringToNumber(total_display_count));
      setPreviewGenerated(true);
      setLoading(false);
    } catch (e) {
      setError((e as Error).message);
      setLoading(false);
    }
  };

  const data = React.useMemo(() => {
    return convertDataToTableFormat(previewData);
  }, [previewData]);

  const importItems = async () => {
    const id = await createNewWorkflow(
      workflowName,
      "comapnies_crustdata_realtime_linkedin_list_url",
      selectedFolder,
      {
        url,
        maxCount: getTotalItems(totalItems || 0, pageCount),
      },
      true
    );

    if (id === "") {
      setError("Failed to create workflow");
      return;
    }

    try {
      await importCrustDataSalesNavData(id, url, getTotalItems(totalItems || 0, pageCount));
    } catch (e) {
      setError((e as Error).message);
    }

    navigate(`/workflow/${id}?v2=${true}`);
  };

  return (
    <div className="py-5 px-10 overflow-hidden flex flex-col h-screen">
      <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-6">
        <div className="flex-1">
          <label htmlFor="searchURL" className="block mb-2 text-xl font-semibold text-black">
            Sales Navigator Search URL
          </label>
          <Input
            placeholder="https://www.linkedin.com/sales/people/293202-johndoe"
            size="large"
            className="h-14"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </div>
        <div className="flex-1">
          <label htmlFor="pageCount" className="block mb-2 text-xl font-semibold text-black">
            Page Count <span className="text-gray-400">(Optional)</span>
          </label>
          <Tooltip
            trigger={["focus"]}
            title={"Number of pages to be extracted from salesnav URL (each page has upto 25 items)"}
            placement="topLeft"
            overlayClassName="numeric-input"
          >
            <Input
              // {...props}
              onChange={(e) => setPageCount(parseInt(e.target.value))}
              value={pageCount}
              placeholder="Input a number"
              max={99}
              min={1}
              className="h-14"
              type="number"
            />
          </Tooltip>
        </div>
      </div>

      <div className="overflow-auto">
        {previewGenerated && (
          <div className="mt-6 ">
            <Table
              bordered
              rowClassName="bg-[#f7fdff] cursor-pointer overflow-y-auto"
              columns={tableColumns}
              dataSource={data}
              pagination={false}
              rowSelection={{ type: "checkbox" }}
              title={() => (
                <span className="text-xl font-semibold bg-[#f7fdff] text-black">
                  Preview of Data {`(${previewData.length} of ${totalItems ?? 0})`}
                </span>
              )}
            />
          </div>
        )}
      </div>

      <div>
        {previewGenerated && (
          <div className="flex flex-row items-center justify-start mt-5">
            <>
              <LoaderButton
                disabled={false}
                error=""
                loaderText="Importing..."
                text={`Import ${getTotalItems(totalItems || 0, pageCount)} Items`}
                onClickCallback={importItems}
                btnClasses="text-white text-md bg-[#4F3FFF] hover:bg-[#4F3FFF] rounded-none"
              />
              <div className="w-[0.5px] h-12 bg-gray-200 mx-4" />
              <span className="text-xl text-[#777777] font-semibold">{getCostString(totalItems || 0, pageCount)}</span>
            </>
          </div>
        )}
      </div>

      <div>
        {!previewGenerated && (
          <div className="mt-5">
            <Button
              onClick={generatePreview}
              disabled={loading || !url}
              type="primary"
              size="large"
              className={`bg-[#4F3FFF] flex flex-row items-center justify-center gap-2 rounded-none text-xl h-12 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-[#4F3FFF] disabled:text-white`}
            >
              {loading && <SpinnerStatus white />}
              {loading ? "Generating Preview..." : "Generate Preview"}
            </Button>
          </div>
        )}
      </div>

      <div>
        <span className="text-red-500 font-semibold">
          {error && "Invalid: "}
          {error}
        </span>
      </div>
    </div>
  );
};

export default SalesNavListBuilder;
