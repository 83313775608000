/*global chrome*/
import { createContext, useContext, useState } from "react";

import {
  copyWorkflowToUserURL,
  createNewWorkflowURL,
  getAllPredefinedWorkflowsURL,
  getDataStoreValueURL,
  markAsReviewedAndMoveOnURL,
  retryWorkflowForDataURL,
  retryActionURL,
  saveWorkflowActionsURL,
  triggerWorkflowForInputURL,
  updateDataForWorkflowRunURL,
  updateGeneratedEmailURL,
  fetchCreditsForOrgIdUrl,
  fetchTransactionsForOrgIdUrl,
  fetchPricingPlansForUserUrl,
  exportWorkflowURL,
  getSfObjectsFieldsURL,
  getSalesforceObjectsURL,
  getSfObjectItemsURL,
  createNewWorkflowFolderURL,
  getSfListViewsURL,
  getSfListViewColumnsURL,
  dedupeRowsCheckURL,
  HTTP_API_CALL_PING_URL,
  SALES_NAVIGATOR_PREVIEW_URL,
  SALES_NAVIGATOR_IMPORT_URL,
  fetchTransactionsForOrgIdWithFiltersUrl,
  saveWorkflowRecordURL,
  retryWorkflowForDataURLV2,
  retryActionURLV2,
  updateDataForWorkflowRunURLV2,
  markAsReviewedAndMoveOnURLV2,
  triggerWorkflowForInputURLV2,
  saveWorkflowActionsURLV2,
  copyWorkflowToUserURLV2,
  createNewWorkflowURLV2,
  exportWorkflowURLV2,
  dedupeRowsCheckURLV2,
  updateGeneratedEmailURLV2,
  HTTP_API_CALL_PING_URLV2,
  saveWorkflowRecordURLV2,
  fetchAllAPIPricingUrl,
  fetchTransactionsForOrgIdWithFilterUnformattedUrl,
  SALES_NAVIGATOR_IMPORT_URLV2,
  SALES_NAVIGATOR_PREVIEW_URLV2,
  getSpreadsheetsURLV2,
  getSpreadsheetsURL,
  CRUSTDATA_SALES_NAVIGATOR_PREVIEW_URL_V2,
  CRUSTDATA_SALES_NAV_IMPORT_URLV2,
  CRUSTDATA_FILTERS_IMPORT_URLV2,
} from "../utils/urls";
import { ancestors, getBrowserTokens, getUpdatedRows, handleUpdateTokens } from "../utils/functions";
import useStateRef from "react-usestateref";
import { ActionResponses, Condition } from "@/utils/interfaces";
import { v4 as uuidv4 } from "uuid";
import { getV2 } from "@/utils/constants";
import { getWorkflowDetailsWithActions } from "@/utils/apis";

// import useStateRef from "react-usestateref";

export const WorkflowContext = createContext({
  actions: [] as any[],
  setActions: (_actions: any[]) => {},
  availableActions: [] as any[],
  publishWarning: false,
  revertWarning: false,
  setPublishWarning: (_publishWarning: boolean) => {},
  setRevertWarning: (_revertWarning: boolean) => {},
  workflowBuildDetails: {} as any,
  setWorkflowBuildDetails: (_workflowBuildDetails: any) => {},
  errorEncountered: false,
  setErrorEncountered: (_workflowBuildDetails: any) => {},
  data: [{} as any],
  setData: (_data: any[]) => {},
  dataRef: { current: [] as any[] },
  userActions: [] as ActionResponses[][],
  setUserActions: (_userActions: ActionResponses[][]) => {},
  refresh: false,
  setRefresh: (_refresh: boolean) => {},
  getAllPredefinedWorkflows: async (): Promise<any[]> => {
    return [{}];
  },
  copyWorkflowToUser: async (_predefinedWorkflowId: string, _workflowName: string): Promise<string> => {
    return "";
  },
  createNewWorkflow: async (
    _name: string,
    _type: string,
    _workflowFolder: string,
    _extra: any = {},
    _v2?: boolean
  ): Promise<string> => {
    return "";
  },
  createNewWorkflowFolder: async (_folderName: string): Promise<string> => {
    return "";
  },
  fetchCreditsForOrgId: async (): Promise<any> => {
    return {};
  },
  fetchAllAPIPricing: async (): Promise<any> => {
    return {};
  },
  fetchTransactionsForOrgId: async (_pageNumber: number, _pageSize: number): Promise<any> => {
    return {};
  },
  fetchTransactionsForOrgIdWithFilters: async (_filters: {
    start_date: string;
    end_date: string;
    actions?: string[];
    workflows?: string[];
    status?: string;
  }): Promise<any> => {
    return {};
  },
  fetchTransactionsForOrgIdWithFiltersUnformatted: async (
    _filters: {
      start_date: string;
      end_date: string;
      actions?: string[];
      workflows?: string[];
      status?: string;
    },
    _pageNumber: number
  ): Promise<any> => {
    return {};
  },
  fetchPricingPlansForUser: async (): Promise<any> => {
    return {};
  },
  getAllWorkflowActionsDetails: async (_workflowId: string, _skipSet?: boolean): Promise<any> => {
    return {};
  },
  updateResponseConfig: (_responses: any[], _actionId: string) => {
    return {} as any;
  },
  updatePayloadConfig: (
    _payload: any[],
    _actionId: string,
    _review: boolean,
    _continueOnFail?: boolean,
    _runCondition?: any
  ) => {
    return {} as any;
  },
  updateActionName: (_actionId: string, _name: string) => {},
  saveWorkflowActions: async (_workflowId: string) => {},
  saveAnotherWorkflowActions: async (_workflowId: string, _actions: any[], _v2: boolean) => {},
  updateNextAction: (_source: string, _target: string | null, _name: string, _conditions: Condition[]) => {},
  addNewPath: (_source: string) => {
    return {} as any;
  },
  markActionAsReviewedAndMoveOn: async (
    _actionNameId: string,
    _userWorkflowId: string,
    _dataIds: string[],
    _currentDataIds: string[],
    _section: number,
    _type: string
  ) => {},
  triggerWorkflowForInput: async (_workflowId: string, _data: any[], _csvInput: boolean, _prevKey: number) => {},
  getSpreadsheets: async (_type: string, _sheetId?: string, _sheetName?: string) => {
    return [];
  },
  retryWorkflowForData: async (_workflowId: string, _dataId: string[], _data: any[], _section: number) => {},
  dedupeRowsCheck: async (_workflowId: string, _section: number, _toDedupeColumnId: string) => {},
  updateGeneratedEmail: async (
    _workflowId: string,
    _dataId: string,
    _actionId: string,
    _responseId: string,
    _updatedValue: string,
    _section: number
  ) => {},
  updateWorkflowRunDataUtil: (_workflowId: string, _dataIds: string[], _section: number) => {},
  updateWorkflowDataForRun: async (_workflowId: string, _dataIds: string[]) => {
    return {} as any;
  },
  exportTableData: async (_userWorkflowId: string, _dataIds: string[], _section: number) => {
    return "";
  },
  retryAction: async (
    _actionNameId: string,
    _userWorkflowId: string,
    _dataIds: string[],
    _currentDataIds: string[],
    _section: number,
    _type: string,
    _filters?: any
  ) => {
    return "";
  },
  getDataStoreValue: async (_key: string) => {
    return {} as any;
  },
  getSalesforceObjects: async () => {
    return [];
  },
  getSfObjectsFields: async (_objectName: string) => {
    return [];
  },
  getSfObjectItems: async (_reference: string) => {
    return [];
  },
  getSfListViews: async (_objectName: string) => {
    return [];
  },
  getSfListViewColumns: async (_objectName: string, _listViewId: string) => {
    return [];
  },
  getVariblesForAction: (_actionId: string, _graph: dagre.graphlib.Graph) => {
    return [] as any[];
  },
  pingHttpApiCall: async (_userWorkflowId: string, _actionNameId: string, _requestBody: any) => {
    return {} as any;
  },
  getSalesNavigatorPreview: async (_url: string, _v2: boolean) => {
    return {} as any;
  },
  getCrustDataSalesNavigatorPreview: async (_url: string) => {
    return {} as any;
  },
  getCrustDataFiltersPreview: async (_filters: any) => {
    return {} as any;
  },
  importSalesNavigatorData: async (
    _userWorkflowId: string,
    _url: string,
    _total: string,
    _maxCount: number | null,
    _v2?: boolean
  ) => {
    return {} as any;
  },
  importCrustDataSalesNavData: async (_userWorkflowId: string, _url: string, _total: number) => {
    return {} as any;
  },
  importCrustDataFiltersData: async (_userWorkflowId: string, _filters: any, _total: number) => {
    return {} as any;
  },
  saveWorkflowRecord: async (_workflowId: string, _data: any, _dataId?: any, _section: number = 0) => {
    return {} as any;
  },
});

// eslint-disable-next-line react-refresh/only-export-components
export const useWorkflow = () => useContext(WorkflowContext);

export default function WorkflowContextProvider({ children }: any) {
  const [_actions, setActions, actionsRef] = useStateRef<any[]>([]);
  const [availableActions, setAvailableActions] = useState<any[]>([]);
  const [data, setData, dataRef] = useStateRef<any[]>([]);
  const [userActions, setUserActions, userActionsRef] = useStateRef<ActionResponses[][]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [publishWarning, setPublishWarning] = useState<boolean>(false);
  const [revertWarning, setRevertWarning] = useState<boolean>(false);
  const [workflowBuildDetails, setWorkflowBuildDetails] = useState<null | any>(null);
  const [errorEncountered, setErrorEncountered] = useState<boolean>(false);

  const updateWorkflowUIUpdate = async (workflowData: any, workflowId: string, section: number) => {
    try {
      const updatedRows =
        (await getUpdatedRows(dataRef.current, workflowData, workflowId, userActionsRef.current[section])) || [];
      const updatedIds = updatedRows.map((row) => row.key);
      let currData = dataRef.current;
      currData = currData.map((row) => {
        if (updatedIds.includes(row.key)) {
          return updatedRows.find((r) => r.key === row.key);
        }
        return row;
      });
      setData(() => currData);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const updateWorkflowRunDataUtil = (workflowId: string, dataIds: string[], section: number) => {
    setRefresh(true);
    updateWorkflowDataForRun(workflowId, dataIds)
      .then((workflowData) => {
        updateWorkflowUIUpdate(workflowData, workflowId, section);
      })
      .finally(() => setRefresh(false))
      .catch((err) => {
        setErrorEncountered(true);
        setTimeout(() => {
          setErrorEncountered(false);
        }, 1000);
        console.error(err);
      });
  };

  const getVariblesForAction = (
    actionId: string,
    // actions: any[],
    graph: dagre.graphlib.Graph
  ): any[] => {
    const preds: string[] = ancestors(graph, actionId).reverse();
    const responses: any[] = [];

    preds.forEach((aid) => {
      const act = actionsRef.current.find((act) => act.id === aid);
      if (!act) return;
      const a: any = {
        name: act.displayName || act.actionDetails.name,
        type: act.actionDetails.type,
        logo: act.actionDetails.logo,
      };
      if (act.actionDetails.outputStructureType === "userDefined") {
        responses.push({
          ...a,
          variables: act.responseConfiguration || [],
        });
      } else {
        const arr: any[] = [];
        const configs: {
          responseId: string;
          responseStructureId: string;
        }[] = act.responseConfiguration || [];
        const structures: {
          name: string;
          description: string;
          responseStructureId: string;
          type: string;
        }[] = act.actionDetails.responseStructure;

        configs.forEach((config: any) => {
          const response: any = config;
          const structure = structures.find((str) => str.responseStructureId === config.responseStructureId);
          arr.push({
            ...response,
            ...structure,
          });
        });
        responses.push({
          ...a,
          variables: arr,
        });
      }
    });
    return responses;
  };

  const getAllPredefinedWorkflows = async (): Promise<any[]> => {
    try {
      const url = getAllPredefinedWorkflowsURL;
      const response = await fetch(url);
      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.error);
      return responseData.workflows;
    } catch (error) {
      console.log(error);
      // throw new Error("Failed to fetch workflows");
      return [];
    }
  };

  const copyWorkflowToUser = async (predefinedWorkflowId: string, workflowName: string): Promise<string> => {
    try {
      const v2 = getV2();
      const url = v2 ? copyWorkflowToUserURLV2 : copyWorkflowToUserURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          predefinedWorkflowId,
          workflowName,
        }),
      };
      const response = await fetch(url, options);
      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.error);
      handleUpdateTokens(responseData, accessToken, refreshToken);
      return responseData.newWorkflowId;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to copy workflow");
    }
  };

  const createNewWorkflow = async (
    name: string,
    type: string,
    workflowFolder: string,
    extra: any = {},
    v2?: boolean
  ): Promise<string> => {
    try {
      const url = v2 ? createNewWorkflowURLV2 : createNewWorkflowURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          name,
          type: type,
          workflowFolder,
          extraData: extra,
        }),
      };
      const response = await fetch(url, options);
      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.error);
      handleUpdateTokens(responseData, accessToken, refreshToken);
      return responseData.workflowId;
    } catch (error: any) {
      console.log(error);
      if (error.message.includes("connection not found")) return error.message;
      return "";
    }
  };

  const createNewWorkflowFolder = async (folderName: string): Promise<string> => {
    try {
      const url = createNewWorkflowFolderURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          folderName,
        }),
      };
      const response = await fetch(url, options);
      const responseData = await response.json();
      handleUpdateTokens(responseData, accessToken, refreshToken);
      if (!response.ok) throw new Error(responseData.error);
      return responseData.workflowFolderId;
    } catch (error: any) {
      console.log(error);
      if (error.message === "Hubspot connection not found") return "No Hubspot connection found";
      else if (error.message === "Salesforce connection not found") return "No Salesforce connection found";
      return "";
    }
  };

  const fetchTransactionsForOrgId = async (pageNumber: number, pageSize: number): Promise<any[]> => {
    try {
      const url = fetchTransactionsForOrgIdUrl;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        headers: {
          accessToken,
          refreshToken,
        },
      };
      const response = await fetch(
        `${url}?
        pageNumber=${pageNumber}&pageSize=${pageSize}
        `,
        options
      );
      const responseData = await response.json();
      handleUpdateTokens(responseData, accessToken, refreshToken);
      if (!response.ok) throw new Error(responseData.error);
      return responseData;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch transaction");
    }
  };

  const fetchTransactionsForOrgIdWithFilters = async (filters: {
    start_date: string;
    end_date: string;
    actions?: string[];
    workflows?: string[];
    status?: string;
  }): Promise<any[]> => {
    try {
      const url = fetchTransactionsForOrgIdWithFiltersUrl;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options: any = {
        headers: {
          accessToken,
          refreshToken,
          start_date: filters.start_date,
          end_date: filters.end_date,
        },
      };

      if (filters?.actions?.length) options.headers.actions = JSON.stringify(filters.actions);
      if (filters?.workflows?.length) options.headers.workflows = JSON.stringify(filters.workflows);
      if (filters?.status) options.headers.status = filters.status;

      const response = await fetch(url, options);
      const responseData = await response.json();
      handleUpdateTokens(responseData, accessToken, refreshToken);
      if (!response.ok) throw new Error(responseData.error);
      return responseData;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch transaction");
    }
  };

  const fetchTransactionsForOrgIdWithFiltersUnformatted = async (
    filters: {
      start_date: string;
      end_date: string;
      actions?: string[];
      workflows?: string[];
      status?: string;
    },
    pageNumber: number
  ): Promise<any[]> => {
    try {
      const url = fetchTransactionsForOrgIdWithFilterUnformattedUrl;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options: any = {
        headers: {
          accessToken,
          refreshToken,
          start_date: filters.start_date,
          end_date: filters.end_date,
        },
      };

      if (filters?.actions?.length) options.headers.actions = JSON.stringify(filters.actions);
      if (filters?.workflows?.length) options.headers.workflows = JSON.stringify(filters.workflows);
      if (filters?.status) options.headers.status = filters.status;

      const response = await fetch(`${url}?pageNumber=${pageNumber}&pageSize=${1000}`, options);
      const responseData = await response.json();
      handleUpdateTokens(responseData, accessToken, refreshToken);
      if (!response.ok) throw new Error(responseData.error);
      return responseData;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch unformatted transaction");
    }
  };

  const fetchPricingPlansForUser = async (): Promise<any[]> => {
    try {
      const url = fetchPricingPlansForUserUrl;
      const response = await fetch(url);
      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.error);
      return responseData;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to copy workflow");
    }
  };
  const fetchCreditsForOrgId = async (): Promise<any[]> => {
    try {
      const url = fetchCreditsForOrgIdUrl;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        headers: {
          accessToken,
          refreshToken,
        },
      };
      const response = await fetch(url, options);
      const responseData = await response.json();
      handleUpdateTokens(responseData, accessToken, refreshToken);
      if (!response.ok) throw new Error(responseData.error);
      return responseData;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to copy workflow");
    }
  };

  const fetchAllAPIPricing = async (): Promise<any[]> => {
    try {
      const url = fetchAllAPIPricingUrl;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        headers: {
          accessToken,
          refreshToken,
        },
      };
      const response = await fetch(url, options);
      const responseData = await response.json();
      handleUpdateTokens(responseData, accessToken, refreshToken);
      if (!response.ok) throw new Error(responseData.error);
      return responseData;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch api pricing");
    }
  };

  const getAllWorkflowActionsDetails = async (userWorkflowId: string, skipSet: boolean = false): Promise<any> => {
    try {
      setPublishWarning(false);
      const v2 = getV2();
      const responseData = await getWorkflowDetailsWithActions(userWorkflowId, v2);
      if (!skipSet) {
        setWorkflowBuildDetails(responseData.response.workflowDetails);
        setActions(responseData.response.actions);
        setAvailableActions(responseData.response.availableActions);
      }
      return responseData.response;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch workflow");
    }
  };

  const exportTableData = async (userWorkflowId: string, dataIds: string[], section: number): Promise<any> => {
    try {
      const v2 = getV2();
      const url = v2 ? exportWorkflowURLV2 : exportWorkflowURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          dataIds,
          section,
        }),
      };
      const res = await fetch(`${url}/${userWorkflowId}`, options);
      const responseData = await res.json();
      if (!res.ok) throw new Error(responseData.error);
      handleUpdateTokens(responseData, accessToken, refreshToken);
      return responseData.fileUrl;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch workflow");
    }
  };

  const updateResponseConfig = (responses: any[], actionId: string) => {
    const actionIndex = actionsRef.current.findIndex((act) => act.id === actionId);
    const updatedConfiguration = responses.map((config) => ({
      responseId: config.responseId || uuidv4(), // Keep existing ID or generate a new one if it's not present
      ...config,
    }));

    const updatedActions = [...actionsRef.current];
    updatedActions[actionIndex].responseConfiguration = updatedConfiguration;

    setActions(updatedActions);
    return updatedConfiguration;
  };

  const updatePayloadConfig = (
    payload: any[],
    actionId: string,
    review: boolean,
    continueOnFail = false,
    runCondition?: any
  ) => {
    const actionIndex = actionsRef.current.findIndex((act) => act.id === actionId);
    const updatedActions = [...actionsRef.current];
    updatedActions[actionIndex].payloadConfiguration = payload;
    updatedActions[actionIndex].reviewNeeded = review;
    updatedActions[actionIndex].continueOnFail = continueOnFail;
    if (runCondition) updatedActions[actionIndex].runCondition = runCondition;

    setActions(updatedActions);
    return payload;
  };

  const updateNextAction = (source: string, target: string | null, name: string, conditions: Condition[]) => {
    const sourceIndex = actionsRef.current.findIndex((act) => act.id === source);
    const targetIndex = actionsRef.current.findIndex((act) => act.id === target);
    const updatedActions = [...actionsRef.current];
    updatedActions[sourceIndex].nextAction =
      Array.isArray(updatedActions[sourceIndex].nextAction) &&
      updatedActions[sourceIndex].nextAction
        .map((act: any) => {
          if (!act)
            return {
              actionId: null,
              name,
              conditions,
            };
          else if ((typeof act === "string" && act === target) || act.actionId === target)
            return {
              actionId: target,
              name,
              conditions,
            };
          return act;
        })
        // Remove duplicates
        .filter((obj1: any, i: number, arr: any[]) => arr.findIndex((obj2) => obj2.actionId === obj1.actionId) === i);
    if (targetIndex !== -1)
      updatedActions[targetIndex].prevAction =
        Array.isArray(updatedActions[targetIndex].prevAction) &&
        updatedActions[targetIndex].prevAction
          .map((act: any) => {
            if (!act)
              return {
                actionId: null,
                name,
                conditions,
              };
            else if ((typeof act === "string" && act === source) || act.actionId === source)
              return {
                actionId: source,
                name,
                conditions,
              };
            return act;
          })
          // Remove duplicates
          .filter((obj1: any, i: number, arr: any[]) => arr.findIndex((obj2) => obj2.actionId === obj1.actionId) === i);
    setActions(updatedActions);
  };

  const addNewPath = (source: string) => {
    const sourceIndex = actionsRef.current.findIndex((act) => act.id === source);
    const updatedActions = [...actionsRef.current];
    if (updatedActions[sourceIndex].nextAction.some((act: any) => !act || !act.actionId)) return null;
    updatedActions[sourceIndex].nextAction.push({
      actionId: null,
      name: "Conditional Path",
      conditions: [],
    });
    setActions(updatedActions);
    return updatedActions[sourceIndex].nextAction;
  };

  const updateActionName = (actionId: string, name: string) => {
    const actionIndex = actionsRef.current.findIndex((act) => act.id === actionId);
    const updatedActions = [...actionsRef.current];
    updatedActions[actionIndex].displayName = name;

    setActions(updatedActions);
  };

  const saveWorkflowActions = async (userWorkflowId: string): Promise<any> => {
    try {
      const v2 = getV2();
      const url = v2 ? saveWorkflowActionsURLV2 : saveWorkflowActionsURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          actions: actionsRef.current,
        }),
      };
      const response = await fetch(`${url}/${userWorkflowId}`, options);
      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.error);
      handleUpdateTokens(responseData, accessToken, refreshToken);
    } catch (error) {
      console.log(error);
      throw new Error("Failed to save workflow actions");
    }
  };

  const saveAnotherWorkflowActions = async (userWorkflowId: string, actions: any[], v2: boolean): Promise<any> => {
    try {
      const url = v2 ? saveWorkflowActionsURLV2 : saveWorkflowActionsURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          actions,
        }),
      };
      const response = await fetch(`${url}/${userWorkflowId}`, options);
      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.error);
      handleUpdateTokens(responseData, accessToken, refreshToken);
    } catch (error) {
      console.log(error);
      throw new Error("Failed to save workflow actions");
    }
  };

  const markActionAsReviewedAndMoveOn = async (
    actionNameId: string,
    userWorkflowId: string,
    dataIds: string[],
    currentDataIds: string[],
    section: number,
    type: string
  ) => {
    try {
      const v2 = getV2();
      const url = v2 ? markAsReviewedAndMoveOnURLV2 : markAsReviewedAndMoveOnURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          actionNameId,
          dataIds,
          type,
          section,
        }),
      };
      const res = await fetch(`${url}/${userWorkflowId}`, options);
      const responseData = await res.json();
      if (!res.ok) throw new Error(`Failed to mark action as reviewed --> ${JSON.stringify(responseData.error)}`);

      handleUpdateTokens(responseData, accessToken, refreshToken);

      updateWorkflowRunDataUtil(userWorkflowId, currentDataIds, section);
    } catch (err) {
      console.error(err);
    }
  };

  const triggerWorkflowForInput = async (workflowId: string, data: any[], csvInput: boolean, prevKey: number) => {
    try {
      const v2 = getV2();
      const url = v2 ? triggerWorkflowForInputURLV2 : triggerWorkflowForInputURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          data: data,
        }),
      };
      const response = await fetch(`${url}/${workflowId}`, options);
      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.error);
      handleUpdateTokens(responseData, accessToken, refreshToken);
      if (csvInput) return;
      // return responseData.newPayloadConfiguration;
      let temp = dataRef.current;
      temp = temp.map((itr) => {
        if (itr.key === prevKey) itr.key = responseData.newDataIds[0];
        return itr;
      });
      setData(() => temp);
      updateWorkflowRunDataUtil(workflowId, responseData.newDataIds || [], 0);
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch workflow");
    }
  };

  const retryWorkflowForData = async (workflowId: string, dataIds: string[], data: any[], section: number) => {
    try {
      const v2 = getV2();
      const url = v2 ? retryWorkflowForDataURLV2 : retryWorkflowForDataURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          dataIds,
          data: data,
          section: section,
        }),
      };
      const response = await fetch(`${url}/${workflowId}`, options);
      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.error);
      handleUpdateTokens(responseData, accessToken, refreshToken);
      updateWorkflowRunDataUtil(workflowId, dataIds, section);
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch workflow");
    }
  };
  const dedupeRowsCheck = async (workflowId: string, section: number, toDedupeColumnId: string) => {
    try {
      const v2 = getV2();
      const url = v2 ? dedupeRowsCheckURLV2 : dedupeRowsCheckURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          section: section,
          toDedupeColumnId: toDedupeColumnId,
        }),
      };
      const response = await fetch(`${url}/${workflowId}`, options);
      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.error);
      handleUpdateTokens(responseData, accessToken, refreshToken);
      return responseData.response;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch workflow");
    }
  };

  const getSpreadsheets = async (type: string, sheetId?: string, sheetName?: string) => {
    try {
      const v2 = getV2();
      const url = v2 ? getSpreadsheetsURLV2 : getSpreadsheetsURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        headers: {
          accessToken,
          refreshToken,
        },
      };
      switch (type) {
        case "google_spreadsheets":
          if (!type) throw new Error("Type is required to fetch spreadsheets");
          const spreadsheets = await fetch(`${url}/${type}`, options);
          const spreadsheetsData = await spreadsheets.json();

          if (!spreadsheets.ok) throw new Error(spreadsheetsData.error);
          handleUpdateTokens(spreadsheetsData, accessToken, refreshToken);
          return spreadsheetsData.spreadsheets;

        case "google_spreadsheet_sheets":
          if (!type || !sheetId) throw new Error("Sheet ID is required to fetch sheets");
          const inSheets = await fetch(`${url}/${type}/${sheetId}`, options);
          const inSheetsData = await inSheets.json();

          if (!inSheets.ok) throw new Error(inSheetsData.error);
          handleUpdateTokens(inSheetsData, accessToken, refreshToken);
          return inSheetsData.spreadsheets;

        case "google_sheet_columns":
          if (!type || !sheetId || !sheetName) throw new Error("Sheet ID and Sheet Name is required to fetch columns");
          const sheetColumns = await fetch(`${url}/${type}/${sheetId}/${sheetName}`, options);
          const columnsData = await sheetColumns.json();

          if (!sheetColumns.ok) throw new Error(columnsData.error);
          handleUpdateTokens(columnsData, accessToken, refreshToken);
          return columnsData.spreadsheets;

        default:
          const response = await fetch(`${url}/${type}`, options);
          const responseData = await response.json();

          if (!response.ok) throw new Error(responseData.error);
          handleUpdateTokens(responseData, accessToken, refreshToken);
          return responseData.spreadsheets;
      }
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch campaigns");
    }
  };

  const updateGeneratedEmail = async (
    workflowId: string,
    dataId: string,
    actionId: string,
    responseId: string,
    updatedValue: string,
    section: number
  ) => {
    try {
      const v2 = getV2();
      const url = v2 ? updateGeneratedEmailURLV2 : updateGeneratedEmailURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          dataId,
          actionId,
          responseId,
          updatedValue,
        }),
      };
      const res = await fetch(`${url}/${workflowId}`, options);
      const data = await res.json();
      if (!res.ok) throw new Error(data.error || "Error updating email text");
      handleUpdateTokens(data, accessToken, refreshToken);
      // return true;
      await updateWorkflowUIUpdate(data.updatedWorkflowData, workflowId, section);
    } catch (err) {
      console.error(err);
      // return false;
    }
  };

  const updateWorkflowDataForRun = async (workflowId: string, dataIds: string[]) => {
    try {
      const v2 = getV2();
      const url = v2 ? updateDataForWorkflowRunURLV2 : updateDataForWorkflowRunURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          dataIds,
        }),
      };
      const res = await fetch(`${url}/${workflowId}`, options);
      const data = await res.json();

      if (!res.ok) throw new Error(data.error || "Error updating workflow data");
      handleUpdateTokens(data, accessToken, refreshToken);
      return data.updatedWorkflowData || {};
    } catch (err) {
      console.error(err);
      setErrorEncountered(true);

      setTimeout(() => {
        setErrorEncountered(false);
      }, 1000);
    }
  };

  const retryAction = async (
    actionNameId: string,
    userWorkflowId: string,
    dataIds: string[],
    currentDataIds: string[],
    section: number,
    type: string,
    filters: any = {}
  ) => {
    try {
      const v2 = getV2();
      const url = v2 ? retryActionURLV2 : retryActionURL;
      const [accessToken, refreshToken] = getBrowserTokens();

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          actionNameId,
          dataIds,
          type,
          section,
          filters,
        }),
      };

      const res = await fetch(`${url}/${userWorkflowId}`, options);
      const data = await res.json();

      if (!res.ok) throw new Error(data.error || "Error updating workflow data");
      handleUpdateTokens(data, accessToken, refreshToken);

      updateWorkflowRunDataUtil(userWorkflowId, currentDataIds, section);
      return data.regeneratedEmail || "";
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getDataStoreValue = async (key: string) => {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        headers: {
          accessToken,
          refreshToken,
        },
      };
      const res = await fetch(`${getDataStoreValueURL}/${key}`, options);
      const data = await res.json();
      handleUpdateTokens(data, accessToken, refreshToken);

      if (!res.ok) throw new Error(data.error || "Error updating workflow data");

      return data.response || {};
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getSalesforceObjects = async () => {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        headers: {
          accessToken,
          refreshToken,
        },
      };
      const res = await fetch(getSalesforceObjectsURL, options);
      const data = await res.json();

      if (!res.ok) throw new Error(data.error || "Error updating workflow data");
      handleUpdateTokens(data, accessToken, refreshToken);

      return data || [];
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getSfObjectsFields = async (objectName: string) => {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        headers: {
          accessToken,
          refreshToken,
        },
      };
      const res = await fetch(`${getSfObjectsFieldsURL}/${objectName}`, options);
      const data = await res.json();

      if (!res.ok) throw new Error(data.error || "Error updating workflow data");
      handleUpdateTokens(data, accessToken, refreshToken);

      return data.sfObjectsFields || [];
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getSfObjectItems = async (reference: string) => {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        headers: {
          accessToken,
          refreshToken,
        },
      };
      const res = await fetch(`${getSfObjectItemsURL}/${reference}`, options);
      const data = await res.json();

      if (!res.ok) throw new Error(data.error || "Error updating workflow data");
      handleUpdateTokens(data, accessToken, refreshToken);

      return data.sfObjectItems || [];
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getSfListViews = async (objectName: string) => {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        headers: {
          accessToken,
          refreshToken,
        },
      };
      const res = await fetch(`${getSfListViewsURL}/${objectName}`, options);
      const data = await res.json();

      if (!res.ok) throw new Error(data.error || "Error updating workflow data");
      handleUpdateTokens(data, accessToken, refreshToken);

      return data.sfListViews.listviews || [];
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getSfListViewColumns = async (objectName: string, listViewId: string) => {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        headers: {
          accessToken,
          refreshToken,
        },
      };
      const res = await fetch(
        `${getSfListViewColumnsURL}/${objectName}
        ?listViewId=${listViewId}
        `,
        options
      );
      const data = await res.json();

      if (!res.ok) throw new Error(data.error || "Error updating workflow data");
      handleUpdateTokens(data, accessToken, refreshToken);
      return data.sfListViewColumns || [];
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const pingHttpApiCall = async (userWorkflowId: string, actionNameId: string, requestBody: any) => {
    try {
      const v2 = getV2();
      const url = v2 ? HTTP_API_CALL_PING_URLV2 : HTTP_API_CALL_PING_URL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          userWorkflowId,
          actionNameId,
          ...requestBody,
        }),
      };
      const res = await fetch(url, options);
      const data = await res.json();

      if (!res.ok) throw new Error(data.error || "Error updating workflow data");
      handleUpdateTokens(data, accessToken, refreshToken);
    } catch (err) {
      return (err as any).message;
    }
  };

  const getSalesNavigatorPreview = async (url: string, v2: boolean) => {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          url,
        }),
      };
      const res = await fetch(v2 ? SALES_NAVIGATOR_PREVIEW_URLV2 : SALES_NAVIGATOR_PREVIEW_URL, options);
      const data = await res.json();
      if (!res.ok) throw new Error(data.error || "Error getting preview data");
      handleUpdateTokens(data, accessToken, refreshToken);
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getCrustDataSalesNavigatorPreview = async (url: string) => {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          salesNavigatorUrl: url,
        }),
      };
      const res = await fetch(CRUSTDATA_SALES_NAVIGATOR_PREVIEW_URL_V2, options);
      const data = await res.json();
      if (!res.ok) throw new Error(data.error || "Error getting preview data");
      handleUpdateTokens(data, accessToken, refreshToken);
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getCrustDataFiltersPreview = async (filters: string) => {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          filters,
        }),
      };
      const res = await fetch(CRUSTDATA_SALES_NAVIGATOR_PREVIEW_URL_V2, options);
      const data = await res.json();
      if (!res.ok) throw new Error(data.error || "Error getting preview data");
      handleUpdateTokens(data, accessToken, refreshToken);
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const importSalesNavigatorData = async (
    userWorkflowId: string,
    url: string,
    total: string,
    maxCount: number | null,
    v2?: boolean
  ) => {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          userWorkflowId,
          url,
          total,
          count: maxCount,
        }),
      };
      const res = await fetch(v2 ? SALES_NAVIGATOR_IMPORT_URLV2 : SALES_NAVIGATOR_IMPORT_URL, options);
      const data = await res.json();
      handleUpdateTokens(data, accessToken, refreshToken);
      if (!res.ok) throw new Error(data.error || "Error importing data");
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const importCrustDataSalesNavData = async (userWorkflowId: string, url: string, total: number) => {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          userWorkflowId,
          url,
          total,
        }),
      };
      const res = await fetch(CRUSTDATA_SALES_NAV_IMPORT_URLV2, options);
      const data = await res.json();
      handleUpdateTokens(data, accessToken, refreshToken);
      if (!res.ok) throw new Error(data.error || "Error importing data");
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const importCrustDataFiltersData = async (userWorkflowId: string, filters: any, total: number) => {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          userWorkflowId,
          filters,
          total,
        }),
      };
      const res = await fetch(CRUSTDATA_FILTERS_IMPORT_URLV2, options);
      const data = await res.json();
      handleUpdateTokens(data, accessToken, refreshToken);
      if (!res.ok) throw new Error(data.error || "Error importing data");
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const saveWorkflowRecord = async (workflowId: string, data: any, dataId?: any, section: number = 0) => {
    try {
      const v2 = getV2();
      const url = v2 ? saveWorkflowRecordURLV2 : saveWorkflowRecordURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          data,
          dataId,
          section,
        }),
      };
      const res = await fetch(`${url}/${workflowId}`, options);
      const responseData = await res.json();
      handleUpdateTokens(responseData, accessToken, refreshToken);
      // updateWorkflowRunDataUtil(workflowId, [responseData.dataId], 0);
      if (!res.ok) throw new Error(responseData.error);
      return responseData;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to save record");
    }
  };

  const value = {
    actions: actionsRef.current,
    setActions,
    availableActions,
    publishWarning,
    setPublishWarning,
    revertWarning,
    setRevertWarning,
    workflowBuildDetails,
    setWorkflowBuildDetails,
    data,
    setData: (data: any[]) => setData(() => data),
    dataRef,
    errorEncountered,
    setErrorEncountered,
    userActions,
    setUserActions: (userActions: ActionResponses[][]) => setUserActions(userActions),
    refresh,
    setRefresh: (refresh: boolean) => setRefresh(refresh),
    getAllPredefinedWorkflows,
    copyWorkflowToUser,
    createNewWorkflow,
    createNewWorkflowFolder,
    fetchCreditsForOrgId,
    fetchAllAPIPricing,
    fetchTransactionsForOrgId,
    fetchTransactionsForOrgIdWithFilters,
    fetchTransactionsForOrgIdWithFiltersUnformatted,
    fetchPricingPlansForUser,
    getAllWorkflowActionsDetails,
    updateResponseConfig,
    updatePayloadConfig,
    updateNextAction,
    addNewPath,
    updateActionName,
    saveWorkflowActions,
    saveAnotherWorkflowActions,
    markActionAsReviewedAndMoveOn,
    triggerWorkflowForInput,
    retryWorkflowForData,
    retryAction,
    exportTableData,
    updateGeneratedEmail,
    updateWorkflowRunDataUtil,
    updateWorkflowDataForRun,
    getDataStoreValue,
    getSalesforceObjects,
    getSfObjectsFields,
    getSfObjectItems,
    getSfListViews,
    getSfListViewColumns,
    getVariblesForAction,
    dedupeRowsCheck,
    pingHttpApiCall,
    getSalesNavigatorPreview,
    getCrustDataSalesNavigatorPreview,
    getCrustDataFiltersPreview,
    importSalesNavigatorData,
    importCrustDataSalesNavData,
    importCrustDataFiltersData,
    saveWorkflowRecord,
    getSpreadsheets,
  };

  return <WorkflowContext.Provider value={value}>{children}</WorkflowContext.Provider>;
}
