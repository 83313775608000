import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useWorkflow } from "@/contexts/WorkflowContext";
import LoaderButton from "@/Components/LoaderButtonBlack";
import { useNavigate } from "react-router-dom";
import { FaX } from "react-icons/fa6";
import { operatorsFilters } from "./Components/operatorsFilters";
import { IFiltersProps } from "./Components/filter.schema";
import "./Components/Filters.css";
import SearchFilterBase from "./Components/AppliedFilters";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { filtersToParams } from "./Components/utils";
import { importWelldbOperators } from "@/utils/apis";

interface SalesNavigatorProps {
  workflowName: string;
  selectedSource: string;
  selectedFolder: string;
  stepsClose: React.Dispatch<React.SetStateAction<boolean>>;
  close: React.Dispatch<React.SetStateAction<boolean>>;
  v2?: boolean;
}

const OperatorsListComponent = ({
  workflowName,
  selectedSource,
  selectedFolder,
  stepsClose,
  close,
  v2,
}: SalesNavigatorProps): React.JSX.Element => {
  const navigate = useNavigate();
  const { createNewWorkflow } = useWorkflow();
  const [appliedFilters, setAppliedFilters] = useState<IFiltersProps[]>([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const missingPermanentFilters = operatorsFilters
      .filter((filter) => filter.is_permanent)
      .map((filter) => ({ ...filter, operator: filter.availableOperators[0] }));
    setAppliedFilters(missingPermanentFilters);
  }, []);

  const onRemoveFilter = (index: number) => {
    const newFilters = [...appliedFilters];
    newFilters.splice(index, 1);
    setAppliedFilters(newFilters);
  };

  const onChangeFilter = (index: number, filter: IFiltersProps) => {
    const newFilters = [...appliedFilters];
    newFilters[index] = filter;
    newFilters[index].hasChanged = true;
    setAppliedFilters(newFilters);
  };

  const isFilterRemovable = (filter: IFiltersProps) => {
    const exist_job_filter = appliedFilters.some(
      (f) => f.id.startsWith("job_filters.") && f.id !== "job_filters.posted_at"
    );
    if (filter.id === "job_filters.posted_at" && exist_job_filter) {
      return false;
    }
    return true;
  };

  const isFilterPermanent = (filter: IFiltersProps, index: number, appliedFilters: IFiltersProps[]) => {
    if (filter.is_permanent) {
      const firstOcurrenceSameId = appliedFilters.findIndex((appliedFilter) => appliedFilter.id === filter.id);
      if (firstOcurrenceSameId == index) return true;
    }
    return false;
  };

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <div className="flex flex-col overflow-hidden h-screen">
        <div className="flex flex-row w-full items-center justify-between border-b py-3 px-10">
          <button
            onClick={() => close(false)}
            className="h-14 rounded-2xl self-start font-bold color-black z-10 px-6 py-2 bg-white flex items-center justify-center gap-4 hover:bg-[#eeedfe] transition cursor-pointer border-2 border-[#eeedfe]"
          >
            <MdOutlineKeyboardBackspace size={30} />
            <span>Back</span>
          </button>
          <FaX
            className="w-5 h-5 text-red-500 hover:text-red-700 cursor-pointer transition-all duration-300 ease-in-out"
            onClick={() => stepsClose(false)}
          />
        </div>
        <div className="flex flex-col space-y-6 overflow-y-auto h-full">
          <div className="flex flex-col gap-6 px-10 py-5">
            <p className="text-xl font-semibold">Add the filters to refine your operators search</p>
            <div className="flex flex-wrap items-center gap-2">
              {appliedFilters.map((filter, index) => (
                <SearchFilterBase
                  key={index}
                  index={index}
                  filter={filter}
                  changeFilter={onChangeFilter}
                  removeFilter={onRemoveFilter}
                  appliedFilters={appliedFilters}
                  isRemovable={isFilterRemovable(filter)}
                  isPermanent={isFilterPermanent(filter, index, appliedFilters)}
                  hasChanged={filter.hasChanged || false}
                />
              ))}
            </div>
          </div>

          {
            <div className="text-black px-10">
              <p className="font-bold">When using this source, please keep in mind:</p>
              <ul className="list-disc list-inside mt-3 text-lg">
                {/* put some bullet points
                like: all filters are required, */}
                <li>
                  Well Database <span className="font-semibold">API Key</span> is required to use this source. Please
                  make sure you have it before proceeding.
                </li>
                <li>
                  All the filters are <span className="font-semibold">required</span>. So, please make sure to fill in
                  all the filters that you want to use.
                </li>
                <li>
                  After you are satisfied with the filters, click on{" "}
                  <span className="font-semibold">Import Operators</span> to see the operators that match your criteria.
                </li>
              </ul>
            </div>
          }
        </div>

        <div className="flex flex-row items-center justify-between w-full bg-white p-4 border-t border-gray-200 min-h-[80px]">
          {/* {previewGenerated && ( */}
          <div className="flex flex-row items-center justify-between">
            <>
              <LoaderButton
                disabled={false}
                error=""
                loaderText="Importing..."
                text={`Import Items`}
                onClickCallback={async () => {
                  const id = await createNewWorkflow(
                    workflowName,
                    selectedSource,
                    selectedFolder,
                    {
                      filters: filtersToParams(appliedFilters, []),
                    },
                    v2
                  );
                  if (id === "" || id === "Well Database connection not found")
                    return setError(
                      id
                        ? id + ". Please go to connections page and add your Well Database API Key"
                        : "Failed to create workflow"
                    );
                  try {
                    await importWelldbOperators(id, filtersToParams(appliedFilters, []), v2);
                  } catch (e) {
                    setError((e as Error).message);
                  }
                  if (v2) navigate(`/workflow/${id}?v2=${v2}`);
                  else navigate(`/workflow/${id}`);
                }}
                btnClasses="text-white text-md bg-[#4F3FFF] hover:bg-[#4F3FFF] rounded-none"
              />
            </>
          </div>
          <span className="text-red-500 font-semibold">
            {error && "Invalid: "}
            {error}
          </span>
        </div>
      </div>
    </QueryClientProvider>
  );
};

export default OperatorsListComponent;
