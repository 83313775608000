import { Button, ConfigProvider, Drawer, Select, Skeleton } from "antd";
import { useEffect, useMemo, useState } from "react";
import { Action, PayloadConfiguration, PayloadStructure, ResponseConfiguration } from "../../../utils/interfaces";
import { ENGAGEBAY_SYSTEM_FIELDS, getEndpointFromNameAndId, setStateType } from "../../../utils/constants";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import { useParams } from "react-router-dom";
import { FaCheck, FaPlus, FaRegCopy } from "react-icons/fa";
import PingViewer from "../Modals/PingViewer/PingViewer";
import { MdDeleteOutline } from "react-icons/md";
import { IoIosRefresh } from "react-icons/io";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { getCampaigns } from "@/utils/apis";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  responseConfiguration: ResponseConfiguration[];
  payloadConfiguration: PayloadConfiguration[];
};

const InputModal = ({ action, modal, setModal, responseConfiguration, payloadConfiguration }: Props) => {
  const [loading, setLoading] = useState(false);
  const [campaignsLoading, setCampaignsLoading] = useState<Record<string, boolean>>({});
  const [campaigns, setCampaigns] = useState<Record<string, any[]>>({});
  const [responses, setResponses] = useState<ResponseConfiguration[]>(responseConfiguration);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const [isCopied, setIsCopied] = useState(false);
  const types = useMemo<string[]>(
    () =>
      action.payloadStructure
        .filter((field) => field.type.includes("dynamicDropdown"))
        .map((field) => field.valuesId ?? ""),
    [action.payloadStructure]
  );
  const [ping, setPing] = useState<any>({
    loading: false,
    data: action.ping,
  });

  const {
    updateResponseConfig,
    updatePayloadConfig,
    saveWorkflowActions,
    setPublishWarning,
    getAllWorkflowActionsDetails,
  } = useWorkflow();
  const { id } = useParams();

  useEffect(() => {
    if (types.length === 0) return;
    types.forEach((type) => {
      setCampaignsLoading((prev) => ({ ...prev, [type]: true }));
      getCampaigns(type)
        .then((data: any[]) => {
          setCampaigns((prev) => ({ ...prev, [type]: data }));
        })
        .catch((error) => {
          console.log(error);
          setCampaigns((prev) => ({ ...prev, [type]: [] }));
        })
        .finally(() => {
          setCampaignsLoading((prev) => ({ ...prev, [type]: false }));
        });
    });
  }, [types]);

  const saveOutputStructure = async () => {
    const filteredResponses = responses?.filter((res) => {
      return !!res?.name;
    });
    setLoading(true);
    const updated = updateResponseConfig(filteredResponses, action.id);
    updatePayloadConfig(payloads, action.id, false);
    setResponses(updated);
    await saveWorkflowActions(id || "");
    setPublishWarning(true);
    setLoading(false);
    setModal(false);
  };

  const refreshPing = async () => {
    setPing({ ...ping, loading: true });
    const data = await getAllWorkflowActionsDetails(id || "");
    setPing({
      loading: false,
      data: data?.actions?.find((act: any) => act.id === action.id)?.ping || undefined,
    });
  };

  const addFields = (keys: string[]) => {
    setResponses((prev) =>
      prev.concat(
        keys.map((key) => {
          const name = key.split(".");
          return {
            name: name[name.length - 1],
            description: "",
            responseStructureId: key,
          };
        })
      )
    );
  };

  const addField = (key: string) => {
    const temp = [...responses];
    const name = key.split(".");
    temp.push({
      name: name[name.length - 1],
      description: "",
      responseStructureId: key,
    });
    setResponses(temp);
  };

  const handleAddFields = () => {
    // Accumulate all keys in a single array
    const keysToAdd: string[] = [];
    // Recursive function to traverse and collect keys
    const collectKeys = (data: any, prefix = "") => {
      Object.entries(data).forEach(([key, value]) => {
        const currentKey = prefix ? `${prefix}.${key}` : key;
        // Add the current key
        keysToAdd.push(currentKey);
        if (Array.isArray(value)) {
          // For arrays, include the index as part of the key
          value.forEach((item, index) => {
            const arrayKey = `${currentKey}.${index}`;
            keysToAdd.push(arrayKey); // Add the array index key
            if (item && typeof item === "object") {
              // Recurse for objects within arrays
              collectKeys(item, arrayKey);
            }
          });
        } else if (value && typeof value === "object") {
          // Recurse for nested objects
          collectKeys(value, currentKey);
        }
      });
    };
    // Collect all keys from ping.data
    collectKeys(ping.data);
    // Call addFields once with the accumulated keys
    addFields(keysToAdd);
  };

  const handlePaste = (e: any, index: number, field: string) => {
    e.preventDefault(); // Prevent the default paste behavior
    const pastedData = e.clipboardData.getData("text"); // Get clipboard data

    // Split the pasted data by new lines (assuming each line is a row)
    const lines = pastedData.split("\t").filter((line: any) => line.trim() !== ""); // Avoid empty lines

    const updatedRows: any[] = [...responses];

    // Update the rows starting from the pasted input's index
    lines.forEach((line: any, lineIndex: number) => {
      if (index + lineIndex < responses.length) {
        // Update existing rows
        updatedRows[index + lineIndex][field] = line;
      } else {
        // Add new rows if needed
        const newRow: any = { name: "", description: "" };
        newRow[field] = line;
        updatedRows.push(newRow);
      }
    });

    setResponses(updatedRows);
  };

  // function to return code for "fixed" type fields
  const getFixedCode = (field: PayloadStructure) => {
    const includeNames = ["search/create", "navigator", "pipedrive"];
    if (includeNames.some((name) => action.name.toLowerCase().includes(name))) {
      return payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString
        ? typeof payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString ===
          "string"
          ? payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString
          : JSON.stringify(payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString)
        : "";
    } else {
      return getEndpointFromNameAndId(
        action.name,
        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
      );
    }
  };

  const fieldSwitch = (field: PayloadStructure) => {
    switch (field.type) {
      case "inputArray":
        return (
          <div className="flex flex-col w-full justify-center items-start gap-4">
            {responses.map((response, index) => {
              return (
                <div className="w-full" key={index}>
                  <p>Input {index + 1}</p>
                  <div className=" flex justify-between">
                    <input
                      className="w-[47%] rounded-lg"
                      type="text"
                      name="name"
                      value={response.name}
                      onChange={(e) => {
                        const temp = [...responses];
                        temp[index].name = e.target.value;
                        setResponses(temp);
                      }}
                      onPaste={(e) => handlePaste(e, index, "name")}
                      placeholder="Name of the Field"
                    />
                    <input
                      className="w-[47%] rounded-lg"
                      type="text"
                      name="description"
                      placeholder="Description of the Field"
                      value={response.description}
                      onChange={(e) => {
                        const temp = [...responses];
                        temp[index].description = e.target.value;
                        setResponses(temp);
                      }}
                      onPaste={(e) => handlePaste(e, index, "description")}
                    />
                    <button
                      className="hover:bg-primary/10 h-fit w-fit p-2 rounded-full text-primary"
                      onClick={() => {
                        const temp = [...responses];
                        temp.splice(index, 1);
                        setResponses(temp);
                      }}
                    >
                      <MdDeleteOutline size={25} />
                    </button>
                  </div>
                </div>
              );
            })}
            <button
              className="border border-black rounded-lg p-2"
              onClick={() => {
                const temp = [...responses];
                temp.push({
                  name: "",
                  description: "",
                });
                setResponses(temp);
              }}
            >
              + Add input
            </button>
          </div>
        );
      case "dynamicDropdownInp":
        return (
          <Skeleton loading={campaignsLoading[field.valuesId || ""] ?? true}>
            <Select
              key={responses.map((response) => response.responseStructureId).join("")}
              mode="multiple"
              defaultValue={responses.map((response) => response.responseStructureId)}
              onChange={(value) => {
                if (field.valuesId === "engagebay_properties") {
                  setResponses(
                    value.map((res) => {
                      const p: any = (campaigns[field.valuesId || ""] ?? [])
                        .concat(ENGAGEBAY_SYSTEM_FIELDS)
                        .find((property: any) => property.field_name === res);
                      return {
                        name: p.field_label,
                        description: p.field_description,
                        responseStructureId: p.field_name,
                      };
                    })
                  );
                } else {
                  setResponses(
                    value.map((res) => {
                      const p: any = (campaigns[field.valuesId || ""] ?? []).find(
                        (property: any) => property.name === res
                      );
                      return {
                        name: p.label,
                        description: p.description,
                        responseStructureId: p.name,
                      };
                    })
                  );
                }
              }}
              options={
                field.valuesId === "engagebay_properties"
                  ? (campaigns[field.valuesId || ""] ?? []).concat(ENGAGEBAY_SYSTEM_FIELDS).map((property: any) => ({
                      label: property.field_label,
                      value: property.field_name,
                    }))
                  : (campaigns[field.valuesId || ""] ?? []).map((property: any) => ({
                      label: property.label,
                      value: property.name,
                    }))
              }
              optionFilterProp="label"
            />
          </Skeleton>
        );
      case "dynamicDropdown":
        return (
          <Skeleton loading={campaignsLoading[field.valuesId || ""] ?? true}>
            <Select
              showSearch
              defaultValue={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
              }
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString = value;
                setPayloads(temp);
              }}
              options={(campaigns[field.valuesId || ""] ?? []).map((value: any) => ({
                label: value.name,
                value: value.id,
              }))}
            />
          </Skeleton>
        );
      case "dynamicDropdownMulti":
        return (
          <Skeleton loading={campaignsLoading[field.valuesId || ""] ?? true}>
            <Select
              showSearch
              mode="multiple"
              defaultValue={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || []
              }
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                if (index === -1)
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    inputString: value,
                    type: field.type,
                  });
                else temp[index].inputString = value;
                setPayloads(temp);
              }}
              options={(campaigns[field.valuesId || ""] ?? []).map((value: any) => ({
                label: value.name,
                value: value.id,
              }))}
            />
          </Skeleton>
        );
      case "array":
        return (
          <div className="flex flex-col gap-6">
            {responses.map((item: ResponseConfiguration, idx2: number) => (
              <div className="flex gap-2">
                <input
                  type="text"
                  key={idx2}
                  className="w-full rounded-lg"
                  placeholder={field.description}
                  value={item.name}
                  onChange={(e) => {
                    const temp = [...responses];
                    temp[idx2].name = e.target.value;
                    temp[idx2].description = e.target.value;
                    temp[idx2].responseStructureId = e.target.value;
                    setResponses(temp);
                  }}
                />
                <button
                  className="hover:bg-primary/10 h-fit w-fit p-2 rounded-full text-primary"
                  onClick={() => {
                    const temp = [...responses];
                    temp.splice(idx2, 1);
                    setResponses(temp);
                  }}
                >
                  <MdDeleteOutline size={25} />
                </button>
              </div>
            ))}
            <button
              className="border border-black w-fit flex font-semibold items-center text-black gap-2 rounded-lg p-2"
              onClick={() => {
                const temp = [...responses];
                temp.push({
                  name: "",
                  description: "",
                });
                setResponses(temp);
              }}
              disabled={responses.length > 0 && responses[responses.length - 1].name === ""}
            >
              <FaPlus />
              Add {field.name}
            </button>
          </div>
        );

      case "webhookArray":
        return (
          <div className="flex flex-col w-full justify-center items-start gap-4">
            {responses.map((response, index) => {
              return (
                <div className="w-full" key={index}>
                  <p>Input {index + 1}</p>
                  <div className="flex gap-1 justify-between">
                    <input
                      className="w-[47%] rounded-lg"
                      type="text"
                      value={response.name}
                      onChange={(e) => {
                        const temp = [...responses];
                        temp[index].name = e.target.value;
                        setResponses(temp);
                      }}
                      placeholder="Name of the column"
                      onPaste={(e) => handlePaste(e, index, "name")}
                    />
                    <input
                      className="w-[47%] rounded-lg"
                      type="text"
                      placeholder="Webhook field"
                      value={response.responseStructureId || ""}
                      onChange={(e) => {
                        const temp = [...responses];
                        temp[index].responseStructureId = e.target.value;
                        setResponses(temp);
                      }}
                      onPaste={(e) => handlePaste(e, index, "responseStructureId")}
                    />
                    <button
                      className="hover:bg-primary/10 h-fit w-fit p-2 rounded-full text-primary"
                      onClick={() => {
                        const temp = [...responses];
                        temp.splice(index, 1);
                        setResponses(temp);
                      }}
                    >
                      <MdDeleteOutline size={25} />
                    </button>
                  </div>
                </div>
              );
            })}
            <button
              className="border border-black rounded-lg p-2"
              onClick={() => {
                const temp = [...responses];
                temp.push({
                  name: "",
                  description: "",
                });
                setResponses(temp);
              }}
            >
              + Add input
            </button>
          </div>
        );
      case "fixed":
        return (
          <div className="w-full rounded-lg p-2 bg-gray-100 break-words relative">
            <code>{getFixedCode(field)}</code>
            <Button
              type="text"
              icon={
                isCopied ? (
                  <FaCheck className="text-white bg-primary rounded-md p-1" />
                ) : (
                  <FaRegCopy className="text-primary" />
                )
              }
              className="absolute top-2 right-2 hover:bg-gray-200 rounded-md"
              onClick={() => {
                navigator.clipboard.writeText(getFixedCode(field)).then(() => {
                  setIsCopied(true);
                  setTimeout(() => setIsCopied(false), 2000);
                });
              }}
            />
          </div>
        );
      default:
        return (
          <textarea
            className="w-full h-32 rounded-lg"
            placeholder="Enter the value"
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(e) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: e.target.value,
                  type: field.type,
                });
              } else {
                temp[index].inputString = e.target.value;
              }
              setPayloads(temp);
            }}
          />
        );
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        onClose={() => setModal(false)}
        width="30%"
        title={
          <div className="flex items-center gap-2">
            <img src={action.logo} alt={action.name} className="w-6 h-6" />
            {action.name}
          </div>
        }
        footer={
          <button
            className={`w-full text-white rounded-lg p-2 mt-auto ${
              loading ? "cursor-not-allowed bg-gray-500" : "bg-primary cursor-pointer"
            }`}
            onClick={saveOutputStructure}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save"}
          </button>
        }
      >
        <div className="h-full bg-white gap-10 flex flex-col">
          {action.payloadStructure.map((field, idx) => {
            return (
              <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                <div>
                  <p className="tracking-primary font-semibold">
                    {field.name.toUpperCase()}
                    {!field.required && (
                      <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                    )}
                  </p>
                  <p className="text-sm font-light">{field.description}</p>
                </div>
                {fieldSwitch(field)}
              </div>
            );
          })}
          {action.name.includes("Webhook") && (
            <div className="flex flex-col w-full gap-4">
              <div className="flex items-center justify-between">
                <div>
                  <p className="tracking-primary font-semibold">Mapping</p>
                  <p className="text-sm font-light">Click the field to add as an acceptable parameter</p>
                </div>
                <button
                  className="flex gap-1 items-center hover:bg-gray-200 p-1 rounded"
                  disabled={ping.loading}
                  onClick={refreshPing}
                >
                  {ping.loading ? <SpinnerStatus /> : <IoIosRefresh color="black" />}
                  Refresh
                </button>
              </div>
              <div className="bg-gray-100 rounded p-4">
                {ping.data ? (
                  <>
                    <div className="flex flex-wrap items-center justify-between mb-4">
                      {Object.entries(ping.data).map(([key, value], idx) => (
                        <PingViewer keyName={key} value={value} prev={""} key={idx} addField={addField} />
                      ))}
                    </div>
                    <div className="w-full">
                      <button
                        className="w-full font-medium py-2 px-4 whitespace-nowrap h-fit bg-gray-200 hover:bg-gray-300 rounded text-xs"
                        onClick={handleAddFields}
                      >
                        Add All Fields
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="p-2 flex flex-col items-center">
                    <p>No mapping found</p>
                    <p>The webhook hasn't received any data yet.</p>
                    <p>Make a request and refresh to see the mapping.</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Drawer>
    </ConfigProvider>
  );
};

export default InputModal;
