import { useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { MdCalendarToday, MdDataArray, MdDataObject, MdNumbers } from "react-icons/md";
import { PiTextTBold } from "react-icons/pi";

type Props = {
  keyName: any;
  value: any;
  prev: string;
  showValue?: boolean;
  addField: (key: string) => void;
};

const PingViewer = ({ keyName, value, addField, prev = "", showValue = false }: Props) => {
  const isObject = value && typeof value === "object";
  const [isOpen, setIsOpen] = useState(false);

  const isAtomic = (value: any) => {
    return typeof value === "string" || typeof value === "number" || typeof value === "boolean";
  };

  const getIconFromType = (type: string) => {
    switch (type) {
      case "string":
        return <PiTextTBold />;
      case "number":
        return <MdNumbers />;
      case "date":
        return <MdCalendarToday />;
      case "array":
        return <MdDataArray />;
      case "json":
        return <MdDataObject />;
      default:
        return <PiTextTBold />;
    }
  };

  const getTypeFromValue = (value: any) => {
    if (Array.isArray(value)) return "array";
    if (typeof value === "object") return "json";
    return typeof value;
  };

  return (
    <div className="flex gap-1 items-baseline w-full">
      {isObject ? (
        !isOpen ? (
          <button onClick={() => setIsOpen(true)}>
            <IoIosArrowForward />
          </button>
        ) : (
          <button onClick={() => setIsOpen(false)}>
            <IoIosArrowDown />
          </button>
        )
      ) : null}
      <div className="w-full">
        <div className="flex justify-between items-center w-full group">
          <span className="flex gap-1 items-center text-sm bg-white w-fit p-1 rounded border border-gray-300">
            {getIconFromType(getTypeFromValue(value))} {keyName}
            {showValue && isAtomic(value) && <span className="text-xs text-gray-500 ml-1">{value.toString()}</span>}
          </span>
          <button
            onClick={() => addField(prev ? prev + "." + keyName : keyName)}
            className="p-1 whitespace-nowrap h-fit hover:bg-gray-200 group-hover:visible invisible rounded text-xs"
          >
            Add Field
          </button>
        </div>
        {isOpen
          ? Object.entries(value).map(([key, value], idx) => {
              return (
                <PingViewer
                  value={value}
                  keyName={key}
                  key={idx}
                  prev={prev ? prev + "." + keyName : keyName}
                  addField={addField}
                  showValue={showValue}
                />
              );
            })
          : null}
      </div>
    </div>
  );
};

export default PingViewer;
