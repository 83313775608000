import { renderToString } from "react-dom/server";
import {
  ACTION_TYPES,
  CELL_STATUS_DISPLAY,
  CELL_STATUS_SYMBOL,
  CELL_STATUS_TYPE,
  DISPLAY_DATE_TIME_FORMAT,
  TABLE_COLUMN_STATUS_FILTERS,
  TABLE_ERROR_MESSAGES,
  USER_ACTION_STATUS,
  WATERFALL_ACTIONS,
} from "@/utils/constants";
import { fetchDocContent, isApplicableURL } from "@/utils/functions";
import { ActionResponses, ResponseStructure } from "@/utils/interfaces";
import { Tooltip } from "antd";
import moment from "moment";
import { IoPlay } from "react-icons/io5";
import * as Checkbox from "@radix-ui/react-checkbox";
import { FaRegCopy } from "react-icons/fa";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { useState } from "react";
import RegenMenu from "../../Modals/RegenMenu";
import { FaArrowDownLong, FaArrowDownUpAcrossLine, FaArrowUpLong } from "react-icons/fa6";
import { cloneDeep } from "lodash";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GetContextMenuItemsParams } from "ag-grid-enterprise";
import MultiReviewModal from "../../Modals/MultiReviewModal";

export interface ViewerProps {
  open: boolean;
  data: any;
  title: () => React.ReactNode;
  type: string;
  // style?:any;
  credits: number | Record<string, number>;
  errorMessage?: string;
}

export type Sorter = { [field: string]: string };

interface MapColumnDeps {
  runAction: (recordKey: any, inputs: any) => void;
  runCellAction: (actionId: string, dataIdL: string) => void;
  copied: boolean;
  updateRow: (row: any) => void;
  updateRows: (rows: any[] | "all") => void;
  setCustomViewer: (data: any) => void;
  setErrorModal: (data: any) => void;
  setErrorData: (data: any) => void;
  setModalStyle: (data: any) => void;
  workflowId: string;
  currentSection: number;
  modalStyle: any;
  filters?: any;
  triggerReload: () => void;
  triggerLoadingOverlay: () => void;
  handleDedupeInput: (columnId: string) => Promise<void>;
  selectedRows: any[];
  sorters: Sorter[] | null;
  sortCreatedAt: () => void;
  columnWidths: any;
}

interface MapRowsDeps {
  id: string;
  currentSection: number;
  updateRow: (row: any) => void;
}

const getSortOption = (sort: string) => {
  switch (sort) {
    case "descend":
      return <FaArrowDownLong />;
    case "ascend":
      return <FaArrowUpLong />;
    default:
      return null;
  }
};

export const getSelectedRows = (selectedRows: any[], colunmsData: any[]) => {
  const selectedTempRows = cloneDeep(selectedRows);
  const transformedData = selectedTempRows.map((row: any) => {
    Object.keys(row).forEach((key) => {
      const colData = colunmsData.find((col: any) => col.field === key);
      if (!colData) return;
      const val = row[key];
      delete row[key];
      // @ts-ignore
      row[colData.id] = val;
    });
    return row;
  });

  return transformedData;
};

export const mapColumns = (actions: ActionResponses[], deps: MapColumnDeps) => {
  const {
    runAction,
    runCellAction,
    updateRow,
    updateRows,
    setCustomViewer,
    setErrorModal,
    setErrorData,
    setModalStyle,
    workflowId,
    currentSection,
    modalStyle,
    filters,
    triggerReload,
    handleDedupeInput,
    selectedRows,
    sorters,
    sortCreatedAt,
    columnWidths,
  } = deps;
  const columns: any[] = actions?.map((action) => {
    if (action && (action.type === "input" || action.type === "add_section")) {
      return action.response.map((res) => {
        return {
          field: res.name,
          id: res.responseId,
          editable: !currentSection ? true : false,
          sortable: false,
          mainMenuItems: (params: GetContextMenuItemsParams) => {
            return [
              ...(params.defaultItems || []),
              {
                icon: `${renderToString(<FaArrowDownUpAcrossLine size={"12px"} className="text-gray-600" />)}`,
                name: "DeDupe Column",
                action: () => {
                  handleDedupeInput(res.responseId);
                },
              },
            ];
          },
          headerComponent: (props: any) => {
            return (
              <>
                <div className="bg-[#fffff0] h-full w-full flex justify-start items-center pr-5">
                  <div
                    className="pl-2 hover:cursor-pointer"
                    onClick={() => {
                      console.log(props.api.showColumnMenu(res.name));
                    }}
                  >
                    <BsThreeDotsVertical />
                  </div>
                  <div className="pl-3">{props?.displayName}</div>
                </div>
              </>
            );
          },
          width: columnWidths[res.name] || 200,
          minWidth: 80,
        };
      });
    }

    const mainColumn = {
      field: action.displayName || action.name,
      id: action.actionId,
      editable: false,
      sortable: false,
      width: columnWidths[action.displayName || action.name] || 250,
      minWidth: 150, // Prevent shrinking
      colData: action,
      filter: () => {
        return (
          <div>
            {TABLE_COLUMN_STATUS_FILTERS?.map((status, index) => {
              return (
                <label key={index} htmlFor={`${index}`}>
                  <div className="flex gap-2 py-1 px-2 cursor-pointer hover:bg-gray-300 hover:text-white transition duration-300">
                    <Checkbox.Root
                      className="CheckboxRoot"
                      id={`${index}`}
                      onCheckedChange={(_val) => {}}
                    ></Checkbox.Root>
                    <div className="text-sm">{status?.text}</div>
                  </div>
                </label>
              );
            })}
          </div>
        );
      },
      headerComponent: (props: any) => {
        const showModalNearCell = (event: any) => {
          const cellRect = event.target.getBoundingClientRect();
          const modalWidth = 300;
          const modalHeight = 100;

          let modalPosition = {
            top: cellRect.top + window.scrollY,
            left: cellRect.left + window.scrollX,
          };

          // Adjust if modal goes beyond the right edge of the window
          if (modalPosition.left + modalWidth > window.innerWidth) {
            modalPosition.left = window.innerWidth - modalWidth;
          }

          // Adjust if modal goes beyond the bottom edge of the window
          if (modalPosition.top + modalHeight > window.innerHeight) {
            modalPosition.top = window.innerHeight - modalHeight;
          }

          setModalStyle({
            position: "absolute",
            top: `${modalPosition.top}px`,
            left: `${modalPosition.left}px`,
          });
        };
        return (
          <div className="flex items-center gap-2 pr-2" style={{ width: "100%" }}>
            <div
              className="pl-2 hover:cursor-pointer"
              onClick={() => {
                console.log(props.api.showColumnMenu(action.displayName || action.name));
              }}
            >
              <BsThreeDotsVertical />
            </div>
            <div>
              <img
                src={props.column.userProvidedColDef.colData.logo}
                height={25}
                width={25}
                className="min-w-[25px] min-h-[25px]"
              />
            </div>
            <div
              className="flex-grow truncate"
              style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
            >
              {props.displayName}
            </div>
            <div>
              <div className="flex flex-row gap-2">
                {action.type === ACTION_TYPES.AI && (
                  <div className="opacity-1 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                    <MultiReviewModal
                      actionId={action.actionId}
                      userWorkflowId={workflowId}
                      section={currentSection}
                      // showModalNearCell={showModalNearCell}
                      style={modalStyle}
                      selectedRows={selectedRows?.map((row) => row.key)}
                      setSelectedRows={() => {}}
                    />
                  </div>
                )}
                <div className="opacity-1 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                  <RegenMenu
                    actionId={action.actionId}
                    userWorkflowId={workflowId}
                    section={currentSection}
                    showModalNearCell={showModalNearCell}
                    style={modalStyle}
                    selectedRows={selectedRows?.map((row) => row.key)}
                    setSelectedRows={() => {}}
                    filters={filters}
                    updateRows={updateRows}
                    // refresh={triggerReload}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      },

      cellRenderer: (params: any) => {
        const { data } = params;
        const actionData = data?.[action.actionId];
        const status = actionData?.status;
        if (!actionData) {
          return (
            <div className="flex flex-row gap-2 h-10 items-center justify-between px-2 transition group">
              <div className="flex gap-2 overflow-hidden whitespace-nowrap text-ellipsis w-auto">
                <span className="flex pr-2 gap-1 sm:gap-2">
                  {CELL_STATUS_SYMBOL[status || "pending"]}
                  <span>{CELL_STATUS_DISPLAY[status || "pending"]}</span>
                </span>
              </div>
              {(status || "pending") === "pending" && (
                <Tooltip
                  placement="bottomLeft"
                  title="Run this cell"
                  arrow={false}
                  overlayClassName="border border-gray-200 rounded-md"
                  overlayInnerStyle={{
                    fontWeight: 600,
                    backgroundColor: "#f3f4f6",
                    color: "#6c727f",
                    fontSize: "0.75rem",
                  }}
                >
                  <button
                    type="button"
                    onClick={() => {
                      const actionId = params.column.colDef.id;
                      const dataId = params.data.key;
                      runCellAction(actionId, dataId);
                      setTimeout(triggerReload, 1500);
                    }}
                    title="Run"
                    className="group-hover:flex justify-center items-center rounded-full bg-gray-200 hover:bg-gray-300 p-2 hidden"
                  >
                    <IoPlay size={"12px"} className="text-gray-600" />
                  </button>
                </Tooltip>
              )}
            </div>
          );
        }

        const ColTitle = ({ action, children }: { action: ActionResponses; children?: React.ReactNode }) => (
          <div className="relative flex items-center justify-between gap-2">
            <section className="flex items-center justify-between gap-2 overflow-hidden text-ellipsis">
              <img src={action.logo} alt={action.name} className="w-6 h-6" />
              <span>{action.displayName || action.name}</span>
            </section>
            {children}
          </div>
        );

        const { extra, stepDownResponse, ...colResponse } = actionData;
        const actionResponseSize: string = action.responseSize;
        const isActionResponseSizeSmall: boolean = actionResponseSize === "small";
        const isWaterfallAction = WATERFALL_ACTIONS.includes(action.actionName);
        const actionResponse: any = isActionResponseSizeSmall && Object.values(colResponse)[0];

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [copied, setCopied] = useState(false);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [retried, _setRetried] = useState(false);

        const handleCopyEmail = () => {
          if (isActionResponseSizeSmall) {
            navigator.clipboard
              .writeText(actionResponse.value)
              .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000); // Hide after 2 seconds
              })
              .catch((_err) => {
                alert("Failed to copy. Please try again.");
              });
          }
        };

        const showModalNearCell = (event: any) => {
          const cellRect = event.target.getBoundingClientRect();
          // Assuming a fixed size for the modal for demonstration purposes
          const modalWidth = 500; // Adjust as needed
          const modalHeight = 200; // Adjust as needed

          // Initial modal position calculation
          let modalPosition = {
            top: cellRect.top + window.scrollY,
            left: cellRect.left + window.scrollX,
          };

          // Adjust if modal goes beyond the right edge of the window
          if (modalPosition.left + modalWidth > window.innerWidth) modalPosition.left = window.innerWidth - modalWidth;

          // Adjust if modal goes beyond the bottom edge of the window
          if (modalPosition.top + modalHeight > window.innerHeight)
            modalPosition.top = window.innerHeight - modalHeight;

          // Apply the adjusted positions, converting back to strings with 'px'
          setModalStyle({
            position: "absolute",
            top: `${modalPosition.top}px`,
            left: `${modalPosition.left}px`,
          });
        };

        const customDataFormat = (value: any) => {
          if (["string", "number"].includes(typeof value)) {
            return value;
          }
          return JSON.stringify(value);
        };

        function jsonParser(_value: any): any {
          throw new Error("Function not implemented.");
        }

        return (
          //<div className="relative flex space-x-4 transition group">
          <div className="flex flex-row gap-2 px-2 justify-start transition group ">
            {action["ui-style"] === "Formatting" && action.actionName.includes("FORMATTER") ? (
              // display the formatted value as is in the cell
              <div className="flex gap-2 overflow-hidden whitespace-nowrap text-ellipsis w-auto">
                <span className="flex items-center justify-center pr-2 gap-1 sm:gap-2">
                  {customDataFormat((Object.values(colResponse)[0] as any)?.value || {})}
                </span>
              </div>
            ) : (
              <button
                type="button"
                className={`flex gap-2 overflow-hidden whitespace-nowrap text-ellipsis w-auto`}
                disabled={
                  !(
                    actionData &&
                    [
                      CELL_STATUS_TYPE.COMPLETED,
                      CELL_STATUS_TYPE.READY_TO_BE_REVIEWED,
                      CELL_STATUS_TYPE.FAILED,
                    ].includes(status || "")
                  )
                }
                onClick={() => {
                  if (!actionData) return alert("Error processing request. Please try again later.");
                  if (status === "failed") {
                    if (isWaterfallAction) {
                      setCustomViewer({
                        open: true,
                        data: {
                          stepDownResponse,
                          action,
                          email: actionResponse.value,
                        },
                        title: () => <ColTitle action={action} />,
                        type: "email",
                        credits: action.credits,
                        errorMessage: actionData.error,
                      });
                    } else {
                      setErrorData({
                        status: status,
                        message: actionData.error,
                      });
                      setErrorModal(true);
                    }
                    return;
                  }

                  if (action.actionName === "PUBLIC_LINKEDIN_POSTS_ENRICH") {
                    setCustomViewer({
                      open: true,
                      data: Object.values(colResponse)
                        .map((res: any) => ({
                          [res.name]: res.value,
                        }))
                        .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                      title: () => <ColTitle action={action} />,
                      type: "linkedinPosts",
                      credits: action.credits,
                    });
                  } else if (action.actionName === "SCRAPE_LINKEDIN_PUBLIC_DATA_2") {
                    setCustomViewer({
                      open: true,
                      data: Object.values(colResponse)
                        .map((res: any) => ({
                          [res.name]: res.value,
                        }))
                        .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                      title: () => <ColTitle action={action} />,
                      type: "linkedinProfile",
                      credits: action.credits,
                    });
                  } else if (action.actionName === "FIND_EMPLOYEES_FOR_COMPANY_USING_APOLLO") {
                    setCustomViewer({
                      open: true,
                      data: Object.values(colResponse)
                        .map((res: any) => ({
                          [res.name]: res.value,
                        }))
                        .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                      title: () => <ColTitle action={action} />,
                      type: "apolloEmployees",
                      credits: 0, //marked
                    });
                  } else if (action.actionName === "FIND_EMPLOYEES_USING_SALES_NAV_URL") {
                    setCustomViewer({
                      open: true,
                      data: Object.values(colResponse)
                        .map((res: any) => ({
                          [res.name]: res.value,
                        }))
                        .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                      title: () => <ColTitle action={action} />,
                      type: "salesNavEmployees",
                      credits: 0, //marked
                    });
                  } else if (action.actionName === "WELL_DB_OPERATOR_LOOKUP_AND_WELLS_SEARCH") {
                    setCustomViewer({
                      open: true,
                      data: Object.values(colResponse)
                        .map((res: any) => ({
                          [res.name]: res.value,
                        }))
                        .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                      title: () => <ColTitle action={action} />,
                      type: "wells",
                      credits: 0,
                    });
                  } else if (action.actionName === "CRUNCHBASE_RECENT_FUNDRAISING_DATA_FROM_COMPANY") {
                    setCustomViewer({
                      open: true,
                      data: Object.values(colResponse)
                        .map((res: any) => ({
                          [res.name]: res.value,
                        }))
                        .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                      title: () => <ColTitle action={action} />,
                      type: "crunchBaseFundraising",
                      credits: action.credits,
                    });
                  } else if (
                    typeof colResponse === "object" &&
                    Object.keys(colResponse).length > 0 &&
                    Object.values(colResponse).some((res: any) => res.type && res.type === "textFileURL")
                  ) {
                    setCustomViewer({
                      open: true,
                      data: actionData,
                      title: () => <ColTitle action={action} />,
                      type: "textFileURL",
                      credits: action.credits,
                    });
                  } else if (
                    typeof colResponse === "object" &&
                    Object.keys(colResponse).length > 0 &&
                    Object.values(colResponse).some((res: any) => res.type && res.type === "table")
                  ) {
                    setCustomViewer({
                      open: true,
                      data: Object.values(colResponse)
                        .map((res: any) => ({
                          [res.name]: res.value,
                        }))
                        .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                      title: () => <ColTitle action={action} />,
                      type: "table",
                      credits: action.credits,
                    });
                  } else if (typeof actionData === "object" && actionData.extra.reviewAllowed) {
                    showModalNearCell(event);
                    setCustomViewer({
                      open: true,
                      data: actionData,
                      title: () => <ColTitle action={action} />,
                      type: "review",
                      credits: action.credits,
                    });
                  } else if (isWaterfallAction) {
                    setCustomViewer({
                      open: true,
                      data: {
                        stepDownResponse,
                        action,
                        email: actionResponse.value,
                      },
                      title: () => <ColTitle action={action} />,
                      type: "email",
                      credits: action.credits,
                    });
                  } else {
                    setCustomViewer({
                      open: true,
                      data: Object.values(colResponse)
                        .map((res: any) => ({
                          [res.name]:
                            res.stringified || action.actionName === "HTTP_API_CALL"
                              ? jsonParser(res.value)
                              : res.value,
                        }))
                        .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                      title: () => <ColTitle action={action} />,
                      type: "default",
                      credits: action.credits,
                    });
                  }
                }}
              >
                <span className="flex items-center justify-center pr-2 gap-1 sm:gap-2">
                  {CELL_STATUS_SYMBOL[status || "pending"]}
                  <span>
                    {status === USER_ACTION_STATUS.FAILED &&
                    actionData.error &&
                    TABLE_ERROR_MESSAGES.includes(actionData.error)
                      ? actionData.error
                      : (status === "completed" || status === "readyToBeReviewed") && actionResponse
                        ? actionResponse.value
                        : CELL_STATUS_DISPLAY[status || "pending"]}
                  </span>
                </span>
              </button>
            )}
            <div className="flex items-center justify-center gap-2">
              {actionResponse && (
                <div className="opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                  <Tooltip
                    placement="bottomLeft"
                    title={copied ? "Copied!" : "Copy to clipboard"}
                    arrow={false}
                    overlayClassName="border border-gray-200 rounded-md"
                    overlayInnerStyle={{
                      fontWeight: 600,
                      backgroundColor: `${copied ? "#6c727f" : "#f3f4f6"}`,
                      color: `${copied ? "#f3f4f6" : "#6c727f"}`,
                      fontSize: "0.75rem",
                    }}
                  >
                    <button
                      type="button"
                      onClick={handleCopyEmail}
                      // title="Copy"
                      className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
                    >
                      <FaRegCopy size={"12px"} className="text-gray-600" />
                    </button>
                  </Tooltip>
                </div>
              )}
              <div className="opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                <Tooltip
                  placement="bottomLeft"
                  title={retried ? "Run!" : "Run this cell"}
                  arrow={false}
                  overlayClassName="border border-gray-200 rounded-md"
                  overlayInnerStyle={{
                    fontWeight: 600,
                    backgroundColor: `${retried ? "#6c727f" : "#f3f4f6"}`,
                    color: `${retried ? "#f3f4f6" : "#6c727f"}`,
                    fontSize: "0.75rem",
                  }}
                >
                  <button
                    type="button"
                    // onClick={handleRetryCell}
                    onClick={() => {
                      const actionId = params.column.colDef.id;
                      const dataId = params.data.key;
                      runCellAction(actionId, dataId);
                      setTimeout(triggerReload, 1500);
                    }}
                    // title="Retry"
                    className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
                  >
                    <IoPlay size={"12px"} className="text-gray-600" />
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
        );
      },
    };
    // Subcolumns
    const subColumns = action.response
      .filter((res: any) => res.display_column)
      .map((res: any) => ({
        field: res.name,
        id: res.responseId,
        editable: !currentSection ? true : false,
        sortable: false,
        mainMenuItems: (params: GetContextMenuItemsParams) => [
          ...(params.defaultItems || []),
          {
            icon: `${renderToString(<FaArrowDownUpAcrossLine size={"12px"} className="text-gray-600" />)}`,
            name: "DeDupe Column",
            action: () => {
              handleDedupeInput(res.responseId);
            },
          },
        ],
        headerComponent: (props: any) => (
          <div className="bg-[#fffff0] h-full w-full flex justify-start items-center pr-5">
            <div
              className="pl-2 hover:cursor-pointer"
              onClick={() => {
                console.log(props.api.showColumnMenu(res.name));
              }}
            >
              <BsThreeDotsVertical />
            </div>
            <div className="pl-3">{props?.displayName}</div>
          </div>
        ),
        cellRenderer: (params: any) => {
          const { data } = params;
          const actionData = data?.[action.actionId];
          const text = actionData?.[res.responseId];
          const status = actionData?.status;

          return (
            <Tooltip
              placement="bottom"
              title={["string", "number"].includes(text?.type) ? text?.value : JSON.stringify(text?.value, null, 2)}
              arrow={false}
              overlayClassName="border border-gray-200 rounded-md text-sm whitespace-pre-wrap overflow-auto max-h-96 max-w-md scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
              overlayInnerStyle={{
                fontWeight: 600,
                backgroundColor: "#f3f4f6",
                color: "#6c727f",
                fontSize: "0.75rem",
              }}
            >
              <div className="overflow-hidden whitespace-nowrap text-ellipsis w-auto cursor-pointer">
                {status !== "completed" && status !== "readyToBeReviewed" ? (
                  <div className="flex gap-2 items-center">
                    {CELL_STATUS_SYMBOL[actionData?.status || "pending"]}
                    <span>{CELL_STATUS_DISPLAY[actionData?.status || "pending"]}</span>
                  </div>
                ) : ["string", "number"].includes(text?.type) ? (
                  text?.value
                ) : (
                  JSON.stringify(text?.value)
                )}
              </div>
            </Tooltip>
          );
        },
        width: columnWidths[res.name] || 180,
        minWidth: 180,
        maxWidth: 300,
        valueFormatter: (params: any) => {
          const { data } = params;
          const actionData = data?.[action.actionId];
          const text = actionData?.[res.responseId];
          return ["string", "number"].includes(text?.type) ? text?.value : JSON.stringify(text?.value, null, 2);
        },
      }));

    // Combine main column and subcolumns
    return [mainColumn, ...subColumns];
  });
  const createdAtSortOption = sorters?.find((sorter) => sorter.feild === "createdAt");

  const flatArr = columns.reduce((acc, val) => acc.concat(...(Array.isArray(val) ? val : [val])), []);
  flatArr.unshift({
    field: "Created At",
    id: "createdAt",
    headerComponent: (params: any) => {
      return (
        <div className="flex justify-center items-center pr-5">
          <div
            className="pl-2 hover:cursor-pointer"
            onClick={() => {
              console.log(params.api.showColumnMenu("Created At"));
            }}
          >
            <BsThreeDotsVertical />
          </div>
          <div className="flex gap-1 pl-3 cursor-pointer" onClick={sortCreatedAt}>
            <div>Created At</div>
            <div>{getSortOption(createdAtSortOption?.order || "")}</div>
          </div>
        </div>
      );
    },
    cellRenderer: (params: any) => {
      const { data } = params;
      return (
        // <div className="flex justify-center h-full items-center
        //  text-red-300 font-semibold text-sm px-5 transition group hover:text-red-500 cursor-pointer
        // ">
        //   {data.createdAtt}
        // </div>
        <div>{data.createdAtt}</div>
      );
    },
  });

  flatArr.unshift({
    field: "Run",
    editable: false,
    sortable: false,
    width: 75,
    pinned: "left",
    headerComponent: (_: any) => {
      return <div className="flex justify-center items-center px-5">Run</div>;
    },
    // type needs to be replaced
    cellRenderer: (params: any) => {
      const { data } = params;
      const { run } = data;

      const updateLoadingState = () => {
        data.run = "loading";
        updateRow(data);
      };
      const runRow = () => {
        const colIndex = params.api.getAllGridColumns();
        const inputColIds = colIndex
          .filter((col: any) => col?.colDef?.editable)
          .map((col: any) => ({ id: col?.colDef?.id, name: col?.colDef?.field }));

        const inputs = Object.entries(data).reduce((acc, [key, value]) => {
          if (key === "key" || key === "run" || key === "data") return acc;
          const colData = inputColIds.find((col: any) => col.name === key);
          if (colData) {
            return { ...acc, [colData?.id]: value };
          }
          return acc;
        }, {});
        setTimeout(() => runAction([data?.key], [inputs]), 2000);
        updateLoadingState();
      };
      return (
        <div className="flex justify-center h-full items-center">
          {run === "run" ? (
            <div className="opacity-1 group-hover:opacity-100 transition-all duration-300 ease-in-out">
              <Tooltip
                placement="right"
                title="Run"
                arrow={false}
                overlayClassName="border border-gray-200 rounded-md"
                overlayInnerStyle={{
                  fontWeight: 600,
                  backgroundColor: "#f3f4f6",
                  color: "#6c727f",
                  fontSize: "0.75rem",
                }}
              >
                <button
                  type="button"
                  onClick={runRow}
                  className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-300 p-2"
                >
                  <IoPlay size={"12px"} />
                </button>
              </Tooltip>
            </div>
          ) : run === "retry" ? (
            <div className="opacity-1 group-hover:opacity-100 transition-all duration-300 ease-in-out">
              <Tooltip
                placement="right"
                title="Run"
                arrow={false}
                overlayClassName="border border-gray-200 rounded-md"
                overlayInnerStyle={{
                  fontWeight: 600,
                  backgroundColor: "#f3f4f6",
                  color: "#6c727f",
                  fontSize: "0.75rem",
                }}
              >
                <button
                  onClick={runRow}
                  className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-300 p-2"
                >
                  <IoPlay size={"12px"} />
                </button>
              </Tooltip>
            </div>
          ) : run === "loading" ? (
            <div className="flex justify-center items-center p-2">
              <SpinnerStatus />
            </div>
          ) : run === "review" ? (
            <div className="flex items-center justify-center p-2">{CELL_STATUS_SYMBOL.readyToBeReviewed}</div>
          ) : run === "complete" ? (
            <div className="flex items-center justify-center p-2">{CELL_STATUS_SYMBOL.completed}</div>
          ) : (
            <></>
          )}
        </div>
      );
    },
  });
  return flatArr;
};

export const mapRows = async (actions: ActionResponses[], data: any, deps: MapRowsDeps) => {
  const { id, currentSection } = deps;
  const rows: any[] = [];
  await Promise.all(
    Object.entries(data).map(async ([dataId, item]: [string, any], idx: number) => {
      if (item.sectionInfo && currentSection === 0) return;
      const row: any = { key: dataId, id: dataId };
      const status: string[] = [];
      await Promise.all(
        Object.entries(item).map(async ([actionId, data]: [string, any]) => {
          const action = actions.find((a) => a.actionId === actionId || a.fsWfActionId === actionId);
          if (!action || ["createdAt", "sectionInfo"].includes(actionId)) return;
          else if (action && (action.type === "input" || action.type === "add_section")) {
            const actionResponseMap: Record<string, string> = {};
            action.response.forEach((res: ResponseStructure) => {
              actionResponseMap[res.responseId] = res?.name;
            });

            (data.response ?? []).map((res: any) => {
              row[actionResponseMap[res.responseId]] = res.value;
            });
            if (data?.createdAt) {
              if (row?.["createdAtt"]) {
                console.log("created at already present");
              }
              row["createdAtt"] = moment.utc(data?.createdAt).local().format(DISPLAY_DATE_TIME_FORMAT);
            }
            row.responseMap = Object.fromEntries(Object.entries(actionResponseMap).map(([key, value]) => [value, key]));
          } else {
            status.push(data.status);
            let temp: any = {};
            let respArray = [];
            if (typeof data.response === "string" && isApplicableURL(data.response))
              respArray = await fetchDocContent(data.response);
            else respArray = data.response || [];

            if (Array.isArray(respArray)) {
              respArray.map((r: any) => {
                temp[r.responseId] = {
                  ...(action?.response.find((res) => res.responseId === r.responseId) || {}),
                  value: r.value,
                  stringified: r.stringified,
                };
              });
            }

            if (data.stepDownResponse) temp.stepDownResponse = data.stepDownResponse;
            if (data.error) temp.error = data.error;
            temp = {
              ...temp,
              status: data.status,
              extra: {
                reviewNeeded: action?.reviewNeeded || false,
                reviewed: data.reviewed || false,
                index: idx,
                workflowId: id,
                actionId,
                dataId,
                reviewAllowed: action?.reviewAllowed || false,
              },
            };
            row[actionId] = temp;
          }
        })
      );
      if (status.includes(CELL_STATUS_TYPE.FAILED)) row.run = "retry";
      else if (status.includes(CELL_STATUS_TYPE.READY_TO_BE_REVIEWED)) row.run = "review";
      else if (
        status.includes(CELL_STATUS_TYPE.MISSING) ||
        status.includes(CELL_STATUS_TYPE.CHECKING_NEXT_SOURCE) ||
        status.includes(CELL_STATUS_TYPE.PAYLOAD_FILLED) ||
        status.includes(CELL_STATUS_TYPE.RETRYING)
      )
        row.run = "loading";
      else row.run = "retry";
      // row.data = item;
      rows.push(row);
    })
  );

  return rows;
};

export const normalizeRowData = (data: any, actions: ActionResponses[]) => {
  const response: any = {};
  actions?.forEach((action) => {
    if (!(action.type === "input" || action.type === "add_section")) {
      return;
    }

    action?.response?.forEach((res) => {
      response[res.responseId] = res.name;
    });
  });

  for (let key in data) {
    if (!response[key]) {
      continue;
    }

    data[response[key]] = data[key];
    delete data[key];
  }

  data.responseMap = Object.fromEntries(Object.entries(response).map(([key, value]) => [value, key]));
  return data;
};
