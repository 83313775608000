import { Drawer } from "antd";
import { useCallback, useEffect, useState } from "react";
import CollapseItemWrapper from "./DocViewer/CollapseItemWrapper";
import Contract from "@/assets/SVGs/buildTab/Contract";
import Expand from "@/assets/SVGs/buildTab/Expand";
import { FaCoins } from "react-icons/fa";

type Props = {
  open: boolean;
  close: () => void;
  data: any;
  title: () => React.ReactNode;
  credits: any;
};

const TenKDocViewer = ({ open, close, data, title, credits }: Props) => {
  const [arr, setArr] = useState<any[]>([]);
  const [expand, setExpand] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState("30%");

  useEffect(() => {
    setWidthPercent();
    window.addEventListener("resize", setWidthPercent);
    return () => window.removeEventListener("resize", setWidthPercent);
  }, []);

  const setWidthPercent = useCallback(() => {
    try {
      // Adjust width for different screen sizes
      if (window.matchMedia("(min-width: 1024px) and (min-height: 1366px)").matches) {
        // iPad Pro and similar devices
        setDrawerWidth("50%");
      } else if (window.matchMedia("(min-width: 1024px)").matches) {
        setDrawerWidth("30%");
      } else if (window.matchMedia("(min-width: 640px)").matches) {
        setDrawerWidth("50%");
      } else {
        setDrawerWidth("100%");
      }
    } catch (err) {
      console.error("Error calculating width percent based on media size");
    }
  }, []);

  useEffect(() => {
    if (!open) setArr([]);
  }, [open]);

  useEffect(() => {
    if (!data) return;
    setArr(() => [...Object.values(data).filter((itr: any) => itr.value)]);
  }, [data]);

  return (
    <Drawer
      title={title()}
      placement="right"
      onClose={close}
      open={open}
      // width="40%"
      styles={{
        wrapper: { width: "100vw", boxShadow: "none" },
        content: {
          borderTopLeftRadius: "0.5rem",
          borderBottomLeftRadius: "0.5rem",
          width: `${expand ? `calc(100% - 28px)` : drawerWidth}`,
          transition: "width 0.3s",
          position: "relative",
          overflow: "visible",
        },
        header: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        },
        body: { padding: "1rem", overflow: "hidden" },
      }}
      className="ml-auto !font-['Inter'] !p-0 "
    >
      <button
        className="font-semibold bg-white px-2 py-8 text-sm rounded-tl-lg rounded-bl-lg transition absolute left-[-28px] top-[30%] bottom-auto"
        title={expand ? "Contract to original size" : "Expand to full screen"}
        onClick={() => setExpand(!expand)}
      >
        {expand ? <Contract /> : <Expand />}
      </button>
      <div className="w-full h-full overflow-y-auto overflow-x-hidden">
        {arr.map((itr, idx) => (
          <CollapseItemWrapper idx={idx} itr={itr} />
        ))}
      </div>
      <div className="mt-4 flex items-center">
        <h1 className="font-semibold text-md">Total cost:</h1>
        <div className="flex items-center ml-2 bg-purple-100 rounded p-1">
          <span className="font-bold text-md">{credits}</span>
          <FaCoins className="ml-1 text-purple-500 w-5" />
        </div>
      </div>
    </Drawer>
  );
};

export default TenKDocViewer;
