import { useCallback, useEffect, useState } from "react";
import {
  ReactFlow,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  BackgroundVariant,
  Node,
  Edge,
  ReactFlowProvider,
  ConnectionLineType,
} from "@xyflow/react";

import "@xyflow/react/dist/style.css";
import { ActionNode } from "./ActionNode";
import AddEdge from "./AddEdge";
import InputModal from "@/Components/Workflow/ActionModals/InputModal";
import AddSection from "@/Components/Workflow/ActionModals/AddSection";
import SendingModal from "@/Components/Workflow/ActionModals/SendingModal";
import ActionModal from "@/Components/Workflow/ActionModals/ActionModal";
import HTTPModal from "@/Components/Workflow/ActionModals/HTTPApiModal";
import { Condition, NextActionConfiguration, PayloadConfiguration, ResponseConfiguration } from "@/utils/interfaces";
import { useParams } from "react-router-dom";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { sameTimeStamp } from "@/utils/functions";
import { MdInfoOutline } from "react-icons/md";
import { IoWarning } from "react-icons/io5";
import WarningSVG from "@/assets/SVGs/buildTab/WarningSVG";
import dagre from "dagre";
import CrmModal from "../../ActionModals/CrmModal";
import CrmPullModal from "../../ActionModals/CrmPullModal";
import HubspotModal from "../../ActionModals/HubspotModal";
import LoaderButton from "@/Components/LoaderButtonBlack";
import EngagebayModal from "../../ActionModals/EngagebayModal";
import FloqPushModal from "../../ActionModals/FloqPushModal";
import { publishDraft, revertDraft } from "@/utils/apis";
import CloseCrmModal from "../../ActionModals/closeCrmModal";
import AiActionModal from "../../ActionModals/AiActionModal";
import WaterfallModal from "../../ActionModals/WaterfallModal";
import GoogleSheetModal from "../../ActionModals/GoogleSheetModal";
import { ACTION_TYPES } from "@/utils/constants";
import MondayModal from "../../ActionModals/MondayModal";
import { v4 as uuidv4 } from "uuid";

type Props = {
  firstActionId: string;
  workflow: any;
};

const nodeHeight = window.innerHeight * 0.14;
const nodeWidth = window.innerWidth * 0.4;

function ReactFlowBuild({ firstActionId, workflow }: Props) {
  const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const [modal, setModal] = useState(false);
  // const [conditionalModal, setConditionalModal] = useState(false);
  const [responseConfiguration, setResponseConfiguration] = useState<ResponseConfiguration[]>([]);
  const [payloadConfiguration, setPayloadConfiguration] = useState<PayloadConfiguration[]>([]);
  const [errorActions, setErrorActions] = useState<string[]>([]);
  const [errorEdges, setErrorEdges] = useState<string[]>([]);
  const [action, setAction] = useState<any>(undefined);
  // const [condition, setCondition] = useState<any>(undefined);
  const [variables, setVariables] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [minMaxCostData, setMinMaxCostData] = useState<any>({});
  const {
    actions,
    setActions,
    saveWorkflowActions,
    getVariblesForAction,
    getAllWorkflowActionsDetails,
    publishWarning,
    revertWarning,
    setPublishWarning,
    setRevertWarning,
    workflowBuildDetails,
    setWorkflowBuildDetails,
  } = useWorkflow();
  const { id } = useParams();
  const nodeTypes = { actionNode: ActionNode };
  const edgeTypes = { addEdge: AddEdge };
  const initialPos = { x: 0, y: 0 };
  const [dagreGraph, setDagreGraph] = useState<dagre.graphlib.Graph>(new dagre.graphlib.Graph());
  // const dagreGraph = new dagre.graphlib.Graph();

  const enderNode = (id: string): Node => ({
    id: `${id}-end`,
    type: "actionNode",
    position: initialPos,
    data: {
      name: "",
      logo: "",
      type: "",
    },
    style: { visibility: "hidden" },
  });
  const newNode = (id: string): Node => {
    const act = actions.find((act) => act.id === id);
    return {
      id,
      type: "actionNode",
      position: initialPos,
      data: {
        name: act.displayName || act.actionDetails.name || act.actionName,
        logo: act.actionDetails.logo,
        type: act.actionDetails.type,
      },
    };
  };
  const newEdge = (source: string, target: any): Edge => ({
    id: `${source}-${target.actionId || `${source}-end`}`,
    source,
    target: target.actionId || `${source}-end`,
    data: {
      conditions: target,
    },
    type: "addEdge",
    style: { strokeWidth: 2 },
  });

  useEffect(() => {
    const autoPublish = loadAutoPublishSetting();
    const element = document.getElementById("autopublish") as HTMLInputElement | null;
    if (autoPublish) {
      element!.checked = true;
    }
  }, []);

  useEffect(() => {
    if (!actions) return;
    setEdges([]);
    const queue = [firstActionId];
    const initialNodes: Node[] = [newNode(firstActionId)];
    const initialEdges: Edge[] = [];
    setErrorActions([]);
    setErrorEdges([]);
    if (workflowBuildDetails) {
      setRevertWarning(
        !sameTimeStamp(
          workflowBuildDetails?.publishedWorkflowConfig?.lastPublishedAt as string,
          workflowBuildDetails?.draftWorkflowConfig?.lastSavedAt as string
        )
      );
      setPublishWarning(
        !sameTimeStamp(
          workflowBuildDetails?.publishedWorkflowConfig?.lastPublishedAt as string,
          workflowBuildDetails?.draftWorkflowConfig?.lastSavedAt as string
        )
      );
      setWorkflowBuildDetails(null);
    } else {
      setPublishWarning(true);
      setRevertWarning(true);
    }
    // Create while loop
    while (queue.length > 0) {
      const current = queue.shift();
      if (!current) continue;
      const act = actions.find((act) => act.id === current);
      act.nextAction.map((child: any) => {
        const childObj = child && typeof child === "object" ? child : { actionId: child };
        initialEdges.push(newEdge(current, childObj));
        if (childObj.actionId) initialNodes.push(newNode(childObj.actionId));
        else initialNodes.push(enderNode(current));

        if (child) queue.push(childObj.actionId);
      });
    }
    const { nodes: finalNodes, edges: finalEdges } = getLayoutedElements(initialNodes, initialEdges);
    console.log(finalNodes);
    setNodes(finalNodes);
    setEdges(finalEdges);
  }, [actions]);

  useEffect(() => {
    if (!workflow || !workflow?.publishedWorkflowConfig?.lastPublishedAt || !workflow?.draftWorkflowConfig?.lastSavedAt)
      return;
    setPublishWarning(
      !sameTimeStamp(
        workflow?.publishedWorkflowConfig?.lastPublishedAt as string,
        workflow?.draftWorkflowConfig?.lastSavedAt as string
      )
    );
    setRevertWarning(
      !sameTimeStamp(
        workflow?.publishedWorkflowConfig?.lastPublishedAt as string,
        workflow?.draftWorkflowConfig?.lastSavedAt as string
      )
    );
  }, [workflow]);

  useEffect(() => {
    if (!action) return;
    const responses = getVariblesForAction(action.id, dagreGraph);
    setVariables(responses);
  }, [action]);

  useEffect(() => {
    if (!publishWarning) {
      return;
    }
    if (errorActions?.length && errorEdges?.length) {
      return;
    }
    const autoPublish = loadAutoPublishSetting();
    if (!autoPublish) {
      return;
    }

    publish();
  }, [publishWarning, errorActions, errorEdges]);

  class NaryTreeNode {
    value: any;
    children: NaryTreeNode[];
    minCost: number;
    maxCost: number;
    constructor(value: any, minCost: number, maxCost: number) {
      this.value = value;
      this.minCost = minCost;
      this.maxCost = maxCost;
      this.children = [];
    }
  }

  /**
   * @todo: adjust this according to the current "modify waterfall" story
   */
  const getMinMaxCost = (value: any) => {
    const action = actions.find((act) => act.id === value);
    if (!action) return { minCost: 0, maxCost: 0 };
    let minCost = 100;
    let maxCost = -100;
    const credits: any = action.actionDetails.credits;
    if (typeof credits === "number") {
      return { minCost: credits, maxCost: credits };
    }
    if (typeof credits === "object") {
      Object.values(credits).forEach((credit: any) => {
        if (credit < minCost) minCost = credit;
        if (credit > maxCost) maxCost = credit;
      });
    }
    return { minCost, maxCost };
  };

  // Reconstruct the n-ary tree from preorder and postorder
  const constructNaryTree = (preorder: any, postorder: any) => {
    const stack = [];
    const nodeMap = new Map();

    for (let i = 0; i < preorder.length; i++) {
      const value = preorder[i];
      const cost = getMinMaxCost(value);
      const node = new NaryTreeNode(value, cost.minCost, cost.maxCost);
      nodeMap.set(value, node);

      if (stack.length > 0) {
        stack[stack.length - 1].children.push(node);
      }

      stack.push(node);
      // Use postorder to determine when to pop the stack
      while (stack.length > 0 && stack[stack.length - 1].value === postorder[0]) {
        stack.pop();
        postorder.shift(); // Remove the first element
      }
    }
    return nodeMap.get(preorder[0]); // Return the root node
  };

  const findMinAndMaxCostPaths = (root: NaryTreeNode) => {
    // why minCost and maxCost paths are also in the results object?
    // It's so that we can so the user the path that has the min and max cost
    // We can do that via a popup modal in the future.
    const results: any = {
      minCostPath: null,
      maxCostPath: null,
      minCost: 1000, // for now, setting it to thousand instead of [Infinity]
      maxCost: -1000, // same for this one as well
    };
    function dfs(node: NaryTreeNode, currentMinCost: number, currentMaxCost: number, path: any) {
      if (!node) return;
      // Update the path costs with the current node's costs
      currentMinCost += node.minCost;
      currentMaxCost += node.maxCost;
      path.push(node.value);
      // If it's a leaf node, check and update the results
      if (node.children.length === 0) {
        if (currentMinCost < results.minCost) {
          results.minCost = currentMinCost;
          results.minCostPath = [...path];
        }
        if (currentMaxCost > results.maxCost) {
          results.maxCost = currentMaxCost;
          results.maxCostPath = [...path];
        }
      }
      // Recur for all children
      for (let child of node.children) {
        dfs(child, currentMinCost, currentMaxCost, path);
      }
      // Backtrack
      path.pop();
    }
    // calling the recursion here
    dfs(root, 0, 0, []);
    return results;
  };

  const getLayoutedElements = (nodes: Node[], edges: Edge[]) => {
    const newDagreGraph = new dagre.graphlib.Graph();
    newDagreGraph.setDefaultEdgeLabel(() => ({}));
    newDagreGraph.setGraph({ rankdir: "TB" });

    nodes.forEach((node) => {
      newDagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      newDagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(newDagreGraph);
    setDagreGraph(newDagreGraph);

    nodes.forEach((node) => {
      const nodeWithPosition = newDagreGraph.node(node.id);
      const valid = checkValidAction(node.id, newDagreGraph);

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
      node.data = {
        ...node.data,
        error: !valid,
      };
      return node;
    });

    edges.forEach((edge) => {
      const vars = getVariblesForAction(edge.target, newDagreGraph);
      const valid = checkValidEdge(edge.id, edge?.data?.conditions as NextActionConfiguration, vars);
      edge.data = {
        ...edge.data,
        variables: getVariblesForAction(edge.target, newDagreGraph),
        error: !valid,
        style: { stroke: valid ? "#9CA3AF" : "#EF4444", strokeWidth: 2 },
      };

      return edge;
    });
    const nodeNames: string[] = [];
    nodes.forEach((node) => {
      nodeNames.push(node.id);
    });

    const root = constructNaryTree(
      dagre.graphlib.alg.preorder(newDagreGraph, nodeNames),
      dagre.graphlib.alg.postorder(newDagreGraph, nodeNames)
    );
    const minMaxCost = findMinAndMaxCostPaths(root);
    setMinMaxCostData(minMaxCost);

    return { nodes, edges };
  };

  const checkValidVariable = (variableId: any, variables: any[]) => {
    for (const action of variables) {
      for (const variable of action.variables) {
        if (variable.responseId === variableId) return true;
      }
    }
    return false;
  };

  const checkValidAction = (actionId: string, graph: dagre.graphlib.Graph) => {
    const actionVariables = getVariblesForAction(actionId, graph);
    const action = actions.find((act) => act.id === actionId);
    if (!action) return true;
    if (!action.payloadConfiguration) return true;
    let valid = true;
    action.payloadConfiguration.forEach((config: PayloadConfiguration) => {
      if (typeof config.inputString !== "string") return;
      const vars = config.inputString.match(/{{(.*?)}}/g);
      if (vars) {
        vars.forEach((v: string) => {
          const responseId = v.slice(2, -2);
          if (!checkValidVariable(responseId, actionVariables)) valid = false;
        });
      }
    });
    if (!valid) setErrorActions((prev) => [...prev, actionId]);
    return valid;
  };

  const checkValidEdge = (id: string, nextActionConfig: NextActionConfiguration, variables: any[]) => {
    let valid = true;
    if (!nextActionConfig.conditions || nextActionConfig.conditions.length === 0) return true;
    nextActionConfig.conditions.forEach((condition: Condition) => {
      if (condition.variable && condition.variable !== "")
        if (!checkValidVariable(condition.variable, variables)) valid = false;
    });
    if (!valid) setErrorEdges((prev) => [...prev, id]);
    return valid;
  };

  const deleteActionFromWorkflow = async (aid: string) => {
    const tempActions = [...actions];
    const action = tempActions.find((act) => act.id === aid);
    const prevId: any = dagreGraph.predecessors(aid)?.[0];
    const nextIds: any = dagreGraph.successors(aid);

    if (!prevId) return;
    const prevIndex = tempActions.findIndex((act) => act.id === prevId || act.fsWfActionId === prevId);
    const idx = tempActions[prevIndex].nextAction.findIndex(
      (next: any) => (next && typeof next === "object" ? next.actionId : next) === aid
    );
    if (idx !== -1) tempActions[prevIndex].nextAction.splice(idx, 1, ...action.nextAction);

    // Remove Duplicates
    tempActions[prevIndex].nextAction = tempActions[prevIndex].nextAction.filter(
      (obj1: any, i: number, arr: any[]) => arr.findIndex((obj2) => obj2.actionId === obj1.actionId) === i
    );

    if (nextIds && nextIds.length > 0) {
      nextIds.forEach((nextId: any) => {
        if (!nextId || nextId.includes("-end")) return;
        const nextIndex = tempActions.findIndex((act) => act.id === nextId);
        if (nextIndex === -1 || !tempActions[nextIndex] || !tempActions[nextIndex].prevAction) return;
        const idx = tempActions[nextIndex].prevAction.findIndex(
          (prev: any) => (prev && typeof prev === "object" ? prev.actionId : prev) === aid
        );
        if (idx !== -1)
          tempActions[nextIndex].prevAction.splice(idx, 1, {
            ...tempActions[nextIndex].prevAction[idx],
            actionId: prevId,
          });

        // Remove Duplicates
        tempActions[nextIndex].prevAction = tempActions[nextIndex].prevAction.filter(
          (obj1: any, i: number, arr: any[]) => arr.findIndex((obj2) => obj2.actionId === obj1.actionId) === i
        );
      });
    }
    setActions(tempActions);
    await saveWorkflowActions(id || "");
  };

  const publish = async () => {
    try {
      if (!id) return;
      setLoading(true);
      setPublishWarning(false);
      setRevertWarning(false);
      await publishDraft(id);
      setLoading(false);
    } catch (err) {
      setPublishWarning(true);
    }
  };

  const saveAutoPublishSetting = (autoPublish: boolean) => {
    localStorage.setItem("autoPublish", JSON.stringify(autoPublish));
  };

  const loadAutoPublishSetting = () => {
    const autoPublish = localStorage.getItem("autoPublish");
    const shouldAutoPublish = autoPublish ? JSON.parse(autoPublish) : false;

    return !shouldAutoPublish;
  };

  const actionModalSwitch = (action: any) => {
    if (action.stepDownAPIs)
      return (
        <WaterfallModal
          action={action}
          setModal={setModal}
          modal={modal}
          responseConfiguration={responseConfiguration}
          payloadConfiguration={payloadConfiguration}
          variables={variables}
        />
      );
    if (action.actionName.includes("SALESFORCE"))
      return (
        <CrmPullModal
          action={action}
          modal={modal}
          setModal={setModal}
          variables={variables}
          payloadConfiguration={payloadConfiguration}
          responseConfiguration={responseConfiguration}
        />
      );
    if (action.actionName.includes("HTTP_API"))
      return (
        <HTTPModal
          responseConfiguration={responseConfiguration}
          payloadConfiguration={payloadConfiguration}
          action={action}
          setModal={setModal}
          modal={modal}
          variables={variables}
        />
      );
    if (action.actionName.includes("ADD_ITEM_TO_MONDAY"))
      return (
        <MondayModal
          responseConfiguration={responseConfiguration}
          payloadConfiguration={payloadConfiguration}
          action={action}
          setModal={setModal}
          modal={modal}
          variables={variables}
        />
      );

    switch (action.type) {
      case "input":
        return (
          <InputModal
            action={action}
            setModal={setModal}
            modal={modal}
            responseConfiguration={responseConfiguration}
            payloadConfiguration={payloadConfiguration}
          />
        );

      case "add_section":
        return (
          <AddSection
            action={action}
            setModal={setModal}
            modal={modal}
            responseConfiguration={responseConfiguration}
            variables={variables}
            payloadConfiguration={payloadConfiguration}
          />
        );
      case "floq_push":
        return (
          <FloqPushModal
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
            payloadConfiguration={payloadConfiguration}
          />
        );
      case "sending":
        return (
          <SendingModal
            responseConfiguration={responseConfiguration}
            payloadConfiguration={payloadConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
          />
        );
      case "crm":
        return (
          <CrmModal
            payloadConfiguration={payloadConfiguration}
            responseConfiguration={responseConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
            type={action.name.includes("Salesforce") ? "salesforce" : ""}
          />
        );
      case "closeCrm":
        return (
          <CloseCrmModal
            payloadConfiguration={payloadConfiguration}
            responseConfiguration={responseConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
          />
        );
      case "hubspot":
        return (
          <HubspotModal
            payloadConfiguration={payloadConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
          />
        );
      case "googleSheet":
        return (
          <GoogleSheetModal
            payloadConfiguration={payloadConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
          />
        );
      case "engagebay":
        return (
          <EngagebayModal
            payloadConfiguration={payloadConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
          />
        );
      case ACTION_TYPES.AI:
        return (
          <AiActionModal
            responseConfiguration={responseConfiguration}
            payloadConfiguration={payloadConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
          />
        );
      default:
        return (
          <ActionModal
            responseConfiguration={responseConfiguration}
            payloadConfiguration={payloadConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
          />
        );
    }
  };

  const handleNewResponseConfiguration = (action: any) => {
    switch (action.actionName) {
      case "FLOQER_WEB_AGENT":
        if (action.responseConfiguration.length === 2) {
          setResponseConfiguration(action.responseConfiguration || []);
          return;
        }
        setResponseConfiguration([
          ...(action.responseConfiguration || []),
          {
            responseId: uuidv4().toString(),
            responseStructureId: "reasoningId",
          },
        ]);
        break;
      default:
        setResponseConfiguration(action.responseConfiguration || []);
    }
  };

  const onConnect = useCallback((params: any) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  return (
    <div className="w-full h-full overflow-hidden">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        panOnScroll
        // panOnScrollMode={PanOnScrollMode.Vertical}
        zoomOnScroll={false}
        zoomOnDoubleClick={false}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        edgesFocusable={false}
        edgesReconnectable={false}
        onNodeClick={(_evt, node) => {
          const act = actions.find((a) => a.id === node.id);
          setModal(false);
          setAction({
            ...act.actionDetails,
            actionName: act.actionName,
            name: act.displayName || act.actionDetails.name,
            id: act.id,
            reviewNeeded: act.reviewNeeded || false,
            continueOnFail: act.continueOnFail || false,
            ping: act.ping || undefined,
            runCondition: act.runCondition || {
              conditions: [],
              otherwise: [],
            },
          });
          handleNewResponseConfiguration(act);
          setPayloadConfiguration(act.payloadConfiguration || []);
          setModal(true);
        }}
        onNodesDelete={(deletedNodes) => {
          deletedNodes.forEach((node) => deleteActionFromWorkflow(node.id));
        }}
        fitView
        // minZoom={1}
        // maxZoom={1}
        connectionLineType={ConnectionLineType.Straight}
        onConnect={onConnect}
      >
        <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
      </ReactFlow>
      {modal && action && actionModalSwitch(action)}
      <div className="sticky bottom-0 left-0 z-10">
        <div className="flex w-fit bg-white px-4 py-2 ml-10 border-2 border-b-0 border-[#cccccc] divide-x text-[12px]">
          <span className="px-2">
            <strong>Min Cost:</strong> {minMaxCostData.minCost || 0}
          </span>
          <span className="px-2">
            <strong>Max Cost:</strong> {minMaxCostData.maxCost || 0}
          </span>
          <span className="px-2">per lead</span>
        </div>
        <div className=" bg-white w-full py-3 px-10 flex flex-col items-start border-t-2 border-[#cccccc] gap-4">
          {errorActions.length > 0 ? (
            <section className="flex px-2 py-1 rounded-md bg-red-200 justify-center items-center w-fit text-red-600 gap-2">
              <IoWarning size={25} />
              <span className="text-sm">Some actions have invalid data, please fix them before publishing</span>
            </section>
          ) : errorEdges.length > 0 ? (
            <section className="flex px-2 py-1 rounded-md bg-red-200 justify-center items-center w-fit text-red-600 gap-2">
              <IoWarning size={25} />
              <span className="text-sm">Some conditions have invalid data, please fix them before publishing</span>
            </section>
          ) : (
            publishWarning && (
              <section className="flex px-2 py-1 rounded-md bg-[#fdf6d6] justify-center items-center w-fit text-[#777777] gap-2">
                <WarningSVG size="20px" />
                <span className="text-sm">
                  You have made changes to your workflow actions, please make sure to publish new changes before running
                  to reflect changes.
                </span>
              </section>
            )
          )}
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="autopublish"
              className="cursor-pointer"
              onChange={(e) => saveAutoPublishSetting(e.target.checked)}
            />
            <label htmlFor="autopublish" className="cursor-pointer text-sm">
              Autopublish workflow changes
            </label>
          </div>
          <section className="flex w-full items-center gap-10">
            <button
              className={`disabled:cursor-not-allowed disabled:hover:bg-none font-semibold py-3 px-10 rounded-lg transition ${
                loading ? "cursor-not-allowed bg-gray-400" : "cursor-pointer bg-primary hover:bg-dark text-white"
              }`}
              onClick={publish}
              disabled={loading || errorActions.length > 0}
            >
              {loading ? "Publishing..." : "Publish"}
            </button>
            <LoaderButton
              disabled={!revertWarning}
              error=""
              loaderText="Reverting..."
              text="Revert"
              onClickCallback={async () => {
                try {
                  if (!id) return;
                  setPublishWarning(false);
                  await revertDraft(id);
                  await getAllWorkflowActionsDetails(id);
                } catch (err) {
                  setPublishWarning(true);
                }
              }}
              btnClasses="w-fit text-black bg-white !text-[16px] px-3 py-2 border border-black"
              txtClasses={`!text-[16px] ${revertWarning ? "text-gray-900" : "text-[#cccccc]"}`}
              IconComponent={RevertIcon}
              iconColor={revertWarning ? "black" : "#cccccc"}
            />

            <div className="flex gap-2 items-center">
              <MdInfoOutline size={25} />
              <span className="text-sm">
                New published changes will be applied to all prospects currently in this sequence. The data will not be
                changed for the prospects that are completed
              </span>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export const RevertIcon = ({ color = "black" }: { color?: string }) => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.208984 11.6243L6.45898 17.8744L7.93814 16.3952L4.19852 12.666H15.834V0.166016H13.7506V10.5827H4.19852L7.93814 6.85351L6.45898 5.37434L0.208984 11.6243Z"
      fill={color}
    />
  </svg>
);

export default (props: Props) => (
  <ReactFlowProvider initialHeight={100}>
    <ReactFlowBuild {...props} />
  </ReactFlowProvider>
);
