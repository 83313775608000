import LoaderButton from "@/Components/LoaderButton";
import DefaultAction from "@/assets/SVGs/DefaultAction";
import AllIcon from "@/assets/SVGs/All.svg";
import Data2 from "@/assets/SVGs/Data2.svg";
import Integrations from "@/assets/SVGs/Integrations.svg";
import AI from "@/assets/SVGs/Ai.svg";
import WebScraping from "@/assets/SVGs/WebScraping.svg";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { setStateType } from "@/utils/constants";
import { ConfigProvider, Drawer, Input as AntdInput } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Frame from "@/assets/SVGs/Frame.svg";
import DefaultIcon from "@/assets/SVGs/Spinner.svg";
import FormattingIcon from "@/assets/SVGs/Integrations.svg";
import WorkflowToolsIcon from "@/assets/SVGs/Integrations.svg";
import Search from "@/assets/SVGs/Search.svg";

export const getInputStringFromType = (field: string) => {
  switch (field) {
    case "string":
      return "";
    case "number":
      return 0;
    case "boolean":
    case "radio":
      return false;
    case "array":
      return [""];
    case "jsonArray":
    case "webhookArray":
    case "jsonArrayWithDropDown":
    case "dynamicJsonArray":
      return [];
    case "formula":
      return {};
    default:
      return "";
  }
};

type Props = {
  modal: boolean;
  setModal: setStateType<boolean>;
  availableActions: any[];
  source: string;
  target: string | null;
};

const AddActionModal = ({ modal, setModal, availableActions, source, target }: Props) => {
  const [loading, setLoading] = useState(false);
  const [selectedAction, setSelectedAction] = useState<any>("");
  const { actions, setActions, saveWorkflowActions } = useWorkflow();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredActions, setFilteredActions] = useState(availableActions);
  const [activeTab, setActiveTab] = useState("All");

  const { id } = useParams();

  useEffect(() => {
    setSelectedAction("");
    setFilteredActions(availableActions);
  }, [availableActions, source, target]);

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm, activeTab]);

  const handleActionSelect = (actionId: string) => {
    if (selectedAction === actionId) {
      setSelectedAction("");
    } else {
      setSelectedAction(actionId);
    }
  };

  const handleAdd = async () => {
    setLoading(true);
    const action = availableActions.find((act) => act.id === selectedAction);
    if (!action) return;
    const actId = uuidv4().toString();
    const payloadConfig = action.payloadStructure.map((field: any) => {
      const config: any = {
        payloadStructureId: field.payloadStructureId,
        inputString: getInputStringFromType(field.type),
      };
      if (field.type === "stepDownSearch") {
        config["stepDownSearchConfig"] = (action.stepDownAPIs || field.stepDownAPIs)
          .filter((api: any) => !api?.floqerApiKeyNotAllowed && !api?.disabled)
          .map((api: any, idx: number) => ({
            apiId: api.apiId,
            order: api.order || idx + 1,
          }));
      }
      return config;
    });

    const newAction: any = {
      id: actId,
      pk_id: actId,
      actionDetails: action,
      delayAfterActionPerProspectPerSecond: 0,
      actionName: action.id,
      nextAction: [
        {
          actionId: target,
          conditions: [],
          name: "Conditional Path",
        },
      ],
      prevAction: [
        {
          actionId: source,
          conditions: [],
          name: "Conditional Path",
        },
      ],
      payloadConfiguration: payloadConfig,
    };
    if (action.outputStructureType === "predefined") {
      newAction["responseConfiguration"] = action.responseStructure.map((field: any) => {
        const x: any = {
          responseStructureId: field.responseStructureId,
          responseId: uuidv4().toString(),
        };
        if (field.type === "sectionList")
          x["sectionVariablesConfiguration"] = field.sectionVariablesStructure.map((section: any) => ({
            responseId: uuidv4().toString(),
            responseStructureId: section.responseStructureId,
          }));
        return x;
      });
    }

    const currId = actions.findIndex((act) => act.id === source);
    actions[currId].nextAction = actions[currId].nextAction
      .map((next: any) =>
        (next && typeof next === "object" ? next.actionId : next) === target
          ? {
              actionId: actId,
              conditions: next?.conditions || [],
              name: next?.name || "",
            }
          : next
      )
      // Remove duplicates
      .filter((obj1: any, i: number, arr: any[]) => arr.findIndex((obj2) => obj2.actionId === obj1.actionId) === i);

    setActions([...actions, newAction]);

    await saveWorkflowActions(id || "");

    setLoading(false);
    setModal(false);
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    const filtered = availableActions.filter((action) => {
      return (
        action.name?.toLowerCase().includes(value?.toLowerCase()) &&
        (activeTab === "All" || action["ui-style"] === activeTab)
      );
    });
    setFilteredActions(filtered);
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
    setSearchTerm("");
  };

  const getTabIcon = (tabKey: string) => {
    switch (tabKey) {
      case "All":
        return <img src={AllIcon} alt="All" />;
      case "Data":
        return <img src={Data2} alt="Data" />;
      case "Integrations":
        return <img src={Integrations} alt="Integrations" />;
      case "AI":
        return <img src={AI} alt="AI" />;
      case "Web Scraping":
        return <img src={WebScraping} alt="Web Scraping" />;
      case "Formatting":
        return <img src={FormattingIcon} alt="Formatting" />;
      case "Workflow":
        return <img src={WorkflowToolsIcon} alt="Workflow" />;
      default:
        return <img src={DefaultIcon} alt="Default" />;
    }
  };

  const types = [
    { key: "All", icon: getTabIcon("All"), label: "All" },
    { key: "Data", icon: getTabIcon("Data"), label: "Data" },
    {
      key: "Integrations",
      icon: getTabIcon("Integrations"),
      label: "Integrations",
    },
    { key: "AI", icon: getTabIcon("AI"), label: "AI" },
    {
      key: "Web Scraping",
      icon: getTabIcon("Web Scraping"),
      label: "Web Scraping",
    },
    { key: "Formatting", icon: getTabIcon("Formatting"), label: "Formatting" },
    { key: "Workflow", icon: getTabIcon("Workflow"), label: "Workflow Tools" },
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        onClose={() => setModal(false)}
        width="40%"
        className='!font-["Inter"] !p-0'
        footer={
          <div className="w-full flex flex-col gap-4 bg-white">
            <LoaderButton
              disabled={loading || selectedAction === ""}
              onClickCallback={handleAdd}
              loaderText="Adding..."
              text="Add"
              btnClasses="!p-2"
              error=""
            />
          </div>
        }
        title={
          <div className="flex items-center gap-2 ml-2.5">
            <img src={Frame} alt="frame" />
            <p> Add Action </p>
          </div>
        }
      >
        <div className="flex flex-col gap-2 p-1 ml-2 -mt-2">
          <div className="relative mb-4 border rounded border-black">
            <AntdInput
              placeholder="Search actions"
              onChange={(e) => handleSearch(e.target.value)}
              value={searchTerm}
              className="w-full h-10 gap-1 rounded-sm hover:border-x-black"
              prefix={<img src={Search} alt="search" />}
            />
          </div>
          <div className="flex flex-wrap gap-4 mb-4">
            {types.map((type, idx) => (
              <button
                key={idx}
                onClick={() => handleTabChange(type.key)}
                className={`flex items-center gap-1 px-2 py-1 border rounded ${
                  activeTab === type.key ? "bg-black text-white" : "border-grey-400 bg-white"
                }`}
              >
                {activeTab === type.key && type.key === "All" ? (
                  <img src={AllIcon} alt="All" className="filter invert" />
                ) : (
                  type.icon
                )}
                <span>{type.label}</span>
              </button>
            ))}
          </div>
          <div className="flex flex-col gap-2">
            {filteredActions.map((action: any, idx: number) => (
              <div
                key={idx}
                className={`flex bg-white justify-between items-start sm:items-center transition-all duration-200 w-full max-w-full py-3 px-4 md:p-6 border border-grey-400 rounded-2xl cursor-pointer relative z-10 hover:border-primary hover:bg-gray-50 ${
                  selectedAction === action.id ? "border-primary bg-primary/15" : ""
                }`}
                onClick={() => handleActionSelect(action.id)}
              >
                <div className="flex items-center gap-4 w-full">
                  <div className="h-8 w-8 relative flex justify-center items-center rounded-full flex-shrink-0">
                    {action.logo && action.logo.includes("https") ? (
                      <img src={action.logo} alt="icon" />
                    ) : (
                      <DefaultAction />
                    )}
                  </div>
                  <div className="flex w-fit items-center justify-between overflow-hidden">
                    <label className="text-grey-900 text-base font-semibold select-none pointer-events-none whitespace-no-wrap w-full break-words line-clamp-2">
                      {action.name}
                    </label>
                  </div>
                </div>
                {/* <div className="flex items-center gap-4">
                  <span className="text-sm text-gray-500">
                    {action.costPerProspect}
                  </span>
                </div> */}
              </div>
            ))}
          </div>
        </div>
      </Drawer>
    </ConfigProvider>
  );
};

export default AddActionModal;
