import { ConfigProvider, Modal, Select } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import LoaderButtonBlack from "@/Components/LoaderButtonBlack";
import { cn } from "@/utils/cn";
import HomeIcon from "../../assets/SVGs/All.svg";
import FolderIcon from "../../assets/SVGs/WorkflowFolderIcon.svg";
import { duplicateWorkflow } from "@/utils/apis";

type Props = {
  modal: boolean;
  close: () => void;
  workflow: any;
  workflowFolders: any[];
};

const DuplicateWorkflow = ({ modal, close, workflow, workflowFolders }: Props) => {
  const [newWorkflowName, setNewWorkflowName] = useState(
    (workflow.name || workflow.draftWorkflowConfig.name) + " (copy)"
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [folder, setFolder] = useState("root");
  const navigate = useNavigate();

  const handleDuplicate = async () => {
    if (!newWorkflowName.trim()) {
      setErrorMessage("Please enter a new workflow name.");
      return;
    }
    setErrorMessage("");
    const newId = await duplicateWorkflow(workflow.id, newWorkflowName, folder, workflow?.supabase);
    if (newId) navigate(`/workflow/${newId}?v2=${true}`);
  };

  return (
    <Modal centered open={modal} onCancel={close} footer={null} width="50%">
      <div className="flex flex-col gap-4 p-4">
        <div className="inputStyleCSS2 flex flex-col gap-2 mb-4">
          <label htmlFor="workflow-name" className="text-[2rem] font-umuxGrot font-semibold mb-5 tracking-wider">
            New Workflow:
          </label>
          <label htmlFor="workflow-name" className="text-[22px] font-semibold w-fit">
            Name:
          </label>
          <input
            type="text"
            name="workflow-name"
            id="workflow-name"
            placeholder="Enter new workflow name"
            value={newWorkflowName}
            onChange={(e) => setNewWorkflowName(e.target.value)}
            // className="p-2 border-1 border-gray-400 rounded-md"
            className={cn(
              "focus:!outline-none !text-[22px]",
              "w-full rounded-md focus:!border-gray-500 focus:!ring-0 focus:!ring-offset-0 !text-[22px] !text-black"
            )}
          />
          {errorMessage && <span className="text-red-500 text-sm">{errorMessage}</span>}
        </div>
        <div className=" gap-2 w-full items-center">
          <label className="text-[22px] font-semibold w-fit text-nowrap">Select Folder:</label>
          <ConfigProvider
            theme={{
              token: {
                colorBorder: "rgb(156 163 175)",
                colorPrimary: "#b8b8b8",
                colorPrimaryHover: "rgb(0 0 0)",
              },
            }}
          >
            <Select
              placeholder="Default folder"
              defaultValue="Default"
              className="w-full"
              popupClassName="py-2 rounded-md h-fit"
              rootClassName="py-2 rounded-md h-16"
              suffixIcon={<FaChevronDown size={16} color="rgb(156 163 175)" />}
              value={folder}
              onChange={(value) => setFolder(value)}
              options={workflowFolders.map((folder: any) => ({
                label: (
                  <div className="flex items-center gap-2 py-1 my-1 text-[22px] font-normal">
                    <img
                      src={`${folder.name === "Default" ? HomeIcon : FolderIcon}`}
                      alt="salesforce"
                      className="w-6 h-6"
                    />
                    <span>{folder.name}</span>
                  </div>
                ),
                value: folder.id,
              }))}
            />
          </ConfigProvider>
        </div>
        <LoaderButtonBlack
          disabled={false}
          error=""
          loaderText="Duplicating Workflow..."
          text="Duplicate Workflow"
          onClickCallback={handleDuplicate}
          btnClasses="w-fit bg-black text-white hover:bg-gray-800"
        />
      </div>
    </Modal>
  );
};

export default DuplicateWorkflow;
