import Coins from "@/assets/SVGs/buildTab/Coins";
import { TrashIcon } from "../../Tabs/Table";
import { useEffect, useState } from "react";
import { useConnections } from "@/contexts/ConnectionContext";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { RiKey2Fill } from "react-icons/ri";
import { Modal } from "antd";
import LoaderButton from "@/Components/LoaderButton";

type Props = {
  provider: any;
  payload: any;
  credits: number | undefined;
  deleteButton: boolean;
  onDelete: (apiId: string) => void;
  updateConnectionToStepDown: (apiId: string, value: boolean) => void;
  setIsConnectionExists: (value: boolean) => void;
};

const StepDownTree = ({
  provider,
  payload,
  credits,
  deleteButton,
  onDelete,
  updateConnectionToStepDown,
  setIsConnectionExists,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [connectionDoc, setConnectionDoc] = useState<any>(null);
  const [connection, setConnection] = useState<any>(null);
  const [newAccount, setNewAccount] = useState<any>({});
  const [removing, setRemoving] = useState<boolean>(false);
  const { getConnectionFromType, saveConnection, deleteConnectionFromType } = useConnections();

  useEffect(() => {
    if (!provider || !provider.apiName || !provider.apiKeyAllowed) return;
    setLoading(true);
    setModal(false);
    setNewAccount({});
    getConnectionFromType(provider.apiName).then((connections) => {
      setConnection(connections.userConnection ? connections.userConnection : null);
      setConnectionDoc(connections.connectionDoc);
      setLoading(false);
    });
  }, [provider]);

  useEffect(() => {
    if (!connection) return;
    setNewAccount(connection.value);
  }, [connection]);

  if (!provider || !provider.apiName || !payload) return null;

  return (
    <div className="flex items-center justify-between w-full rounded-sm">
      <div className="flex items-center gap-1 w-full">
        <div className="flex justify-between items-center gap-2 bg-white px-1.5 py-2 w-full min-w-0">
          <div className="flex items-center gap-2 text-sm min-w-0 max-w-[60%]">
            <img src={provider.logo} alt={provider.apiName} className="w-6 h-6 flex-shrink-0" />
            <p className="truncate min-w-0">{provider.apiName}</p>
          </div>
          {loading ? (
            <SpinnerStatus />
          ) : (
            <div className="flex gap-2 items-center flex-shrink-0">
              {(!provider?.apiKeyAllowed || !connection || !payload.connection) &&
                !provider?.floqerApiKeyNotAllowed && (
                  <span className="text-sm flex-shrink-0">
                    <div className="flex items-center justify-center gap-0.5 rounded-sm">
                      <Coins width="15" height="15" />
                      <span className="font-bold text-sm">{credits}</span>
                    </div>
                  </span>
                )}
              {provider?.apiKeyAllowed && !provider?.floqerApiKeyNotAllowed && (!connection || !payload.connection) && (
                <span className="text-xs flex-shrink-0">or</span>
              )}
              {provider?.apiKeyAllowed &&
                (!connection ? (
                  <button
                    className="flex items-center gap-1 bg-gray-100 border border-black text-black text-xs p-1 rounded min-w-[70px] api-key-button"
                    onClick={() => setModal(true)}
                  >
                    <RiKey2Fill size={15} className="rotate-90" />
                    <p className="text-center leading-tight whitespace-normal">Add API Key</p>
                  </button>
                ) : !payload.connection && !provider?.floqerApiKeyNotAllowed ? (
                  <button
                    className="flex items-center gap-1 bg-green-100 border border-green-700 text-green-700 text-xs p-1 rounded min-w-[70px] api-key-button"
                    onClick={() => {
                      updateConnectionToStepDown(provider.apiId, true);
                    }}
                  >
                    <RiKey2Fill size={15} className="rotate-90" />
                    <p className="text-center leading-tight whitespace-normal">Use API Key</p>
                  </button>
                ) : (
                  <button
                    className="flex items-center gap-1 bg-green-100 border border-green-700 text-green-700 text-xs p-1 rounded min-w-[70px] api-key-button"
                    onClick={() => {
                      setModal(true);
                    }}
                  >
                    <RiKey2Fill size={15} className="rotate-90" />
                    <p className="text-center leading-tight whitespace-normal">Using API Key</p>
                  </button>
                ))}
            </div>
          )}
        </div>
      </div>
      {deleteButton && (
        <button
          onClick={() => onDelete(provider.apiId)}
          //   disabled={payloadConfig.length < 2}
          className="p-1 disabled:cursor-not-allowed"
        >
          <TrashIcon className="text-red-500 w-4 h-4" />
        </button>
      )}
      <Modal
        centered
        open={modal}
        onCancel={() => setModal(false)}
        title={
          <div className="font-bold text-lg">
            <div className="flex gap-2 items-center">
              <img src={connectionDoc?.logo} alt={connectionDoc?.name} width={24} height={24} />
              Add {connectionDoc?.name} API Key
            </div>
          </div>
        }
        footer={
          <div className="flex gap-2 justify-between font-semibold text-lg">
            <button
              className="w-full border-black border p-2 hover:bg-gray-200 rounded-sm"
              onClick={() => setModal(false)}
            >
              Cancel
            </button>
            <LoaderButton
              text="Save"
              loaderText="Saving..."
              btnClasses="w-full !rounded bg-primary text-white p-2 hover:bg-dark disabled:cursor-not-allowed disabled:bg-gray-500"
              onClickCallback={async () => {
                const { id } = await saveConnection(
                  connection?.id || "",
                  connection?.connectionId || provider.apiId,
                  newAccount
                );
                updateConnectionToStepDown(provider.apiId, true);
                setConnection({
                  id: id,
                  connectionId: provider.apiId,
                  value: newAccount,
                });
                setIsConnectionExists(true);
                setModal(false);
              }}
              error=""
              disabled={false}
            />
          </div>
        }
        className="custom-modal-width"
      >
        <div className="flex flex-col gap-2 my-8">
          {connectionDoc &&
            connectionDoc.config.map((config: any, idx: number) => {
              return (
                <div key={idx} className="flex flex-col gap-1 font-semibold">
                  <label htmlFor={config.id} className="font-semibold">
                    {config.name}
                  </label>
                  <input
                    type={config.type}
                    id={config.id}
                    placeholder={config.description}
                    value={newAccount[config.id] || ""}
                    onChange={(e) =>
                      setNewAccount({
                        ...newAccount,
                        [config.id]: e.target.value,
                      })
                    }
                    className="border border-gray-300 rounded p-2"
                  />
                </div>
              );
            })}
          {connection && payload.connection && (
            <button
              className="mt-2 flex gap-2 items-center text-red-500 disabled:cursor-not-allowed"
              disabled={removing}
              onClick={async () => {
                setRemoving(true);
                await deleteConnectionFromType(connection.connectionId || provider.apiId);
                updateConnectionToStepDown(provider.apiId, false);
                setNewAccount({});
                setConnection(null);
                setRemoving(false);
                setModal(false);
                if (provider.floqerApiKeyNotAllowed) {
                  setIsConnectionExists(false);
                }
              }}
            >
              {removing ? <SpinnerStatus /> : <TrashIcon className="w-5 h-5" />}
              <p className="font-semibold text-[15px]">{removing ? "Removing" : "Remove API Key"}</p>
            </button>
          )}
        </div>
      </Modal>
      <style>
        {`
        @media screen and (max-width: 640px) {
          .api-key-button {
            flex-direction: row;
            align-items: center;
            padding: 4px 8px;
            min-width: auto;
            white-space: nowrap;
            flex-shrink: 0;
          }
          
          .api-key-button p {
            font-size: 11px;
            line-height: 1;
            margin: 0;
            padding: 0;
          }

          .api-key-button svg {
            margin: 0 2px 0 0;
          }

          .provider-name {
            max-width: 50%;
          }
        }

        @media screen and (max-width: 400px) {
          .api-key-button {
            flex-direction: column;
            padding: 4px;
            min-width: 52px;
          }

          .api-key-button p {
            font-size: 10px;
            text-align: center;
            margin-top: 2px;
          }

          .api-key-button svg {
            margin-bottom: 2px;
          }

          .provider-name {
            max-width: 40%;
          }
        }

        /* Ensure text wrapping and overflow handling */
        input[type="text"],
        input[type="password"] {
          width: 100%;
          white-space: normal;
          word-wrap: break-word;
          height: auto;
          min-height: 40px;
          max-width: 100%;
        }

        input::placeholder {
          white-space: normal;
          overflow-wrap: break-word;
          word-wrap: break-word;
          font-size: 14px;
          line-height: 1.2;
        }

        p {
          white-space: normal;
          overflow-wrap: break-word;
          word-wrap: break-word;
          max-width: 100%;
        }

        /* Add better handling for the coins display */
        .coins-display {
          white-space: nowrap;
          display: flex;
          align-items: center;
          gap: 2px;
        }

        /* Custom modal width for different screen sizes */
        @media screen and (min-width: 768px) {
          .custom-modal-width .ant-modal-content {
            width: 90vw;
            max-width: 600px;
            margin: 0 auto;
          }
        }

        @media screen and (min-width: 1024px) {
          .custom-modal-width .ant-modal-content {
            width: 70vw;
            max-width: 800px;
          }
        }

        /* Ensure modal content is properly centered */
        .custom-modal-width {
          display: flex;
          justify-content: center;
        }

        .custom-modal-width .ant-modal-body {
          max-height: 80vh;
          overflow-y: auto;
        }
        `}
      </style>
    </div>
  );
};

export default StepDownTree;
