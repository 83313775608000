import { countriesDropdown } from "@/utils/countries";
import { DatePicker, Select, Switch, TimePicker } from "antd";
import { useCallback } from "react";
import TiptapField from "../../Tiptap/TiptapField";
import dayjs from "dayjs";
import moment from "moment";

type Props = {
  col: {
    id: string;
    name: string;
    type: string;
    options: {
      id: string;
      label: string;
    }[];
  };
  value: any;
  tiptapJson?: any;
  setPayload: (value: any, tiptapJson?: any) => void;
  variables: any;
};

const { RangePicker } = DatePicker;

const JsonSubTypes = ({ col, value, setPayload, variables, tiptapJson }: Props) => {
  const subTypeSwitchCase = useCallback(() => {
    switch (col.type) {
      case "number":
      case "text":
        return (
          <TiptapField
            content={tiptapJson || ""}
            setContent={(content: any, text: string) => setPayload(text, content)}
            refresh={0}
            placeholder={col.name}
            variables={variables}
          />
        );
      case "checkbox":
        return <Switch className="w-fit" defaultChecked={value || false} onChange={(value) => setPayload(value)} />;
      case "country":
        return (
          <Select
            showSearch
            optionFilterProp="label"
            className="w-full"
            options={countriesDropdown.map((country) => ({
              value: country.id,
              label: country.name,
            }))}
            labelInValue
            defaultValue={{
              value: value?.countryCode,
              label: value?.countryName,
            }}
            onChange={(value) =>
              setPayload({
                countryCode: value?.value,
                countryName: value?.label,
              })
            }
          />
        );
      case "date":
        return (
          <DatePicker
            className="w-full"
            defaultValue={dayjs(value?.date || moment.utc().local().format("YYYY-MM-DD"), "YYYY-MM-DD")}
            format="YYYY-MM-DD"
            onChange={(date) => setPayload({ date: date?.format("YYYY-MM-DD") })}
          />
        );
      case "dropdown":
        return (
          <Select
            showSearch
            className="w-full"
            optionFilterProp="label"
            options={
              col.options.map((opt: any) => ({
                value: opt.id,
                label: opt.label,
              })) || []
            }
            defaultValue={value || []}
            onChange={(value) => setPayload(value)}
          />
        );
      case "multiDropdown":
        return (
          <Select
            showSearch
            mode="multiple"
            className="w-full"
            optionFilterProp="label"
            options={
              col.options.map((opt) => ({
                value: opt.id,
                label: opt.label,
              })) || []
            }
            defaultValue={value || []}
            onChange={(value) => setPayload(value)}
          />
        );
      case "hour":
        return (
          <TimePicker
            className="w-full"
            format={"HH:mm"}
            defaultValue={dayjs(`${value?.hour || "00"}:${value?.minute || "00"}`, "HH:mm")}
            onChange={(date) =>
              setPayload({
                hour: date?.hour(),
                minute: date?.minute(),
              })
            }
          />
        );
      case "timeline":
        return (
          <RangePicker
            className="w-full"
            defaultValue={[
              dayjs(value?.date || moment.utc().local().format("YYYY-MM-DD"), "YYYY-MM-DD"),
              dayjs(value?.date || moment.utc().add(1, "day").local().format("YYYY-MM-DD"), "YYYY-MM-DD"),
            ]}
            format="YYYY-MM-DD"
            onChange={(date) =>
              date && setPayload({ from: date[0]?.format("YYYY-MM-DD"), to: date[1]?.format("YYYY-MM-DD") })
            }
          />
        );
      default:
        return null;
    }
  }, [col, value, setPayload]);

  return <div>{subTypeSwitchCase()}</div>;
};

export default JsonSubTypes;
