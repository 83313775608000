import React, { useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import * as Tooltip from "@radix-ui/react-tooltip";
import clsx from "clsx";
import { Button } from "antd";
import { shareWorkflowAccess } from "@/utils/apis";
import { IoMdShare } from "react-icons/io";

type User = {
  role: string;
  email: string;
};

type ShareButtonProps = {
  emails: User[];
  sharedUsers: string[]; // Array of emails with access
  userEmail: string;
  workflow: any;
  setAccessChanged?: () => void;
};

const ShareWorkflowButton: React.FC<ShareButtonProps> = ({
  emails,
  sharedUsers,
  userEmail,
  workflow,
  setAccessChanged,
}) => {
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [activePopover, setActivePopover] = useState<string | null>(null);
  const handleToggleEmail = (email: string) => {
    setSelectedEmails((prev) => (prev.includes(email) ? prev.filter((e) => e !== email) : [...prev, email]));
  };

  const getInitials = (email: string) => {
    const name = email.split("@")[0];
    const initials = name
      .split(/[^a-zA-Z]/)
      .slice(0, 2)
      .map((part) => part[0])
      .join("")
      .toUpperCase();
    return initials;
  };

  const availableEmails = emails.filter(
    (email) => !sharedUsers.includes(email.email) && email.email !== userEmail && email.email !== workflow.userEmail
  );

  const sharingData = {
    v2: workflow.supabase,
    key: `workflow=${workflow.id}`,
    isLeaf: true,
  };
  const handleShareWorkflow = async (selectedEmails: string[]) => {
    if (selectedEmails.length === 0) return;

    setLoading(true);
    await shareWorkflowAccess(workflow.id, selectedEmails, "add", sharingData);
    if (setAccessChanged) {
      setAccessChanged(); // Only call if provided
    }
    setSelectedEmails([]);
    setTimeout(() => {
      setActivePopover(null);
      setLoading(false);
    }, 1000);
  };
  const handleRemoveWorkflowAccess = async (selectedEmail: string) => {
    setLoading(true);
    await shareWorkflowAccess(workflow.id, [selectedEmail], "remove", sharingData);
    if (setAccessChanged) {
      setAccessChanged(); // Only call if provided
    }
    setTimeout(() => {
      setActivePopover(null);
      setLoading(false);
    }, 1000);
  };

  return (
    <div className="flex items-center">
      {sharedUsers.length > 0 && (
        <div className="flex space-x-2 mr-2">
          {sharedUsers.map((email) => (
            <Popover.Root
              key={email}
              open={activePopover === email}
              onOpenChange={(isOpen) => setActivePopover(isOpen ? email : null)}
            >
              <Tooltip.Provider>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <Popover.Trigger
                      className="w-8 h-8 bg-gray-300 rounded-full flex items-center justify-center text-sm font-semibold text-gray-700 cursor-pointer"
                      disabled={sharedUsers.includes(userEmail)}
                    >
                      {getInitials(email)}
                    </Popover.Trigger>
                  </Tooltip.Trigger>
                  <Tooltip.Content side="top" className="bg-gray-800 text-white px-2 py-1 rounded shadow-md text-sm">
                    {email}
                  </Tooltip.Content>
                </Tooltip.Root>
              </Tooltip.Provider>
              <Popover.Portal>
                <Popover.Content
                  align="center"
                  sideOffset={8}
                  className="bg-white shadow-md p-2 rounded w-60 text-center"
                >
                  <p className="text-gray-800 mb-2">Remove access for {email}?</p>
                  <Button
                    loading={loading}
                    onClick={() => handleRemoveWorkflowAccess(email)}
                    className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600 border-red-500 hover:border hover:border-red-600 hover:text-red-600"
                  >
                    Remove
                  </Button>
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          ))}
        </div>
      )}

      <Popover.Root
        open={activePopover === "share"}
        onOpenChange={(isOpen) => setActivePopover(isOpen ? "share" : null)}
      >
        <Popover.Trigger className="flex items-center rounded text-gray-800">
          <IoMdShare className="mr-2 text-gray-500" />
          Share
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            align="start"
            side="left"
            sideOffset={5}
            className="bg-white shadow-md p-4 rounded w-fit max-h-60 overflow-y-hidden"
          >
            <div className="space-y-2 h-40 overflow-y-auto">
              {availableEmails.length > 0 ? (
                availableEmails.map((email) => (
                  <div key={email.email} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={selectedEmails.includes(email.email)}
                      onChange={() => handleToggleEmail(email.email)}
                      className="mr-2"
                    />
                    <label className="text-gray-800">{email.email}</label>
                  </div>
                ))
              ) : (
                <p className="text-sm text-gray-500">No additional users available</p>
              )}
            </div>
            <Button
              loading={loading}
              onClick={() => handleShareWorkflow(selectedEmails)}
              className={clsx(
                "mt-4 w-full py-2 rounded border border-gray-800 text-gray-800 hover:bg-gray-800 hover:text-white",
                selectedEmails.length === 0 && "opacity-50 cursor-not-allowed"
              )}
              disabled={selectedEmails.length === 0}
            >
              Share Permission
            </Button>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default ShareWorkflowButton;
