import { setStateType } from "../../../utils/constants";
import { useEffect, useState } from "react";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import TiptapField from "../Tiptap/TiptapField";
import { Action, PayloadConfiguration, ResponseConfiguration } from "../../../utils/interfaces";
import BlueVarLogo from "../../../assets/SVGs/BlueVarLogo.svg";
import YellowVarLogo from "../../../assets/SVGs/YellowVarLogo.svg";
import { ConfigProvider, Divider, Drawer, Select, Skeleton, Space, Switch } from "antd";
import { FaPlus, FaRegEdit } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { getUserWorkflows, getWorkflowDetailsWithActions } from "@/utils/apis";
import RunCondition from "./ModalComponents/RunCondition";
import { CreateWorkflowModal } from "./ModalComponents/CreateWorkflowModal";
import { AddInputColumnsModal } from "./ModalComponents/AddInputColumnsModal";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  payloadConfiguration: PayloadConfiguration[];
  variables: any[];
};

const WORKFLOW_STRUCTURE_ID = "workflow";
const INPUT_STRUCTURE_ID = "variables";
const LIST_STRUCTURE_ID = "listId_floq";

const FloqPushModal = ({ action, modal, setModal, payloadConfiguration, variables }: Props) => {
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [workflows, setWorkflows] = useState<{
    loading: boolean;
    workflows: any[];
  }>({
    loading: false,
    workflows: [],
  });
  const [initialWorkflow, setInitialWorkflow] = useState<any>(
    payloadConfiguration.find((input) => input.payloadStructureId === WORKFLOW_STRUCTURE_ID)?.inputString || null
  );
  const [initialList, setInitialList] = useState<any>(
    payloadConfiguration.find((input) => input.payloadStructureId === LIST_STRUCTURE_ID)?.inputString || ""
  );
  const [selectedWorkflow, setSelectedWorkflow] = useState<any>(null);
  const [selectedList, setSelectedList] = useState<any>("");
  const [inputs, setInputs] = useState<{
    loading: boolean;
    fields: any[];
  }>({
    loading: false,
    fields: [],
  });
  const [updateField, setUpdateField] = useState(0);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(structuredClone(payloadConfiguration));
  const [runCondition, setRunCondition] = useState(action.runCondition);
  const { updatePayloadConfig, saveWorkflowActions, updateActionName, setPublishWarning } = useWorkflow();
  const [changeName, setChangeName] = useState(false);

  /**
   * @todo add folder logic while creating a new workflow
   */
  const [createWorkflowParams, setCreateWorkflowParams] = useState<{
    name: string;
    type: string;
    // folderId: string;
  }>({
    name: "",
    type: "v2",
    // folderId: "root",
  });
  const [responses, setResponses] = useState<ResponseConfiguration[]>([]);
  const { id } = useParams();

  const initializeWorkflows = async () => {
    setWorkflows({ ...workflows, loading: true });
    let totalWorkflows: any[] = [];
    try {
      const data = await getUserWorkflows();
      const firebase = data.workflows;
      const supabase = data.workflowsV2;

      totalWorkflows = (firebase?.workflows || []).concat(supabase?.workflows || []).map((workflow: any) => {
        if ((firebase?.workflows || []).find((w: any) => w.id === workflow.id)) workflow.supabase = false;
        else workflow.supabase = true;
        return workflow;
      });
    } catch (error) {
      setWorkflows({ loading: false, workflows: [] });
    } finally {
      setWorkflows({ loading: false, workflows: totalWorkflows });
    }
  };

  // here get the workflow details for the selected workflow from "createWorkflowModal"  and set its name in the dropdown
  /**
   * @description This useEffect will run when the selectedWorkflow is changed and it will fetch the details of the selected workflow like input columns
   * and set the input columns in the inputs state and changes the loading state to false
   */
  const initializeWorkflowInputCols = async () => {
    if (!selectedWorkflow) return;
    if (workflows.workflows.length === 0) return;

    setInputs({ loading: true, fields: [] });
    if (initialWorkflow !== selectedWorkflow) payloads[2].inputString = [];
    const isV2 = workflows.workflows.find((wf: any) => wf.id === selectedWorkflow)?.supabase || false;

    let respConfig: any[] = [];
    try {
      const data = await getWorkflowDetailsWithActions(selectedWorkflow, isV2);
      const inp = data.response.actions.find((action: any) => action.id === "id1");
      respConfig = inp.responseConfiguration;
    } catch (error) {
      setInputs({ loading: false, fields: [] });
    } finally {
      setInputs({ loading: false, fields: respConfig });
    }
  };

  useEffect(() => {
    initializeWorkflows();
  }, []);

  useEffect(() => {
    initializeWorkflowInputCols();
  }, [selectedWorkflow, workflows.workflows]);
  // dependency workflows is added to run the useEffect when the workflows are fetched

  useEffect(() => {
    setPayloads(structuredClone(payloadConfiguration));
    setSelectedWorkflow(initialWorkflow);
    setSelectedList(initialList);
    setUpdateField((prev) => prev + 1);
  }, [payloadConfiguration]);

  // Auto-save when drawer closes
  const handleClose = async () => {
    try {
      await saveOutputStructure();
      setModal(false);
    } catch (error) {
      console.error("Failed to save drawer contents on close", error);
    }
  };

  useEffect(() => {
    const temp = [...payloads];
    const index = payloads.findIndex((input) => input.payloadStructureId === INPUT_STRUCTURE_ID);

    // Get existing payload data if it exists
    const existingPayloadData = temp[index]?.inputString || [];

    if (inputs.fields.length === 0) return;

    // Map through inputs.fields and preserve existing payload data where possible
    const updatedInputString = inputs.fields.map((field: any) => {
      // Try to find matching payload entry by name and responseId
      const existingEntry = existingPayloadData.find(
        (payload: any) => payload.name === field.name && payload.responseId === field.responseId
      );
      // If matching entry found, preserve its data, otherwise create new empty entry
      return existingEntry
        ? {
            ...existingEntry,
            name: field.name,
            responseId: field.responseId,
          }
        : {
            name: field.name,
            responseId: field.responseId,
            value: "",
            tiptapJson: "",
          };
    });
    // Update the payloads array
    if (index === -1) {
      // If no payload exists for this structure ID, create new entry
      temp.push({
        payloadStructureId: INPUT_STRUCTURE_ID,
        inputString: updatedInputString,
        type: "input", // adjust type as needed
      });
    } else {
      // Update existing payload
      temp[index] = {
        ...temp[index],
        inputString: updatedInputString,
      };
    }
    setInitialWorkflow("");
    setInitialList("");
    setPayloads(temp);
    setUpdateField((prev) => prev + 1);
  }, [inputs.fields, selectedList]);

  useEffect(() => {
    if (payloads !== payloadConfiguration || runCondition !== action.runCondition) setChanged(true);
    else setChanged(false);
  }, [payloads, runCondition]);

  const saveOutputStructure = async () => {
    setLoading(true);
    const updated = updatePayloadConfig(payloads, action.id, false);
    setPayloads(updated);
    await saveWorkflowActions(id || "");
    setChanged(false);
    setLoading(false);
    setPublishWarning(true);
  };

  const handleSelectList = (value: any, options: any) => {
    setSelectedList(value ? `{{${value}}}` : "");

    const temp = [...payloads];
    const index = temp.findIndex((payload) => payload.payloadStructureId === LIST_STRUCTURE_ID);
    if (index === -1) return;

    if (!value) temp[index].inputString = "";
    else {
      temp[index].selectedList = options.name;
      temp[index].inputString = `{{${value}}}`;
    }
    setPayloads(temp);
  };

  const fieldSwitchCase = (field: any) => {
    switch (field.type) {
      case "dynamicDropdown":
        return (
          <>
            <Skeleton loading={workflows.loading}>
              <Select
                showSearch
                defaultValue={
                  payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
                }
                // when I was changing workflow from "createWorkflowModal" it was just updating the value
                // but not label of selected workflow in the dropdown, so needed this value parameter
                value={
                  payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
                }
                onChange={(value) => {
                  setSelectedWorkflow(value);
                  const temp = [...payloads];
                  const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                  temp[index].inputString = value;
                  setPayloads(temp);
                }}
                optionFilterProp="label"
                options={workflows.workflows.map((value: any) => ({
                  label: value.name || value.publishedWorkflowConfig.name,
                  value: value.id,
                }))}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <Space style={{ padding: "0 8px 4px" }}>
                      <button
                        className="text-primary flex gap-2 items-center"
                        onClick={() => {
                          setModal2(true);
                        }}
                      >
                        <FaPlus />
                        Create New Workflow
                      </button>
                    </Space>
                  </>
                )}
              />
            </Skeleton>
            <CreateWorkflowModal
              modal={modal2}
              createWorkflowParams={createWorkflowParams}
              responses={responses}
              payloads={payloads}
              parentWorkflowId={id}
              setPayloads={setPayloads}
              setSelectedWorkflow={setSelectedWorkflow}
              setModal={setModal2}
              setCreateWorkflowParams={setCreateWorkflowParams}
              setWorkflows={setWorkflows}
            />
          </>
        );
      case "sectionList":
        return (
          <Select
            placeholder={`Select ${field.name}`}
            defaultValue={
              payloads
                .find((payload) => payload.payloadStructureId === LIST_STRUCTURE_ID)
                ?.inputString.split("{{")[1]
                ?.split("}}")[0]
            }
            allowClear
            showSearch
            onChange={handleSelectList}
            optionFilterProp="name"
            options={variables
              .filter((action) => action.variables.some((variable: any) => variable.type === field.type))
              .map((variable) => {
                return {
                  label: (
                    <div className="flex gap-2">
                      <img src={variable.logo} alt={variable.name} className="w-4 h-4" />
                      <span>{variable.name}</span>
                    </div>
                  ),
                  title: variable.name,
                  options: variable.variables.map((v: any, idx: number) => {
                    return {
                      label: (
                        <div
                          key={idx}
                          className={`px-2 flex items-center gap-2 rounded-lg
                          ${variable.type === "input" ? "bg-[#FFF3D4] text-[#AA7102]" : "bg-[#E3EDFF] text-[#0047C6]"}`}
                        >
                          <img
                            src={variable.type === "input" ? YellowVarLogo : BlueVarLogo}
                            alt={v.name}
                            className="w-4 h-4"
                          />
                          {v.name}
                        </div>
                      ),
                      value: v.responseId,
                      name: v.name,
                    };
                  }),
                };
              })}
          />
        );
      case "radio":
        return (
          <Switch
            className="w-fit"
            defaultChecked={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString === true
            }
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              temp[index].inputString = value;
              setPayloads(temp);
            }}
          />
        );
      case "jsonArray":
        return (
          <div className="flex flex-col gap-4">
            <Skeleton loading={inputs.loading} active={inputs.loading}>
              <div className="flex flex-col gap-4">
                {payloads
                  .find((input) => input.payloadStructureId === field.payloadStructureId)
                  ?.inputString.map((inp: any, idx: number) => {
                    return (
                      <div className="flex flex-col gap-2 p-4 justify-between border border-gray-300 rounded-lg">
                        <p className="tracking-primary font-semibold">{inp.name.toUpperCase()}</p>
                        <TiptapField
                          content={
                            payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                              ?.inputString[idx].tiptapJson || ""
                          }
                          setContent={(content: any, text: string) => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            if (index === -1) {
                              temp.push({
                                payloadStructureId: field.payloadStructureId,
                                tiptapJson: content,
                                inputString: [
                                  {
                                    name: inp.name,
                                    responseId: inp.responseId,
                                    value: text,
                                    tiptapJson: content,
                                  },
                                ],
                                type: field.type,
                              });
                            }
                            temp[index].inputString[idx].value = text;
                            temp[index].inputString[idx].tiptapJson = content;
                            setPayloads(temp);
                          }}
                          refresh={updateField}
                          placeholder="Enter the value"
                          variables={variables}
                          explodeSection={selectedList?.split("{{")[1]?.split("}}")[0] || ""}
                        />
                      </div>
                    );
                  })}
              </div>
            </Skeleton>
            <button
              className="text-primary flex gap-2 items-center"
              onClick={async () => {
                setModal3(true);
              }}
            >
              <FaPlus />
              Add New Workflow Input Columns
            </button>
            <AddInputColumnsModal
              modal={modal3}
              responses={responses}
              selectedWorkflow={selectedWorkflow}
              inputs={inputs}
              setInputs={setInputs}
              setModal={setModal3}
              setResponses={setResponses}
              saveOutputStructure={saveOutputStructure}
            />
          </div>
        );
      default:
        return (
          <TiptapField
            content={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson || ""}
            setContent={(content: any, text: string) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  tiptapJson: content,
                  inputString: text,
                  type: field.type,
                });
              } else {
                temp[index].tiptapJson = content;
                temp[index].inputString = text;
              }
              setPayloads(temp);
            }}
            refresh={updateField}
            placeholder={field.name}
            variables={variables}
          />
        );
    }
  };

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Drawer: {
              footerPaddingBlock: 16,
              footerPaddingInline: 24,
            },
          },
        }}
      >
        <Drawer
          open={modal}
          onClose={handleClose}
          width="30%"
          className='!font-["Inter"] !p-0'
          footer={
            <div className="w-full flex flex-col gap-4 bg-white">
              <button
                className={` text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                  loading || !changed ? "cursor-not-allowed bg-gray-500" : "bg-primary cursor-pointer"
                }`}
                onClick={handleClose}
                disabled={loading || !changed}
              >
                {loading ? "Saving..." : !changed ? "Saved" : "Save"}
              </button>
            </div>
          }
          title={
            <div className="flex items-center gap-2">
              <img src={action.logo} alt={action.name} className="w-6 h-6" />
              <span
                contentEditable={changeName}
                suppressContentEditableWarning
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.target.blur();
                  }
                }}
                onBlur={async (e) => {
                  if (!e.target.textContent) return;
                  setLoading(true);
                  updateActionName(action.id, e.target.textContent || action.name);
                  setChangeName(false);
                  await saveWorkflowActions(id || "");
                  setChanged(false);
                  setLoading(false);
                  setPublishWarning(true);
                }}
              >
                {action.name}
              </span>
              <button
                onClick={() => {
                  setChangeName(!changeName);
                  const p = document.getElementById("actionName");
                  if (!p) return;
                  setTimeout(function () {
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.selectNodeContents(p);
                    range.collapse(false); // <-- Set the cursor at the end of the selection
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                    p.focus();
                  }, 0);
                }}
                className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                  changeName ? "opacity-50" : "opacity-100"
                }`}
              >
                <FaRegEdit />
              </button>
            </div>
          }
        >
          <div className="bg-white gap-10 flex flex-col overflow-x-hidden">
            {action.payloadStructure.map((field, idx) => {
              return (
                <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                  <div>
                    <p className="tracking-primary font-semibold">
                      {field.name.toUpperCase()}
                      {!field.required && (
                        <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                      )}
                    </p>
                    <p className="text-sm font-light">{field.description}</p>
                  </div>
                  {fieldSwitchCase(field)}
                </div>
              );
            })}
            <RunCondition
              variables={variables}
              responses={action.responseStructure}
              conditionsArr={
                runCondition || {
                  conditions: [],
                  otherwise: [],
                }
              }
              setConditionsArr={setRunCondition}
            />
          </div>
        </Drawer>
      </ConfigProvider>
    </>
  );
};

export default FloqPushModal;
