// import { countries } from "@/data/countries";
import { operatorsCountriesDropdown, operatorStatesDropdown } from "@/utils/countries";
import { IFiltersProps, IOperator } from "./filter.schema";
import { LuMapPin } from "react-icons/lu";

export const operatorsFilters: IFiltersProps[] = [
  {
    id: "operator_country_id",
    icon: LuMapPin,
    title: "Country",
    description: "Country of the operator",
    data_type: "options[]",
    availableOperators: [IOperator.contains_any],
    areOperatorsAccumulative: true,
    availableValues: operatorsCountriesDropdown,
    group: "Company",
    is_permanent: true,
  },
  {
    id: "operator_state_id",
    icon: LuMapPin,
    title: "State",
    description: "State of the operator",
    data_type: "states[]",
    availableOperators: [IOperator.contains_any],
    areOperatorsAccumulative: true,
    availableValues: operatorStatesDropdown,
    group: "Company",
    is_permanent: true,
  },
  {
    id: "operator_number_of_wells",
    icon: LuMapPin,
    title: "Number of Wells",
    description: "Number of wells the operator has",
    data_type: "number",
    availableOperators: [
      IOperator.greater,
      IOperator.greater_or_equal,
      IOperator.less,
      IOperator.less_or_equal,
      IOperator.is,
    ],
    group: "Company",
    is_permanent: true,
  },
];
