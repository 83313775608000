import { Select } from "antd";
import { useEffect, useState } from "react";

// Filter `option.label` match the user type `input`
const filterOption = (input: string, option?: { label: string; value: string }) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

type Props = {
  options: { value: string; label: string; idx: number }[];
  mapper: (idx: string, responseId: string) => void;
  idx: number;
  err: string[];
  defaultVal?: any;
  map: any;
  columnLabel: string;
};

const Dropdown = ({ options, mapper, idx, err, defaultVal, map, columnLabel }: Props) => {
  const [bestMatch, setBestMatch] = useState<any>();

  const stringSimilarity = (str1: string, str2: string, gramSize: number = 2) => {
    const getNGrams = (s: string, len: number) => {
      s = " ".repeat(len - 1) + s.toLowerCase() + " ".repeat(len - 1);
      let v = new Array(s.length - len + 1);
      for (let i = 0; i < v.length; i++) {
        v[i] = s.slice(i, i + len);
      }
      return v;
    };
    if (!str1?.length || !str2?.length) {
      return 0.0;
    }
    let s1 = str1.length < str2.length ? str1 : str2;
    let s2 = str1.length < str2.length ? str2 : str1;
    let pairs1 = getNGrams(s1, gramSize);
    let pairs2 = getNGrams(s2, gramSize);
    let set = new Set<string>(pairs1);
    let total = pairs2.length;
    let hits = 0;
    for (let item of pairs2) {
      if (set.delete(item)) {
        hits++;
      }
    }
    return hits / total;
  };

  useEffect(() => {
    // Skip comparison if the dropdown is already set
    if (bestMatch) {
      return; // Do not run if a value is already selected
    }

    // Find the best match based on string similarity
    const findBestMatch = () => {
      let bestScore = 0;
      let bestLabel = { value: "", label: "", idx: -1 };
      options.forEach((option) => {
        const score = stringSimilarity(option.label, columnLabel);
        if (score > bestScore) {
          bestScore = score;
          bestLabel = option;
        }
      });
      if (bestScore >= 0.4 && !Object.values(map).includes(bestLabel.value)) {
        // Use a threshold to assign the best match
        setBestMatch(bestLabel);
        mapper(idx.toString(), bestLabel.value || ""); // Automatically map the best match
      }
    };

    findBestMatch(); // Invoke best match search when component is mounted
  }, [columnLabel]);

  const onChange = (value: string) => {
    mapper(idx.toString(), value || "");
    setBestMatch(options[options.findIndex((option) => option.value === value)]);
  };

  return (
    <div className="flex items-center gap-4">
      <Select
        aria-errormessage="Already Mapped"
        status={err.includes(idx.toString()) ? "error" : ""}
        placeholder="Select a column"
        optionFilterProp="children"
        onChange={onChange}
        filterOption={filterOption}
        value={bestMatch !== "" ? bestMatch : "Select a column"} // Pre-select the best match
        options={options}
        defaultValue={defaultVal.filter((item: any) => item.value === map[idx])}
        showSearch
        allowClear
      />
      {
        // If error, show error message
        err.includes(idx.toString()) && <div className="text-red-500">Already Mapped</div>
      }
    </div>
  );
};

export default Dropdown;
