/*global chrome*/
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBrowserTokens, handleUpdateTokens } from "../utils/functions";
import { firebaseAccessToken, firebaseRefreshToken, setStateType } from "../utils/constants";
import {
  addMemberURL,
  changePasswordURL,
  checkLoginMethodAndMemberURL,
  checkProfileAndPaymentURL,
  createOrgURL,
  fetchMembersURL,
  fetchOrgURL,
  getOrgNameURL,
  getProfileURL,
  loginWithEmailAndPassURL,
  loginWithGoogleURL,
  removeMemberURL,
  resetPasswordURL,
  updateOrgURL,
  updateProfileURL,
} from "../utils/urls";
// import { chrome_extension_key } from "../utils/constants";

export const UserContext = createContext({
  currentUser: "",
  setCurrentUser: (_val: string) => {},
  userLoad: false,
  logout: async () => {},
  collapsed: false,
  setCollapsed: ((_val: boolean) => {}) as setStateType<boolean>,
  checkLoginMethodAndMember: async (_email: string) => {
    return {
      signInMethods: [""],
      member: false,
    };
  },
  loginWithGoogle: async (_token: string) => {},
  loginWithEmailAndPass: async (_email: string, _password: string, _type: string) => {},
  changePassword: async (_oldPassword: string, _newPassword: string) => {},
  resetPassword: async (_email: string) => {},
  checkProfileAndPayment: async () => {
    return {
      profile: false,
      paymentPending: true,
    };
  },
  getOrgName: async () => {
    return {
      id: "",
      name: "",
      description: "",
      members: [{}],
    };
  },
  getProfile: async () => {
    return {
      first_name: "",
      last_name: "",
      job_title: "",
    };
  },
  updateProfile: async (_val: any) => {},
  fetchMembers: async () =>
    Array<{
      email: string;
      role: string;
    }>(),
  addMember: async (_email: string, _role: string) => {},
  removeMember: async (_email: string, _role: string) => {},
  checkIsLead: async () => {
    return {
      isLead: false,
      orgId: "",
    };
  },
  createOrg: async (_org_data: any, _email: string, _password: string) => {},
  updateOrg: async (_desc: string) => {},
  fetchOrg: async () => {
    return {
      id: "",
      name: "",
      description: "",
    };
  },
});

export const useUser = () => useContext(UserContext);

export default function UserContextProvider({ children }: any) {
  const [currentUser, setCurrentUser] = useState<string>("");
  const [userLoad, setUserLoad] = useState<boolean>(true);
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setUserLoad(true);
    const [accessToken, refreshToken] = getBrowserTokens();
    if (!accessToken || !refreshToken) setCurrentUser("");
    else {
      const email = localStorage.getItem("email");
      if (email) setCurrentUser(email);
    }
    setUserLoad(false);
  }, []);

  useEffect(() => {
    const collapseHistory = localStorage.getItem("collapsed");
    if (collapseHistory === "true") setCollapsed(true);
  }, [currentUser]);

  useEffect(() => {
    localStorage.setItem("collapsed", `${collapsed}`);
  }, [collapsed]);

  useEffect(() => {
    if (!currentUser) {
      document.cookie = "firebase_cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      document.cookie = "firebase_email=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      localStorage.removeItem("email");
      return;
    } else {
      localStorage.setItem("email", currentUser);
    }
  }, [currentUser]);

  async function checkLoginMethodAndMember(email: string) {
    try {
      const url = checkLoginMethodAndMemberURL;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      };
      const response = await fetch(url, options);
      if (!response.ok) throw new Error("Error checking login method and member");
      const responseData = await response.json();
      return {
        signInMethods: responseData.methods,
        member: true,
      };
    } catch (error) {
      console.log("error", error);
      return {
        signInMethods: [],
        member: false,
      };
    }
  }

  async function loginWithGoogle(token: string) {
    try {
      const url = loginWithGoogleURL;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
        }),
      };
      const response = await fetch(url, options);
      const responseData = await response.json();
      if (responseData.error) throw new Error(responseData.error);
      handleUpdateTokens(responseData, "", "");
      setCurrentUser(responseData.email);
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }

  async function loginWithEmailAndPass(email: string, password: string, type: string) {
    try {
      const url = loginWithEmailAndPassURL;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
          type,
        }),
      };
      const response = await fetch(url, options);
      // if (!response.ok)
      //   throw new Error("Error checking login method and member");
      const responseData = await response.json();
      if (responseData.error) throw new Error(responseData.error);
      handleUpdateTokens(responseData, "", "");
      setCurrentUser(responseData.email);
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      const url = changePasswordURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          oldPassword,
          newPassword,
        }),
      };
      const response = await fetch(url, options);
      if (!response.ok) throw new Error("Error checking login method and member");
      const responseData = await response.json();
      handleUpdateTokens(responseData, accessToken, refreshToken);
    } catch (error) {
      console.log("error", error);
    }
  }

  async function resetPassword(email: string) {
    try {
      const url = resetPasswordURL;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      };
      const response = await fetch(url, options);
      if (!response.ok) throw new Error("Error checking login method and member");
    } catch (error) {
      console.log("error", error);
    }
  }

  async function logout() {
    document.cookie = "firebase_cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    document.cookie = "firebase_email=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    document.cookie = `${firebaseAccessToken}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    document.cookie = `${firebaseRefreshToken}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    localStorage.removeItem("email");
    setCurrentUser("");
    navigate("/");
    window.location.reload();
  }

  async function checkProfileAndPayment(): Promise<any> {
    try {
      const url = checkProfileAndPaymentURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        headers: {
          accessToken,
          refreshToken,
        },
      };
      const response = await fetch(url, options);
      if (!response.ok) throw new Error("Error checking profile and payment");
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.log("error", error);
      return {
        profile: false,
        paymentPending: true,
      };
    }
  }

  async function getOrgName() {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();

      const myHeaders = new Headers();
      myHeaders.append("accessToken", accessToken);
      myHeaders.append("refreshToken", refreshToken);

      const requestOptions = {
        headers: myHeaders,
      };
      const url = getOrgNameURL;
      const response = await fetch(url, requestOptions);
      if (!response.ok) throw new Error("Error fetching org");
      const responseData = await response.json();
      return responseData.org;
    } catch (error) {
      console.log("error", error);
    }
  }

  async function getProfile(): Promise<any> {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();

      const myHeaders = new Headers();
      myHeaders.append("accessToken", accessToken);
      myHeaders.append("refreshToken", refreshToken);

      const requestOptions = {
        headers: myHeaders,
      };
      const res = await fetch(getProfileURL, requestOptions);
      if (!res.ok) throw new Error("Error fetching profile data");
      const result = await res.json();
      return result.user;
    } catch (error) {
      console.log("error", error);
    }
  }

  async function updateProfile(userData: any) {
    try {
      const url = updateProfileURL;
      const [accessToken, refreshToken] = getBrowserTokens();
      const options = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          payload: userData,
        }),
      };
      const response = await fetch(url, options);
      if (!response.ok) throw new Error("Error updating profile");
    } catch (error) {
      console.log("error", error);
    }
  }

  async function fetchMembers(): Promise<any> {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();

      const myHeaders = new Headers();
      myHeaders.append("accessToken", accessToken);
      myHeaders.append("refreshToken", refreshToken);

      const requestOptions = {
        headers: myHeaders,
      };
      const url = fetchMembersURL;
      const response = await fetch(url, requestOptions);
      if (!response.ok) throw new Error("Error fetching members");
      const responseData = await response.json();
      return responseData.members;
    } catch (error) {
      console.log("error", error);
    }
  }

  async function addMember(email: string, role: string) {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("accessToken", accessToken);
      myHeaders.append("refreshToken", refreshToken);

      const raw = JSON.stringify({
        email: email,
        role: role,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };
      const url = addMemberURL;
      const response = await fetch(url, requestOptions);
      if (!response.ok) throw new Error("Error adding member");
    } catch (error) {
      console.log("error", error);
    }
  }

  async function removeMember(email: string, role: string) {
    try {
      if (!email?.length || !role?.length) return;
      const [accessToken, refreshToken] = getBrowserTokens();

      const myHeaders = new Headers();
      myHeaders.append("accessToken", accessToken);
      myHeaders.append("refreshToken", refreshToken);

      const requestOptions = {
        method: "DELETE",
        headers: myHeaders,
      };
      const url = removeMemberURL;
      const response = await fetch(
        `${url}?
        email=${email}&role=${role}
        `,
        requestOptions
      );
      if (!response.ok) throw new Error("Error removing member");
    } catch (error) {
      console.log("error", error);
    }
  }

  async function checkIsLead() {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();
      const url = fetchOrgURL;
      const options = {
        headers: {
          accessToken,
          refreshToken,
        },
      };
      const response = await fetch(url, options);
      if (!response.ok) throw new Error("Error checking if lead");
      const responseData = await response.json();
      handleUpdateTokens(responseData, accessToken, refreshToken);
      const members: any[] = responseData.org.members;
      const member = members.find((m) => m.email === currentUser);
      return {
        isLead: member?.role === "org_lead",
        orgId: responseData.org.id,
      };
    } catch (error) {
      console.log("error", error);
      return {
        isLead: false,
        orgId: "",
      };
    }
  }

  async function createOrg(org_data: any, email: string, password: string) {
    try {
      const url = createOrgURL;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
          orgData: {
            ...org_data,
            members: [
              {
                email: email,
                role: "org_lead",
              },
            ],
          },
        }),
      };
      const response = await fetch(url, options);
      if (!response.ok) throw new Error("Error creating org");
      const res = await response.json();
      handleUpdateTokens(res, "", "");
      setCurrentUser(res.email);
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }

  async function updateOrg(desc: string) {
    try {
      if (!desc?.length) return;
      const [accessToken, refreshToken] = getBrowserTokens();

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("accessToken", accessToken);
      myHeaders.append("refreshToken", refreshToken);

      const raw = JSON.stringify({
        desc: desc,
      });

      const requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
      };
      const url = updateOrgURL;
      const res = await fetch(url, requestOptions);
      if (!res.ok) throw new Error("Error updating org");
    } catch (error) {
      console.log("error", error);
    }
  }

  async function fetchOrg() {
    try {
      const [accessToken, refreshToken] = getBrowserTokens();

      const myHeaders = new Headers();
      myHeaders.append("accessToken", accessToken);
      myHeaders.append("refreshToken", refreshToken);

      const requestOptions = {
        headers: myHeaders,
      };
      const url = fetchOrgURL;
      const response = await fetch(url, requestOptions);
      if (!response.ok) throw new Error("Error fetching org");
      const responseData = await response.json();
      return responseData.org;
    } catch (error) {
      console.log("error", error);
    }
  }

  const value = {
    currentUser,
    setCurrentUser,
    userLoad,
    logout,
    collapsed,
    setCollapsed,
    checkLoginMethodAndMember,
    loginWithGoogle,
    loginWithEmailAndPass,
    changePassword,
    resetPassword,
    checkProfileAndPayment,
    getOrgName,
    getProfile,
    updateProfile,
    fetchMembers,
    addMember,
    removeMember,
    checkIsLead,
    createOrg,
    updateOrg,
    fetchOrg,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
