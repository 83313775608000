import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { Button, Table, Tooltip } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonAddFilter from "./AddFilterButton";
import SearchFilterBase from "./AppliedFilters";
import { IFiltersProps } from "./filter.schema";
import { crustDataLinkedinFilters } from "./filters";
import { convertNumberStringToNumber } from "./utils";
import { convertDataToTableFormat, getCostString, getTotalItems, tableColumns } from "../CompaniesListViaLinkedin";
import LoaderButton from "@/Components/LoaderButton";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

interface Props {
  workflowName: string;
  selectedSource: string;
  selectedFolder: string;
}

const CrustDataCustomLinkedinListBuilder: React.FC<Props> = ({ workflowName, selectedFolder }) => {
  const navigate = useNavigate();
  const { createNewWorkflow, importCrustDataFiltersData } = useWorkflow();
  const [previewGenerated, setPreviewGenerated] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<IFiltersProps[]>([]);
  const [pageCount, setPageCount] = useState<number>(1);
  const [previewData, setPreviewData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState<number>();
  const [error, setError] = useState("");

  const { getCrustDataFiltersPreview } = useWorkflow();

  const isFilterRemovable = (filter: IFiltersProps) => {
    const exist_job_filter = appliedFilters.some(
      (f) => f.id.startsWith("job_filters.") && f.id !== "job_filters.posted_at"
    );
    if (filter.id === "job_filters.posted_at" && exist_job_filter) {
      return false;
    }
    return true;
  };

  const isFilterPermanent = (filter: IFiltersProps, index: number, appliedFilters: IFiltersProps[]) => {
    if (filter.is_permanent) {
      const firstOcurrenceSameId = appliedFilters.findIndex((appliedFilter) => appliedFilter.id === filter.id);
      if (firstOcurrenceSameId == index) return true;
    }
    return false;
  };

  const onChangeFilter = (index: number, filter: IFiltersProps) => {
    const newFilters = [...appliedFilters];
    newFilters[index] = filter;
    newFilters[index].hasChanged = true;
    setAppliedFilters(newFilters);
  };

  const onRemoveFilter = (index: number) => {
    const newFilters = [...appliedFilters];
    newFilters.splice(index, 1);
    setAppliedFilters(newFilters);
  };

  const addFilters = (filters: IFiltersProps[]) => {
    const newFilters = [...appliedFilters, ...filters];
    setAppliedFilters(newFilters);
  };

  const convertFilters = (filters: any[]) => {
    return filters.map((filter) => {
      const newFilter: any = {
        filter_type: filter.id,
        type: filter.operator === "not_in" ? "not in" : filter.operator,
        value: Array.isArray(filter.value) ? filter.value : [filter.value],
      };

      if (filter.data_type === "options-subfilter") {
        newFilter.sub_filter = filter.value;
        newFilter.value =
          filter.subValue.length === 1
            ? { min: 0, max: filter.subValue[0] }
            : { min: filter.subValue[0], max: filter.subValue[1] };
      }

      return newFilter;
    });
  };

  const generatePreview = async () => {
    setLoading(true);
    setError("");
    const filters = convertFilters(appliedFilters);
    try {
      const { data } = await getCrustDataFiltersPreview(filters);
      if (!data || data["error"]) {
        setError(data["error"] ?? "Something went wrong. Please try again later.");
        setLoading(false);
        return;
      }
      const { total_display_count, companies } = data;

      setPreviewData(companies);
      setTotalItems(convertNumberStringToNumber(total_display_count));
      setPreviewGenerated(true);
      setLoading(false);
    } catch (e) {
      setError((e as Error).message);
      setLoading(false);
    }
  };

  const data = React.useMemo(() => {
    return convertDataToTableFormat(previewData);
  }, [previewData]);

  const importItems = async () => {
    const id = await createNewWorkflow(
      workflowName,
      "comapnies_crustdata_realtime_linkedin_list_filters",
      selectedFolder,
      {
        filters: convertFilters(appliedFilters),
        maxCount: getTotalItems(totalItems || 0, pageCount),
      },
      true
    );

    if (id === "") {
      setError("Failed to create workflow");
      return;
    }

    try {
      await importCrustDataFiltersData(id, convertFilters(appliedFilters), getTotalItems(totalItems || 0, pageCount));
    } catch (e) {
      setError((e as Error).message);
    }

    navigate(`/workflow/${id}?v2=${true}`);
  };

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <div className="py-5 px-10 overflow-hidden flex flex-col h-screen">
        <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-6">
          <div className="flex min-w-fit items-start">
            <div className="flex gap-3 items-center min-w-fit">
              <p>Import</p>
              <Tooltip title="Each page includes upto 25 companies">
                <input
                  type="number"
                  className="broder border-gray-200 rounded-md h-9 px-4 w-50 shadow"
                  placeholder="Number of pages to import"
                  value={pageCount}
                  onChange={(e) => setPageCount(Number(e.target.value))}
                  max={99}
                  min={1}
                />
              </Tooltip>
              <p>page(s)</p>
              <p>using the following filters</p>
            </div>
          </div>
          {/* <p className="text-xl font-semibold">Add the filters to refine your companies search</p> */}
          <div className="flex flex-wrap items-center gap-2">
            {appliedFilters.map((filter, index) => (
              <SearchFilterBase
                key={index}
                index={index}
                filter={filter}
                changeFilter={onChangeFilter}
                removeFilter={onRemoveFilter}
                appliedFilters={appliedFilters}
                isRemovable={isFilterRemovable(filter)}
                isPermanent={isFilterPermanent(filter, index, appliedFilters)}
                hasChanged={filter.hasChanged || false}
              />
            ))}
            <ButtonAddFilter
              availableFilters={crustDataLinkedinFilters}
              appliedFilters={appliedFilters}
              addFilters={addFilters}
            />
          </div>
        </div>

        <div className="overflow-auto">
          {previewGenerated && (
            <div className="mt-6 ">
              <Table
                bordered
                rowClassName="bg-[#f7fdff] cursor-pointer overflow-y-auto"
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                rowSelection={{ type: "checkbox" }}
                title={() => (
                  <span className="text-xl font-semibold bg-[#f7fdff] text-black">
                    Preview of Data {`(${previewData.length} of ${totalItems ?? 0})`}
                  </span>
                )}
              />
            </div>
          )}
        </div>

        <div>
          {previewGenerated && (
            <div className="flex flex-row items-center justify-start mt-5">
              <>
                <LoaderButton
                  disabled={false}
                  error=""
                  loaderText="Importing..."
                  text={`Import ${getTotalItems(totalItems || 0, pageCount)} Items`}
                  onClickCallback={importItems}
                  btnClasses="text-white text-md bg-[#4F3FFF] hover:bg-[#4F3FFF] rounded-none"
                />
                <div className="w-[0.5px] h-12 bg-gray-200 mx-4" />
                <span className="text-xl text-[#777777] font-semibold">
                  {getCostString(totalItems || 0, pageCount)}
                </span>
              </>
            </div>
          )}
        </div>

        <div>
          {!previewGenerated && (
            <div className="mt-5">
              <Button
                onClick={generatePreview}
                disabled={loading}
                type="primary"
                size="large"
                className={`bg-[#4F3FFF] flex flex-row items-center justify-center gap-2 rounded-none text-xl h-12 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-[#4F3FFF] disabled:text-white`}
              >
                {loading && <SpinnerStatus white />}
                {loading ? "Generating Preview..." : previewGenerated ? "Regenerate Preview" : "Generate Preview"}
              </Button>
            </div>
          )}
        </div>

        <div>
          <span className="text-red-500 font-semibold">
            {error && "Invalid: "}
            {error}
          </span>
        </div>
      </div>
    </QueryClientProvider>
  );
};

export default CrustDataCustomLinkedinListBuilder;
