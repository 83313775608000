import { memo } from "react";
import WorkflowFolderIcon from "../../../assets/SVGs/WorkflowFolderIcon.svg";
import { MdMoreHoriz } from "react-icons/md";
import moment from "moment";
import { DISPLAY_DATE_TIME_FORMAT } from "@/utils/constants";
import { Dropdown } from "antd";

interface FolderProps {
  folder: any;
  getFolderActionMenu: any;
  RenameWorkflowFolder: any;
  toRenameFolder: any;
  setExpandedKeys: React.Dispatch<React.SetStateAction<string[]>>;
}

const Folder = memo(
  ({ folder, getFolderActionMenu, RenameWorkflowFolder, toRenameFolder, setExpandedKeys }: FolderProps) => {
    if (!folder) return;

    return (
      <div
        className="px-2 py-1 flex justify-between items-center hover:bg-[#efe5ffc7]"
        onClick={() =>
          setExpandedKeys((preExpandedKeys) => {
            if (preExpandedKeys.includes(`folder=${folder.id}`)) {
              return preExpandedKeys.filter((i) => i != `folder=${folder.id}`);
            } else {
              return [...preExpandedKeys, `folder=${folder.id}`];
            }
          })
        }
      >
        <div className="flex p-4 gap-x-2 text-[22px] items-center font-normal w-3/4">
          <img src={WorkflowFolderIcon} alt="workflow-folder-icon" width={26} />
          {!toRenameFolder || toRenameFolder.id !== folder.id ? (
            <p>{folder.name.length > 70 ? `${folder.name.slice(0, 67)}...` : folder.name}</p>
          ) : (
            <RenameWorkflowFolder />
          )}
        </div>
        <div className="absolute right-0 flex items-center justify-between w-[21rem]">
          <p className="w-1/2 text-[18px] text-gray-500">
            {moment.utc(folder.createdAt).local().format(DISPLAY_DATE_TIME_FORMAT)}
          </p>
          <div className="hoverClass w-1/2 flex items-center justify-center">
            <Dropdown
              overlay={getFolderActionMenu(folder)}
              trigger={["click"]}
              overlayStyle={{
                width: "12rem",
              }}
            >
              <button
                className="flex justify-center h-fit items-center p-1 rounded-md transition-opacity duration-600 ease-in-out hover:opacity-100 hover:bg-gray-200"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                }}
              >
                <MdMoreHoriz size={24} />
              </button>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    // Skip comparison for props that never change
    return (
      prevProps.folder === nextProps.folder &&
      prevProps.toRenameFolder === nextProps.toRenameFolder &&
      prevProps.setExpandedKeys === nextProps.setExpandedKeys &&
      prevProps.getFolderActionMenu === nextProps.getFolderActionMenu
    );
  }
);

export default Folder;
